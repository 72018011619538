import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import Modal from 'react-responsive-modal';
import * as moment from 'moment';
import {alertActions} from '../actions'
import swal from '@sweetalert/with-react';
import matchSorter from 'match-sorter';
import transactionHelper from '../helpers/transaction';

import {jsx, css} from '@emotion/core';
import {ClipLoader} from 'react-spinners';
import * as axios from "axios";
import {BASE_URL} from "../constants";

const override = css`
display: block;
margin-top:10%;
margin-left:50%;
margin-right:40%;
border-color: red;
`;

const formatToSixDigits = (number) => {
  var output = number + '';
  while (output.length < 6) {
    output = '0' + output;
  }
  return output;
}


class RefundHistory extends Component {

  state = {
    filterable: false,
    notCustom: true,
    period: "All",
    assignModal: false,
    fullModal: false,
    from_date: "",
    filter: "",
    to_date: "",
    data: [],
    order: "",
    fields: {
      order_id: 0,
      delivery_man_id: 0
    },
    role: 'seller',
    payments: [],
    transaction: [],
    pay: [],
  }

  getData = () => {
    this.setState({loading: true})
    axios({
      method: 'get',
      mode: 'no-cors',
      url: `${BASE_URL}admin/refunds`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
       .then(res => {
         this.setState({
           data: res.data.data,
           payouts: res.data.data,
         })
       })
       .catch(err => {
         console.log(err)
       })
       .finally(() => {
         this.setState({
           loading: false,
         })
       })
  }

  componentDidMount() {
    this.getData();
  }

  // componentWillReceiveProps(props) {
  //   if (props.alert && props.alert.message && props.alert.type === "alert-danger") {
  //     swal("Error", props.alert.message, "error").then(() => {
  //       props.dispatch(alertActions.alert.clear());
  //     });
  //   }
  //
  //   if (props.alert && props.alert.message && props.alert.type === "alert-success") {
  //     swal("Success", props.alert.message, "success").then(() => {
  //       props.dispatch(alertActions.alert.clear());
  //     });
  //     this.onCloseModal('assignModal');
  //     // this.onCloseModal('edit');
  //     // this.onCloseModal('delete');
  //   }
  //   if (props.payouts) {
  //     this.setState({data: [...props.payouts]});
  //     this.setState({payments: props.payouts})
  //
  //   }
  //
  // }

  calculateCardFee = (data, role) => {
    let cost = (parseFloat(data.product_cost / 100) * data.quantity) + (parseFloat(transactionHelper.calculateShipping(data, 1, 1)[role]) / 100);
    let fee = ((1.5 / 100) * cost);

    if (cost >= 2500)
      fee = fee + 100;
    if (fee >= 2000)
      fee = 2000;
    return fee + 50;
  }
  handleSelect = (e) => {
    const {value} = e.target;
    if (value === "Custom") {
      this.setState({notCustom: !this.state.notCustom})
    }
    this.filterPeriod(value);
    // const period = {period: value}
    this.setState({period: value});
    /* console.log(value); */
    /* console.log(this.state.period); */
    /* console.log(this.state.data); */
  }
  filterPeriod = (value) => {
    const data = this.state.payouts;
    /* console.log(data); */

    if (value === "All") {
      /* console.log(data); */
      this.table_rows = data;
      this.notCustom = true;
      this.page = 1;
      this.to_date = null;
      this.from_date = null;
      this.setState({notCustom: true, data: [...this.state.payouts]});
    }

    if (value === "This Week") {
      var ordersThisWeek = [];

      for (var i = 0; i < data.length; i++) {
        var now = moment();
        var input = moment(data[i].created_at);
        var isThisWeek = input.isSame(now, "week");

        if (isThisWeek) {
          ordersThisWeek.push(data[i]);
        }
      }

      this.table_rows = ordersThisWeek;
      this.notCustom = true;
      this.page = 1;
      this.to_date = null;
      this.from_date = null;
      /* console.log(this.table_rows); */
      this.setState({notCustom: true, data: [...this.table_rows]});
    }

    if (value === "This Month") {
      var ordersThisMonth = [];

      for (let i = 0; i < data.length; i++) {
        //var now = moment();
        let input = moment(data[i].created_at);
        var isThisMonth = input.isSame(new Date(), 'month');

        if (isThisMonth) {
          ordersThisMonth.push(data[i]);
        }
      }

      this.table_rows = ordersThisMonth;
      this.notCustom = true;
      this.page = 1;
      this.to_date = null;
      this.from_date = null;
      this.setState({notCustom: true, data: [...this.table_rows]});
    }

    if (value === "60 Days Ago") {
      var ordersSixtyDaysAgo = [];

      for (let i = 0; i < data.length; i++) {

        var sixty_days_ago = moment().subtract(60, 'days');

        var isBetweenSixtyDays = moment(data[i].created_at).isAfter(sixty_days_ago);

        if (isBetweenSixtyDays) {
          ordersSixtyDaysAgo.push(data[i]);
        }
      }

      this.table_rows = ordersSixtyDaysAgo;
      this.notCustom = true;
      this.page = 1;
      this.to_date = null;
      this.from_date = null;
      this.setState({notCustom: true, data: [...this.table_rows]});
    }

    if (value === "90 Days Ago") {
      var ordersNinetyDaysAgo = [];

      for (let i = 0; i < data.length; i++) {

        var ninety_days_ago = moment().subtract(90, 'days');

        var isBetweenNinetyDays = moment(data[i].created_at).isAfter(ninety_days_ago);

        if (isBetweenNinetyDays) {
          ordersNinetyDaysAgo.push(data[i]);
        }
      }

      this.table_rows = ordersNinetyDaysAgo;
      this.notCustom = true;
      this.page = 1;
      this.to_date = null;
      this.from_date = null;
      this.setState({notCustom: true, data: [...this.table_rows]});
    }

    if (value === "Custom") {
      this.table_rows = data;
      this.to_date = null;
      this.from_date = null;

    }
  }

  filterDates = (data) => {

    // if(this.state.from_date == null){

    //     this.alertMessage = "Please select a From date";
    //     // $(".search-alert").removeClass("d-none");
    // }
    // if(this.state.to_date == null){
    //     this.alertMessage = "Please select a To date";
    //     // $(".search-alert").removeClass("d-none");
    // }

    // if(moment(this.from_date).isAfter(this.to_date)){
    //     this.alertMessage = "Please select a From date that is before your To date";
    //     // $(".search-alert").removeClass("d-none");
    // }

    /* console.log(this.state.from_date); console.log(this.state.to_date); */

    var ordersBetweenTheTwoDates = [];

    for (var i = 0; i < data.length; i++) {

      var isBetweenDates = moment(data[i].created_at).isBetween(this.state.from_date, this.state.to_date); // true
      /* console.log(isBetweenDates); */
      if (isBetweenDates) {
        ordersBetweenTheTwoDates.push(data[i]);
      }
    }

    this.table_rows = ordersBetweenTheTwoDates;
    //this.notCustom = true;
    this.page = 1;
    //this.to_date = null;
    //this.from_date = null;
    /* console.log(this.table_rows); */
    return this.table_rows;
  }
  // componentDidMount= ()=>{
  //     'i got called';
  //     this.props.view("seller",this.props.history);
  // }
  handleDate = (e) => {
    const {name, value} = e.target;
    this.setState({[name]: value});

  }

  filterTable = (filter) => {
    var data = this.state.payouts;
    var filteredRows = [];
    // this.setState({data:[...this.state.deliveryMen]});
    if (filter !== "") {
      for (var i = 0; i < data.length; i++) {

        if (
            (data[i].transaction && data[i].transaction.id === parseInt(filter)) ||
            (data[i].created_at && data[i].created_at.includes(filter)) ||
            (data[i].defination && data[i].defination.name.toUpperCase().match(filter.toUpperCase())) ||
            (data[i].transaction && data[i].transaction.user && (data[i].transaction.user.firstname + " " + data[i].transaction.user.lastname).toUpperCase().includes(filter.toUpperCase())) ||

            (data[i].transaction && data[i].transaction.customers && (data[i].transaction.customers.firstname + " " + data[i].transaction.customers.lastname).toUpperCase().includes(filter.toUpperCase())) ||
            (data[i].transaction && data[i].transaction.payment && data[i].transaction.payment.payment_ref.toUpperCase().includes(filter.toUpperCase())) ||
            (data[i].amount === parseInt(filter))
        ) {
          filteredRows.push(data[i]);
        }
      }

      this.setState({data: [...filteredRows]});
    } else {
      this.setState({data: [...this.state.payouts]});
    }

  }

  handleFilter = (e) => {
    const {value} = e.target;
    this.setState({filter: value}, () => this.filterTable(this.state.filter));
    // this.filterTable();

  }
  onOpenModal = (e, name, value, value2) => {
    e.preventDefault();
    this.setState({[name]: true});
    this.setState({transaction: value, pay: value2});
    console.log(value, value2)

  };

  onCloseModal = (name) => {
    this.setState({[name]: false});
    this.setState({transaction: ""});
    this.setState({pay: ""});
  };

  formatAmount = (amount) => {
    let a = amount + '';
    let naira = a.slice(0, -3);
    let kobo = a.substr(a.length - 2);
    return naira + "." + kobo;
  }

  render() {
    const {loading, filterable, role} = this.state
    //   var data = [];
    //   payments ? data = payments  : data = [];
    /* console.log(loading); */
    var columns = [];

    columns = [
      {
        Header: props => <span><b>SN</b></span>,
        id: "sn",
        filterable,
        maxWidth: 80,
        accessor: d => d,// String-based value accessors!
        Cell: (row) => {
          return <span>{row.index + 1}</span>;
        }
      }, {
        Header: props => <span><b>Order ID</b></span>,
        id: "id",
        filterable,
        maxWidth: 90,
        accessor: d => d,// String-based value accessors!
        Cell: props => <a href="#"
                          onClick={(e) => this.onOpenModal(e, "fullModal", props.value.transaction, props.value)}>{formatToSixDigits(props.value.transaction && props.value.transaction.id)}</a>
      },
      {
        Header: props => <span><b>Service Type</b></span>,
        id: "type",
        filterable,
        maxWidth: 100,
        accessor: d => d.transaction ? d.transaction.type : null,// String-based value accessors!
        Cell: props => <span>{props.original.transaction ? (props.original.transaction.type == 1 ? 'eCommerce' : props.original.transaction.type == 2 ? 'P2P' : 'Other') : 'Other'}</span>
      },

      {
        id: 'amount', // Required because our accessor is not a string
        Header: props => <span><b>Amount Paid</b></span>,
        filterable,
        maxWidth: 140,
        accessor: d => d, // Custom value accessors!
        Cell: props => <span
           className='number'>{props.value === null ? '' : '₦' + (props.value.amount / 100).toLocaleString('en', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        })}</span> // Custom cell components!

      },

      {
        id: 'sellername', // Required because our accessor is not a string
        Header: props => <span><b>Seller</b></span>,
        filterable,
        maxWidth: 140,
        accessor: d => d.user ? d.user.firstname : null, // Custom value accessors!
        Cell: props => <span>
          {
            props.original.transaction && props.original.transaction.user ? props.original.transaction.user.firstname + ' ' + props.original.transaction.user.lastname : 'N/A'
          }
        </span>

      },

      {
        id: 'buyername', // Required because our accessor is not a string
        Header: props => <span><b>Buyer</b></span>,
        filterable,
        maxWidth: 140,
        accessor: d => d.transaction ? (d.transaction.customers ? d.transaction.customers.firstname : null) : null, // Custom value accessors!
        Cell: props => <span>
          {
            props.original.transaction && props.original.transaction.customers ? props.original.transaction.customers.firstname + ' ' + props.original.transaction.customers.lastname : 'N/A'
          }
        </span>

      },
      {
        id: 'recipient', // Required because our accessor is not a string
        Header: props => <span><b>Recipient</b></span>,
        filterable,
        accessor: d => d, // Custom value accessors!
        Cell: props =>
           <span>{props.value.type === 10 ? "Service Provider" : props.value.account && props.value.account.type === 1 ? 'Own Account' : props.value.account && props.value.account.type === 2 ? 'Service Provider' : props.value.type === 1 || props.value.type === 2 || props.value.type === 4 || props.value.type === 5 ? 'Own Account' : 'Buyer\'s Account'}</span> // Custom cell components!
      },
      {
        id: "datePaid",
        filterable,
        maxWidth: 170,
        Header: props => <span><b>Date Paid</b></span>,
        accessor: d => d, // String-based value accessors!
        Cell: props => <span>{props.value === null ? '' : props.value.created_at}</span>
      }, {
        Header: props => <span><b>Transaction No.</b></span>,
        id: "transactionNo",
        filterable,
        accessor: d => d.transaction,
        Cell: props => <span
           className='number'>{props.value === null ? '' : props.value.payment && props.value.payment.payment_ref}</span> // Custom cell components!
      }, {
        id: "status",
        filterable,
        maxWidth: 100,
        Header: props => <span><b>Status</b></span>,
        accessor: d => d, // String-based value accessors!
        className: 'text-center',
        Cell: props => <div>
          {props.value.status == 1 ? (<span class="badge badge-success">Completed </span>) : props.value.status == 2 ? (
             <span class="badge badge-info">GW-Pending</span>) : props.value.status == 3 ? (
             <span class="badge badge-danger">GW-Failed</span>) : (<span class="badge badge-warning">Pending</span>)}
        </div>
      },]
    /* console.log("d", this.state.transaction); */
    return (

       <div>
         <Modal open={this.state.fullModal} onClose={() => this.onCloseModal("fullModal")} center>
           <form>
             <div class="modal-header">
               <h4 class="modal-title">
                 <small>Transaction {formatToSixDigits(this.state.transaction && this.state.transaction.id)}</small>
               </h4>
             </div>
             <div class="modal-body">
               <div class="row"></div>
               <div class="row">
                 <div class="col-sm-6">
                   <div class="form-group"><label>Order ID</label> <br/> <input type="text" readonly="readonly"
                                                                                value={formatToSixDigits(this.state.transaction && this.state.transaction.id)}
                                                                                class="form-control"/></div>
                 </div>
                 <div class="col-sm-6">
                   <div class="form-group"><label>Order Value</label> <br/> <input type="text" readonly="readonly"
                                                                                   value={this.state.transaction && '₦' + (this.state.transaction.amount_payed / 100).toLocaleString('en', {
                                                                                     minimumFractionDigits: 2,
                                                                                     maximumFractionDigits: 2
                                                                                   })} class="form-control"/></div>
                 </div>
               </div>
               <div class="row">
                 {role === 'seller' ?
                    <div class="col-sm-6">
                      <div class="form-group"><label>Buyer Name</label> <br/> <input type="text" readonly="readonly"
                                                                                     value={(this.state.transaction && this.state.pay.customer && this.state.pay.customer.firstname) + " " + (this.state.pay && this.state.pay.customer && this.state.pay.customer.lastname)}
                                                                                     class="form-control"/></div>
                    </div> :
                    <div class="col-sm-6">
                      <div class="form-group"><label>Seller Name</label> <br/> <input type="text" readonly="readonly"
                                                                                      value={(this.state.transaction && this.state.transaction.user && this.state.transaction.user.firstname) + " " + (this.state.transaction && this.state.transaction.sellers && this.state.transaction.user && this.state.transaction.user.lastname)}
                                                                                      class="form-control"/></div>
                    </div>
                 }
                 <div class="col-sm-6">
                   {role === 'seller' ?
                      <div class="form-group"><label><small><b>Marketplace Business Name</b></small></label> <br/>
                        <input type="text" readonly="readonly"
                               value={this.state.pay && this.state.pay.marketplace && this.state.pay.marketplace.marketplaceuser && this.state.pay.marketplace.marketplaceuser.seller ? this.state.pay.marketplace.marketplaceuser.seller.business_name :
                                  this.props.user && this.props.user.seller && this.props.user.seller.individual ? this.props.user.firstname + " " + this.props.user.lastname : this.props.user && this.props.user.seller && this.props.user.seller.business_name
                               } class="form-control"/></div>
                      :
                      <div class="form-group"><label><small><b>Marketplace Business Name</b></small></label> <br/>
                        <input type="text" readonly="readonly"
                               value={this.state.transaction && this.state.transaction.sellers && this.state.transaction.sellers.business_name ? this.state.transaction && this.state.transaction.sellers && this.state.transaction.sellers.business_name : "N/A"}
                               class="form-control"/></div>
                   }
                 </div>
               </div>
               <div class="row">
                 <div class="col-sm-6">
                   <div class="form-group"><label>Escrow Fee Paid By</label> <br/> <input type="text"
                                                                                          readonly="readonly"
                                                                                          value={this.state.transaction && this.state.transaction.escrow_fee_bearer === "seller" ? "Seller" : this.state.transaction && this.state.transaction.escrow_fee_bearer === "both" ? "Both" : "Buyer"}
                                                                                          class="form-control"/></div>
                 </div>
                 <div class="col-sm-6">
                   <div class="form-group"><label>Escrow Fee</label> <br/> <input type="text" readonly="readonly"
                                                                                  value={this.state.transaction && '(₦' + (transactionHelper.calculateTransactionEscrow((this.state.transaction.amount_net + (this.state.transaction.shipping_cost * (this.state.transaction && this.state.transaction.shipping_fee_bearer === "both" ? 0.5 : this.state.transaction && this.state.transaction.shipping_fee_bearer === "seller" ? 0 : 1))) / 100) * (this.state.transaction && this.state.transaction.escrow_fee_bearer === "both" ? 0.5 : 1)).toLocaleString('en', {
                                                                                    minimumFractionDigits: 2,
                                                                                    maximumFractionDigits: 2
                                                                                  }) + ')'}
                                                                                  class="form-control text-danger"/>
                   </div>
                 </div>

               </div>
               <div class="row">
                 <div class="col-sm-6">
                   <div class="form-group"><label>Shipping Fee Paid By</label> <br/> <input type="text"
                                                                                            readonly="readonly"
                                                                                            value={this.state.transaction && this.state.transaction.shipping_fee_bearer === "seller" ? "Seller" : this.state.transaction && this.state.transaction.shipping_fee_bearer === "both" ? "Both" : "Buyer"}
                                                                                            class="form-control"/></div>
                 </div>
                 <div class="col-sm-6">
                   {role === "seller" ?
                      <div class="form-group"><label>Shipping Fee</label> <br/> <input type="text" readonly="readonly"
                                                                                       value={this.state.transaction && '₦' + (this.state.transaction.shipping_cost / 100).toLocaleString('en', {
                                                                                         minimumFractionDigits: 2,
                                                                                         maximumFractionDigits: 2
                                                                                       })} class="form-control"/>
                      </div> :
                      this.state.transaction && this.state.transaction.shipping_fee_bearer === "seller" ?
                         <div class="form-group"><label>Shipping Fee</label> <br/> <input type="text"
                                                                                          readonly="readonly"
                                                                                          value={this.state.transaction && '(₦' + (this.state.transaction.shipping_cost / 100).toLocaleString('en', {
                                                                                            minimumFractionDigits: 2,
                                                                                            maximumFractionDigits: 2
                                                                                          }) + ')'}
                                                                                          class="form-control text-danger"/>
                         </div> :
                         <div class="form-group"><label>Shipping Fee</label> <br/> <input type="text"
                                                                                          readonly="readonly"
                                                                                          value={this.state.transaction && '₦' + (this.state.transaction.shipping_cost / 100).toLocaleString('en', {
                                                                                            minimumFractionDigits: 2,
                                                                                            maximumFractionDigits: 2
                                                                                          })} class="form-control"/>
                         </div>
                   }
                 </div>
               </div>
               <div class="row">
                 <div class="col-sm-6">
                   <div class="form-group"><label style={{'font-size': '12px'}}>Card & Transfer Fee Paid By</label>
                     <br/> <input type="text" readonly="readonly"
                                  value={this.state.transaction && this.state.transaction.tx_fee_bearer === "seller" ? "Seller" : this.state.transaction.sla === 1 ? "Seller" : "Buyer"}
                                  class="form-control"/></div>
                 </div>
                 <div class="col-sm-6">

                   {this.state.transaction && this.state.transaction.status === 9 && this.state.transaction.tx_fee_bearer === "seller" && this.state.transaction.sla === 1 ?
                      <div class="form-group"><label>Card & Transfer Fee</label> <br/> <input type="text"
                                                                                              readonly="readonly"
                                                                                              value={this.state.transaction && '₦' + this.calculateCardFee(this.state.transaction, 'customer').toLocaleString('en', {
                                                                                                minimumFractionDigits: 2,
                                                                                                maximumFractionDigits: 2
                                                                                              })} class="form-control"/>
                      </div>
                      :
                      <div class="form-group"><label>Card & Transfer Fee</label> <br/> <input type="text"
                                                                                              readonly="readonly"
                                                                                              value={this.state.transaction && '(₦' + this.calculateCardFee(this.state.transaction, 'customer').toLocaleString('en', {
                                                                                                minimumFractionDigits: 2,
                                                                                                maximumFractionDigits: 2
                                                                                              }) + ')'}
                                                                                              class="form-control text-danger"/>
                      </div>
                   }
                 </div>
               </div>
               <div class="row">
                 <div class="col-sm-6">
                   <div class="form-group"><label>Product Amount</label> <br/> <input type="text" readonly="readonly"
                                                                                      value={this.state.transaction && '₦' + (this.state.transaction.product_cost / 100).toLocaleString('en', {
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2
                                                                                      })} class="form-control"/></div>
                 </div>
                 <div class="col-sm-6">
                   {role === 'seller' ?
                      <div class="form-group"><label>Total Payout</label> <br/> <input type="text" readonly="readonly"
                                                                                       value={this.state.pay && '₦' + (this.state.pay.amount / 100).toLocaleString('en', {
                                                                                         minimumFractionDigits: 2,
                                                                                         maximumFractionDigits: 2
                                                                                       })} class="form-control"/>
                      </div> :
                      <div class="form-group"><label>Total Refund</label> <br/> <input type="text" readonly="readonly"
                                                                                       value={this.state.pay && '₦' + (this.state.pay.amount / 100).toLocaleString('en', {
                                                                                         minimumFractionDigits: 2,
                                                                                         maximumFractionDigits: 2
                                                                                       })} class="form-control"/></div>
                   }
                 </div>
               </div>
               <div class="row">
                 <div class="col-sm-6">
                   <div class="form-group"><label>Payment Channel</label> <br/> <input type="text" readonly="readonly"
                                                                                       value={this.state.transaction && this.state.transaction.payment ? this.state.transaction && this.state.transaction.payment && this.state.transaction.payment.method : "Card"}
                                                                                       class="form-control"/></div>
                 </div>
                 <div class="col-sm-6">
                   {role === 'seller' ?
                      <div class="form-group"><label>Payout Status</label> <br/> <input type="text" readonly="readonly"
                                                                                        value={this.state.pay && this.state.pay.status ? "Paid" : "Pending"}
                                                                                        class="form-control"/></div> :
                      <div class="form-group"><label>Refund Status</label> <br/> <input type="text" readonly="readonly"
                                                                                        value={this.state.pay && this.state.pay.status ? "Paid" : "Pending"}
                                                                                        class="form-control"/></div>
                   }
                 </div>
               </div>
               {this.state.transaction && this.state.transaction.status === 9 && this.state.transaction.tx_fee_bearer === "seller" && this.state.transaction.sla === 1 ?
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group"><label>Cancellation Fee</label> <br/> <input type="text"
                                                                                           readonly="readonly"
                                                                                           value={this.state.transaction && '₦' + (this.state.transaction.cancellation_fee / 100).toLocaleString('en', {
                                                                                             minimumFractionDigits: 2,
                                                                                             maximumFractionDigits: 2
                                                                                           })} class="form-control"/>
                      </div>
                    </div>
                  </div> : null}
               <br/><br/>
             </div>
           </form>
         </Modal>
         <div class="content-wrapper" style={{minHeight: 136}}>
           <div class="content-header">
             <div class="container-fluid">
               <div class="row mb-2">

                 <div class="col-sm-12">
                   <h1 class="m-0 text-dark">Refund History</h1>
                   <div class="attop">
                     <p>
                       On this page, you will find details of refunds or payouts made by AtaraPay to you or your Service
                       Provider respectively, on orders placed from the Seller’s website or P2P escrow transaction you
                       created and accepted or rejected the service.
                     </p>
                     <p>
                       You can filter by refund made to Buyer, in cases of rejected service or payout to your Service
                       Provider, in cases of accepted service.
                     </p>
                   </div>
                   <br/>

                 </div>
               </div>
             </div>
           </div>
           {!loading ?
              <section class="content">
                <div class="container-fluid"><br/>
                  <div class="row ">
                    <div class="col-12 col-sm-12 col-md-12">
                      <div class="alert animated bounceIn alert-danger d-none search-alert"><a aria-label="close"
                                                                                               class="close alert-close">×</a>
                        <span></span></div>
                      <div class="card">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-12 col-sm-2">
                              <div class="form-group"><label for="period">Period</label>
                                <select class="form-control" name="filter" onChange={this.handleSelect}>
                                  <option value="All">All</option>
                                  <option value="This Week">This Week</option>
                                  <option value="This Month">This Month</option>
                                  <option value="60 Days Ago">60 Days Ago</option>
                                  <option value="90 Days Ago">90 Days Ago</option>
                                  <option value="Custom">Custom</option>
                                </select></div>
                            </div>
                            <div class="col-12 col-sm-2">
                              <div class="form-group"><label for="filter">Filter</label><input type="text"
                                                                                               placeholder="Filter"
                                                                                               class="form-control"
                                                                                               value={this.state.filter}
                                                                                               onChange={this.handleFilter}/>
                              </div>
                            </div>
                            <div class="col-12 col-sm-3">
                              <div class="form-group"><label for="from">Date From</label> <input type="date"
                                                                                                 name="from_date"
                                                                                                 value={this.state.from_date}
                                                                                                 disabled={this.state.notCustom}
                                                                                                 onChange={this.handleDate}
                                                                                                 class="form-control"/>
                              </div>
                            </div>
                            <div class="col-12 col-sm-3">
                              <div class="form-group"><label for="from">Date To</label> <input type="date"
                                                                                               name="to_date"
                                                                                               value={this.state.to_date}
                                                                                               disabled={this.state.notCustom}
                                                                                               onChange={this.handleDate}
                                                                                               class="form-control"/>
                              </div>
                            </div>
                            <div class="col-12 col-sm-2">
                              <div class="form-group"><label>&nbsp;</label>
                                <div class="row">
                                  <div class="col-12">
                                    <button disabled={this.state.notCustom} onClick={() => {
                                      this.setState({data: [...this.filterDates(this.state.data)]})
                                    }} class="btn btn-info btn-block form-control">
                                      <center>Search</center>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br/>
                          <div class="table-responsive">
                            <ReactTable
                               defaultPageSize={5}
                               filterable
                               data={this.state.data}
                               columns={columns}/>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br/>
                </div>
              </section> :
              <div className='sweet-loading'>
                <ClipLoader
                   className={override}
                   sizeUnit={"px"}
                   size={70}
                   color={'blue'}
                   loading={loading}
                />
              </div>
           }
         </div>
       </div>
    )
  }
}

export default RefundHistory;