import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store from './store';
import { Provider } from 'react-redux';
import "babel-polyfill";
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import * as Sentry from '@sentry/browser';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
// import * as serviceWorker from './serviceWorker';
import { HashRouter as Router } from 'react-router-dom'; 

// Sentry bug tracker init
Sentry.init({dsn: "https://853f672e807d436299cf4b4130ddf020@o396371.ingest.sentry.io/5249627"});

// Bugsnag tracker init
Bugsnag.start({
    apiKey: 'f1fa2814f6a51a610bbeeeedd461303c',
    plugins: [new BugsnagPluginReact()]
  })

  const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

ReactDOM.render(
 <Provider store={store}>
<Router>
<ErrorBoundary>
<App />
</ErrorBoundary>
</Router>
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register();
