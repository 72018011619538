import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { ClipLoader } from "react-spinners";

import { payoutActions } from "../actions";

class Identification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      idNumber: "",
      action: "",
    };
  }

  componentDidMount = () => {
    const { idNumber, action } = this.props.match.params;
    this.props.managePhotoId(idNumber, action, this.props.history);
  };

  render() {
    return (
      <div className="background-gradient" style={{ height: "100vh" }}>
        <nav className="navbar navbar-expand-lg fixed-top navbar-inverse">
          <a className="navbar-brand" href="https://www.atarapay.com">
            <img
              src="/dist/img/logo-header.png"
              alt=""
              className="logo-img img img-responsive"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarText"
            aria-controls="navbarText"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </nav>

        <div className="row row-margin top-margin-60">
          <div className="col-sm-3 col-md-3" />
          <div className="col-sm-8 col-md-6 col-12">
            <div className="card card-margin">
              <div className="tab-content">
                <div
                  role="tabpanel"
                  id="seller"
                  className={`tab-pane left-margin-35 active`}
                >
                  <br />
                  <div className=" text-dark d-flex w-100 justify-content-center align-items-center">
                    <div className="text-center bg-white  p-5">
                      <ClipLoader loading={true} size={100} />
                      <div>
                        <p className="mt-2">
                          Please wait. This will take a few seconds.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3 col-md-3" />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const { loading } = state.payout;
  return {
    loading,
  };
};

const mapDispatchToProps = (dispatch) => ({
  managePhotoId: (id, action, history) =>
    dispatch(payoutActions.payout.managePhotoId(id, action, history)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Identification)
);
