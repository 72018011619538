import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {jsx, css} from '@emotion/core';
import swal from '@sweetalert/with-react';
import Modal from 'react-responsive-modal';
import {alertActions} from '../actions';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import * as moment from 'moment';
import * as axios from "axios";
import {BASE_URL} from "../constants";
import {ClipLoader} from "react-spinners";

const formatToSixDigits = (number) => {
  var output = number + '';
  while (output.length < 6) {
    output = '0' + output;
  }
  return output;
}

const override = css`
display: block;
margin-top:10%;
margin-left:40%;
margin-right:60%;
border-color: red;
`;

class Disputes extends Component {

  state = {
    filer: " ",
    data: [],
    notCustom: true,
    period: "All",
    from_date: "",
    to_date: "",
    disputes: [],
    loading: false,
    role: 'seller'
  }

  componentDidMount() {

    this.getData();
  }

  getData = () => {
    this.setState({
      loading: true,
    })
    axios({
      method: 'get',
      mode: 'no-cors',
      url: `${BASE_URL}admin/disputes`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
       .then(res => {
         console.log(res.data)
         this.setState({
           data: res.data.data,
           disputes: res.data.data,
         })
       })
       .catch(err => {
         console.log(err)
       })
       .finally(() => {
         this.setState({
           loading: false,
         })
       })
  }

  handleSelect = (e) => {
    const {name, value} = e.target;
    if (value === "Custom") {
      this.setState({notCustom: !this.state.notCustom})
    }
    this.filterPeriod(value);
    // const period = {period: value}
    this.setState({period: value});
  }
  filterPeriod = (value) => {
    const data = this.state.disputes;
    //    console.log(data);

    if (value === "All") {
      this.table_rows = data;
      this.notCustom = true;
      this.page = 1;
      this.setState({to_date: "", from_date: "", notCustom: true, data: [...this.state.disputes]});
    }

    if (value === "This Week") {
      var disputesThisWeek = [];

      for (var i = 0; i < data.length; i++) {
        var now = moment();
        var input = moment(data[i].dispute.created_at);
        var isThisWeek = input.isSame(now, "week");

        if (isThisWeek) {
          disputesThisWeek.push(data[i]);
        }
      }

      this.table_rows = disputesThisWeek;
      this.notCustom = true;
      this.page = 1;
      console.log(this.table_rows);
      this.setState({to_date: "", from_date: "", notCustom: true, data: [...this.table_rows]});
    }

    if (value === "This Month") {
      var disputesThisMonth = [];

      for (let i = 0; i < data.length; i++) {
        //var now = moment();
        let input = moment(data[i].dispute.created_at);
        var isThisMonth = input.isSame(new Date(), 'month');

        if (isThisMonth) {
          disputesThisMonth.push(data[i]);
        }
      }

      this.table_rows = disputesThisMonth;
      this.notCustom = true;
      this.page = 1;
      this.setState({to_date: "", from_date: "", notCustom: true, data: [...this.table_rows]});
    }

    if (value === "60 Days Ago") {
      var disputesSixtyDaysAgo = [];

      for (let i = 0; i < data.length; i++) {

        var sixty_days_ago = moment().subtract(60, 'days');

        var isBetweenSixtyDays = moment(data[i].dispute.created_at).isAfter(sixty_days_ago);

        if (isBetweenSixtyDays) {
          disputesSixtyDaysAgo.push(data[i]);
        }
      }

      this.table_rows = disputesSixtyDaysAgo;
      this.notCustom = true;
      this.page = 1;
      this.setState({to_date: "", from_date: "", notCustom: true, data: [...this.table_rows]});
    }

    if (value == "90 Days Ago") {
      var disputesNinetyDaysAgo = [];

      for (let i = 0; i < data.length; i++) {

        var ninety_days_ago = moment().subtract(90, 'days');

        var isBetweenNinetyDays = moment(data[i].dispute.created_at).isAfter(ninety_days_ago);

        if (isBetweenNinetyDays) {
          disputesNinetyDaysAgo.push(data[i]);
        }
      }

      this.table_rows = disputesNinetyDaysAgo;
      this.notCustom = true;
      this.page = 1;
      this.setState({to_date: "", from_date: "", notCustom: true, data: [...this.table_rows]});
    }

    if (value === "Custom") {
      this.table_rows = data;
      this.setState({to_date: "", from_date: ""});
      //   this.state.notCustom =  !this.state.notCustom;
      //   return this.table_rows;

    }
  }

  filterDates = (data) => {

    if (this.state.from_date === "") {
      swal("Error", "Please select a From date", "error");
      return this.state.disputes;
    }
    if (this.state.to_date === "") {
      swal("Error", "Please select a To date", "error");
      return this.state.disputes;
    }

    if (moment(this.state.from_date).isAfter(this.state.to_date)) {
      swal("Error", "Please select a From date that is before your To date", "error");
      return this.state.disputes;
    }

    var disputesBetweenTheTwoDates = [];

    for (var i = 0; i < data.length; i++) {

      var isBetweenDates = moment(data[i].dispute.created_at).isBetween(this.state.from_date, this.state.to_date); // true
      if (isBetweenDates) {
        disputesBetweenTheTwoDates.push(data[i]);
      }
    }

    this.table_rows = disputesBetweenTheTwoDates;
    //this.notCustom = true;
    this.page = 1;
    //this.to_date = null;
    //this.from_date = null;
    return this.table_rows;
  }
  // componentDidMount= ()=>{
  //     'i got called';
  //     this.props.view("seller",this.props.history);
  // }
  handleDate = (e) => {
    const {name, value} = e.target;
    this.setState({[name]: value});

  }


  handleCancel = (e) => {
    e.preventDefault();
    const {order} = this.state;
    this.props.cancel(localStorage.getItem("trust_user_type"), {dispute_id: order}, this.props.history);

  }

  filterTable = (filter) => {
    var data = this.state.disputes;
    var filteredRows = [];
    // this.setState({data:[...this.state.deliveryMen]});
    if (filter != "") {
      for (var i = 0; i < data.length; i++) {

        if (formatToSixDigits(data[i].id).match(new RegExp(filter, "gi")) ||
           formatToSixDigits(data[i].transaction_id).match(new RegExp(filter, "gi")) ||
           (data[i].dispute[0] && data[i].dispute[0].reason && data[i].dispute[0].reason.match(new RegExp(filter, "gi"))) ||
           (data[i].user && (data[i].user.firstname + ' ' + data[i].user.lastname ).toUpperCase().match(filter.toUpperCase())) ||
           (data[i].customers && (data[i].customers.firstname + ' ' + data[i].customers.lastname ).toUpperCase().match(filter.toUpperCase())) ||
           (data[i].defination && data[i].defination.name.toUpperCase().match(filter.toUpperCase())) ||
           (data[i].type && data[i].type.title.toUpperCase().match(filter.toUpperCase())) ||
           (data[i].dispute[0] && data[i].dispute[0].created_at && data[i].dispute[0].created_at.match(filter))
        ) {
          filteredRows.push(data[i]);
        }
      }

      this.setState({data: [...filteredRows]});
    } else {
      this.setState({data: [...this.state.disputes]});
    }

  }

  handleFilter = (e) => {
    const {value} = e.target;
    this.setState({filter: value}, () => this.filterTable(this.state.filter));
    // this.filterTable();

  }
  // componentDidMount= ()=>{
  //     if(this.props.disputes){
  //         console.log(this.props.disputes);
  //         this.setState({data:[...this.props.disputes]});
  //     }
  // }

  componentWillReceiveProps(props) {
    if (props.disputes) {
      this.setState({data: [...props.disputes]});
      this.setState({disputes: [...props.disputes]})

    }

    if (props.alert && props.alert.message && props.alert.type === "alert-danger") {
      swal("Error", props.alert.message, "error").then(() => {
        props.dispatch(alertActions.alert.clear());
      });
    }

    if (props.alert && props.alert.message && props.alert.type === "alert-success") {
      swal("Success", props.alert.message, "success").then(() => {
        props.dispatch(alertActions.alert.clear());
      });
    }
  }

  onOpenModal = (e, name, order) => {
    e.preventDefault();
    this.setState({[name]: true});
    this.setState({order: order});

  };

  onCloseModal = (name) => {
    this.setState({[name]: false});
    this.setState({order: ""});
  };

  render() {

    const {data, filterable, loading} = this.state;
    var columns = [];

    columns = [{
      Header: props => <span><b>SN</b></span>,
      id: "sn",
      filterable,
      maxWidth: 80,
      accessor: d => d,// String-based value accessors!
      Cell: (row) => {
        return <span>{row.index + 1}</span>;
      }
    },
      {
        Header: props => <span><b>Order Number</b></span>,
        id: "order_id",
        accessor: d => d && d.dispute && d.dispute[0] ? d.dispute[0].transaction_id : null,
        maxWidth: 140,
        Cell: props => <span className='number'><a href="#/app/disputes/all"
                                                   onClick={(e) => this.onOpenModal(e, "fullModal", props.original)}>{formatToSixDigits(props.original.dispute[0].transaction_id)}</a></span> // Custom cell components!
      },
      {
        Header: props => <span><b>Service Type</b></span>,
        id: "servicetype",
        accessor: d => d && d.type ? d.type.title : null,
        maxWidth: 140,
        Cell: props =>
            <span>
                  {/*{props.value.type.id == 1 ? 'P2P' : props.value.type.id == 2 ? 'Ecommerce' : 'Other'}*/}
              {props.original.type ? ((props.original.type.id == 2 || props.original.type.id == 3) ? 'P2P' :

                      props.original.type.id == 1 ? 'eCommerce' : 'AtaraNg'
              ) : 'N/A'}
                </span>
      },
      {
        Header: props => <span><b>Seller</b></span>,
        id: "seller",
        accessor: d => d && d.user ? d.user.firstname : null,
        maxWidth: 140,
        Cell: props => <span className='number'>
          {props.original.user ? props.original.user.firstname + ' ' + props.original.user.lastname : 'N/A'}
        </span> // Custom cell components!
      },
      {
        Header: props => <span><b>Buyer</b></span>,
        id: "buyer",
        accessor: d => d && d.customers ? d.customers.firstname : null,
        maxWidth: 140,
        Cell: props => <span className='number'>
          {props.original.customers ? props.original.customers.firstname + ' ' + props.original.customers.lastname : 'N/A'}
        </span> // Custom cell components!
      },
      {
        Header: props => <span><b>Marketplace</b></span>,
        id: "marketplace",
        accessor: d => d && d.marketplaceuser ? d.marketplaceuser.firstname : null,
        maxWidth: 140,
        Cell: props => <span className='number'>
          {props.original.user && props.original.user.marketplace ? props.original.user.marketplace.business_name  : 'N/A'}
        </span> // Custom cell components!
      },
      {
        id: 'issue', // Required because our accessor is not a string
        Header: props => <span><b>Issue</b></span>,
        accessor: d => d, // Custom value accessors!
        Cell: props => <span>{props.value.dispute[0] && props.value.dispute[0].reason}</span>

      }, {
        id: "date_created",
        maxWidth: 170,
        Header: props => <span><b>Date Created</b></span>,
        accessor: d => d.dispute[0].created_at // String-based value accessors!
      }, {
        id: 'status',
        Header: props => <span><b>Dispute Status</b></span>,
        maxWidth: 120,
        accessor: d => d.dispute[0], // String-based value accessors!
        className: 'text-center',
        Cell: props => <div>
          {props.value && props.value.status === 0 ?
             <span class="badge badge-success">Resolved</span> : props.value.status === 1 ?
                <span class="badge badge-warning">Dispute</span> : props.value.status === 2 ?
                   <span class="badge badge-info">Disputed</span> :
                   <span class="badge badge-danger">Canceled</span>}
        </div>
      },
      // {
      //   id: "actions",
      //   sortable: false,
      //   filterable: false,
      //   maxWidth: 110,
      //   Header: props => <span><b>Actions</b></span>,
      //   accessor: d => d,
      //   Cell: props => <span>
      //           {/* {props.value.status.title == 'Disputed' &&<Link to={`/app/seller/dispute/raise/${props.value.id}`} class="text-danger" v-if="order.status.title == 'Disputed'">
      //           <small>Cancel Dispute</small>
      //           &nbsp;&nbsp;
      //           </Link>} */}
      //     {props.value.dispute[0].status === 1 && this.state.role === 'seller' &&
      //     <Link to={`/app/seller/dispute/raise/${props.value.id}`} class="text-danger">
      //       <small>Raise Dispute</small><br/></Link>}
      //
      //     {(props.value.dispute[0].status === 1 || props.value.dispute[0].status === 2) &&
      //     <a style={{cursor: "pointer"}} class="text-dark"
      //        onClick={e => this.onOpenModal(e, "cancel", props.value.id)}>
      //       <small>Cancel Dispute</small></a>
      //
      //     }
      //       </span> // String-based value accessors!
      // }
    ]

    return (
       <div class="content-wrapper" style={{minHeight: 311}}>
         <div class="content-header">
           <div class="container-fluid">
             <div class="row mb-2">
               <div class="col-sm-12">
                 <h1 class="m-0 text-dark">All Disputes</h1>
               </div>
             </div>
           </div>
         </div>
         <section class="content">
           <div class="container-fluid"><br/>
             <div class="row">
               <div class="col-12 col-sm-12 col-md-12">
                 {/* {alert && alert.message && alert.type!="alert-danger"&&<div class={`alert buyer-alert ${alert.type}`} ><a
                                aria-label="close" class="close alert-close">×</a> <span   class="alert-message-content">{alert.message}</span></div>} */}
                 {!loading ?
                    <div class="card">

                      <div class="card-body">

                        <div class="row">
                          <div class="col-12 col-sm-2">
                            <div class="form-group"><label for="period">Period</label>
                              <select class="form-control" name="filter" onChange={this.handleSelect}>
                                <option value="All">All</option>
                                <option value="This Week">This Week</option>
                                <option value="This Month">This Month</option>
                                <option value="60 Days Ago">60 Days Ago</option>
                                <option value="90 Days Ago">90 Days Ago</option>
                                <option value="Custom">Custom</option>
                              </select></div>
                          </div>
                          <div class="col-12 col-sm-2">
                            <div class="form-group"><label for="filter">Filter</label><input type="text"
                                                                                             placeholder="Filter"
                                                                                             class="form-control"
                                                                                             value={this.state.filter}
                                                                                             onChange={this.handleFilter}/>
                            </div>
                          </div>
                          <div class="col-12 col-sm-3">
                            <div class="form-group"><label for="from">Date From</label> <input type="date"
                                                                                               name="from_date"
                                                                                               value={this.state.from_date}
                                                                                               disabled={this.state.notCustom}
                                                                                               onChange={this.handleDate}
                                                                                               class="form-control"/>
                            </div>
                          </div>
                          <div class="col-12 col-sm-3">
                            <div class="form-group"><label for="from">Date To</label> <input type="date"
                                                                                             name="to_date"
                                                                                             value={this.state.to_date}
                                                                                             disabled={this.state.notCustom}
                                                                                             onChange={this.handleDate}
                                                                                             class="form-control"/>
                            </div>
                          </div>
                          <div class="col-12 col-sm-2">
                            <div class="form-group"><label>&nbsp;</label>
                              <div class="row">
                                <div class="col-12">
                                  <button disabled={this.state.notCustom} onClick={() => {
                                    this.setState({data: [...this.filterDates(this.state.disputes)]})
                                  }} class="btn btn-info btn-block form-control">
                                    <center>Search</center>
                                  </button>
                                </div>
                                {/* <div class="col-4">
                                                            <button onClick={()=>{this.setState({filterable: !filterable})}} class="btn btn-info btn-md">
                                                                <i class="fa fa-search"></i>
                                                            </button>
                                                        </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        <br/>

                        <div class="table-responsive">
                          <ReactTable
                             defaultPageSize={5}
                             data={data}
                             columns={columns}/>

                        </div>
                      </div>
                    </div>
                    :
                    <div className='sweet-loading'>
                      <ClipLoader
                         className={override}
                         sizeUnit={"px"}
                         size={70}

                         color={'blue'}
                         loading={loading}
                      />
                    </div>
                 }
               </div>
             </div>
             <br/>
           </div>
         </section>
         <div>
           <Modal open={this.state.fullModal} onClose={() => this.onCloseModal("fullModal")} center>
             <form>
               <div class="modal-header">
                 <h4 class="modal-title"><small></small></h4>
               </div>
               <div class="modal-body">
                 <div class="row"></div>
                 <div class="row">
                   <div class="col-sm-6">
                     <div class="form-group"><label>Order Number</label> <br/> <input type="text" readonly="readonly"
                                                                                      value={formatToSixDigits(this.state.order && this.state.order.id)}
                                                                                      class="form-control"/></div>
                   </div>
                   <div class="col-sm-6">
                     <div class="form-group"><label>Amount</label> <br/> <input type="text" readonly="readonly"
                                                                                value={this.state.order && '₦' + (this.state.order.amount_payed / 100).toLocaleString('en', {
                                                                                  minimumFractionDigits: 2,
                                                                                  maximumFractionDigits: 2
                                                                                })} class="form-control"/></div>
                   </div>
                 </div>
                 <div class="row">
                   <div class="col-sm-6">
                     <div class="form-group"><label>Order Date</label> <br/> <input type="text" readonly="readonly"
                                                                                    value={this.state.order && this.state.order.transaction_date}
                                                                                    class="form-control"/></div>
                   </div>
                   <div class="col-sm-6">
                     <div class="form-group"><label>Order Status</label> <br/> <input type="text" readonly="readonly"
                                                                                      value={this.state.order && this.state.order.status && this.state.order.status.title}
                                                                                      class="form-control"/></div>
                   </div>
                 </div>
                 <div class="row">
                   <div class="col-sm-6">
                     <div class="form-group"><label>Buyer Name</label> <br/> <input type="text" readonly="readonly"
                                                                                    defaultValue={this.state.order && this.state.order.customers && this.state.order.customers.firstname + " " + this.state.order.customers.lastname}
                                                                                    class="form-control"/></div>
                   </div>
                 </div>
                 <div class="row">
                   <div class="col-sm-6">
                     <div class="form-group"><label>Delivery Location</label> <br/> <input type="text"
                                                                                           readonly="readonly"
                                                                                           value={this.state.order && this.state.order.delivery_location}
                                                                                           class="form-control"/></div>
                   </div>
                   <div class="col-sm-6">
                     <div class="form-group"><label>Delivery Date</label> <br/> <input type="text" readonly="readonly"
                                                                                       value={this.state.order && this.state.order.delivery_date}
                                                                                       class="form-control"/></div>
                   </div>
                 </div>
                 <br/><br/>
               </div>
             </form>
           </Modal>
         </div>
       </div>

    )
  }
}

export default Disputes;