import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import TransactionCalculatorSimulatorInputWizard from "./wizard/Input";
import TransactionCalculatorSimulatorOutputWizard from "./wizard/Output";
const INPUT_STAGE = "INPUT_STAGE";
const OUTPUT_STAGE = "OUTPUT_STAGE";
const TransactionCalculatorSimulator = (props) => {
  const [stage, setStage] = useState(INPUT_STAGE);
  const [result, setOutputResult] = useState(null);
  const goNext = () => {
    setStage(OUTPUT_STAGE);
  };
  const goBack = () => {
    setStage(INPUT_STAGE);
  };
  const handleSetOutputResult = (data) => {
    setOutputResult(data);
    goNext(data);
  };
  return (
    <div
      className='content-wrapper'
      style={{ minHeight: 311, marginBottom: "5rem", backgroundColor: "#fff" }}
    >
      {stage === INPUT_STAGE && (
        <TransactionCalculatorSimulatorInputWizard
          goNext={goNext}
          handleSetOutputResult={handleSetOutputResult}
        />
      )}
      {stage === OUTPUT_STAGE && (
        <TransactionCalculatorSimulatorOutputWizard
          simulatorResult={result}
          goBack={goBack}
        />
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  const { sending, success } = state.users;
  return {
    sending,
    success,
    alert: state.alert,
  };
};
const mapDispatchToProps = (dispatch) => ({
  dispatch,
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TransactionCalculatorSimulator)
);
