import React , {Component} from 'react';
import {Link} from 'react-router-dom';
import {withRouter} from 'react-router-dom'
import { USER_ROLE_SELLER, USER_ROLE_MARKET } from '../constants';


class Header extends Component{
   state={
       disabled:false
   }

    getVerifiedStyle = () => {
    const {user} = this.props
    if((user && user.role && user.role.name === USER_ROLE_SELLER || user && user.role && user.role.name === USER_ROLE_MARKET)&& (user.verified !== 1 || user.support_verified !== 1 || user.seller_id === null)){
      return {
        color: 'darkgray ',
        fontWeight: '600'
      }
    } else {
      return {
        fontWeight: '600'
      }
    }
}

componentWillReceiveProps(props){
    const {user} = props
    if((user && user.role && user.role.name === USER_ROLE_SELLER || user && user.role && user.role.name === USER_ROLE_MARKET)&& (user.verified !== 1 || user.support_verified !== 1 || user.seller_id === null)){
       this.setState({disabled:true});
      } else {
        this.setState({disabled:false});
        return {
          fontWeight: '600'
        }
      }
}
   
    handleLogout = ()=>{
        if (this.props.user && this.props.user.role && (this.props.user.role.name === USER_ROLE_SELLER || this.props.user.role.name === USER_ROLE_MARKET))
            this.props.logout('seller',this.props.history);
        else
            this.props.logout('buyer', this.props.history);
    }

    render(){
         const {history,user} = this.props;
         const {disabled} = this.state;

        return(
        <nav className="main-header navbar navbar-expand bg-dark navbar-light border-bottom py-3">
    {/*<!-- Left navbar links --> */}
    <ul className="navbar-nav">
        <li className="nav-item">
            <a className="nav-link" data-widget="pushmenu" href="#"><i className="fa fa-bars"></i></a>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
            <a href="https://www.atarapay.com/faq" className="nav-link"><strong><b>Help</b></strong></a>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
            <a href="https://www.atarapay.com/contact" className="nav-link"><strong><b>Contact Us</b></strong></a>
        </li>
        <li className="nav-item d-none d-sm-inline-block">
            <a href="https://www.youtube.com/watch?v=ybsb-D1as8s&t=8s" className="nav-link" target="_blank"><strong><b>Watch: How AtaraPay Escrow Works</b></strong></a>
        </li>
    </ul>

    {/*
    <!-- Right navbar links --> */}
    <ul className="navbar-nav ml-auto">
        
        <ul className="nav-item dropdown">
            <a className="nav-link el-dropdown-link" data-toggle="dropdown" href="#" aria-expanded="false">
        <i  className="ion-person t-ion" >
        </i>
        </a>
        <div className="dropdown-menu el-dropdown-menu dropdown-menu-right">
          <span className="dropdown-item dropdown-header">Hi {user && user.name}</span>
          <li onClick={() => {
            history.push("/app/profile")
          }} className="dropdown-item ">
            <i className="ion-ios-person mr-2"></i> My Profile

          </li>

          <div className="dropdown-divider"></div>
          <li className="dropdown-item" onClick={this.handleLogout}>
            <i className="ion-log-out mr-2"></i>Log Out
            
          </li>
          <div className="dropdown-divider"></div>
        
        </div>
      </ul>
    </ul>
</nav> 
        )
    }
}

export default withRouter(Header);