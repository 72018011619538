import { BASE_URL, LOCALSTORAGE_TOKEN_KEY } from '../constants';
import axios from 'axios';
import { alertActions } from '../actions';



 const createTerm=(term)=>{
   console.log("tan",term);
   return  axios({
        method: 'post',
        mode: 'no-cors',
        url : `${BASE_URL}seller/term/create`,
        data: term,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Authorization':'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
        }
    })

}
const editTerm=(term)=>{
    console.log("tan",term);
    return  axios({
         method: 'put',
         mode: 'no-cors',
         url : `${BASE_URL}seller/term/edit`,
         data: term,
         headers: {
             'Access-Control-Allow-Origin': '*',
             'Content-Type': 'application/json',
             'Authorization':'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
         }
     })
 
 }

const getTerm=()=>{

    return  axios({
        method: 'get',
        mode: 'no-cors',
        url : `${BASE_URL}seller/terms`,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization':'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        }
    })
 
 }


// function getUser(id) {
//     const requestOptions = {
//         method: 'GET',
//         headers: authHeader()
//     };

//     return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
// }

// function register(user) {
//     const requestOptions = {
//         method: 'POST',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(user)
//     };

//     return fetch(`${config.apiUrl}/users/register`, requestOptions).then(handleResponse);
// }

// function update(user) {
//     const requestOptions = {
//         method: 'PUT',
//         headers: { ...authHeader(), 'Content-Type': 'application/json' },
//         body: JSON.stringify(user)
//     };

//     return fetch(`${config.apiUrl}/users/${user.id}`, requestOptions).then(handleResponse);;
// }
export const term = {
    createTerm,
    editTerm,
    getTerm
};


