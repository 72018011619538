import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Button from "../../components/Button";
import NumberTextBox, { EM_DASH } from "../../components/NumberTextBox";
import {
  PAYMENT_METHOD_MAPPING,
  TRANSACTION_SCENARIOS_MAPPING,
  TRANSACTION_TYPES_MAPPING,
} from "../../constants/transaction";
import {
  BOTH_FEE_BEARER_ID,
  FEE_BEARER_MAPPING,
  SELLER_FEE_BEARER_ID,
} from "../../constants/fee-bearer";
const Wrapper = styled.div`
  margin: 2rem 1rem;
  display: flex;
  flex-direction: column;
`;
const GridWrapper = styled.div`
  background-color: #f2f0f0;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  margin-bottom: 4rem;
`;
const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
`;
const HeaderTitle = styled.h4``;
const SubHeaderTitle = styled.h6`
  font-size: 0.9rem;
`;
const Divider = styled.div`
  background-color: #817b7b;
  height: 0.1rem;
`;
const GridContainer = styled.div`
  display: grid;
  margin-top: 1rem;
  grid-template-rows: repeat(2, minmax(2rem, max-content));
  grid-template-columns: 9.5rem 1fr;
  column-gap: 1rem;
`;
const RowIndexGridContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(14, 3rem);
  grid-template-columns: repeat(1, 1fr);
  column-gap: 0.8rem;
  margin-top: 1rem;
`;
const GridCellContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 3rem);
  grid-template-columns: 1fr;
  row-gap: 0.8rem;
`;
const GridCellHeaderContainer = styled.div`
  display: grid;
  grid-template-rows: 3rem;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 0.5rem;
`;
const GridCellBodyContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(13, 3rem);
  grid-template-columns: repeat(6, 1fr);
  column-gap: 0.5rem;
`;
const GridItem = styled.div`
  border: 1px solid;
  padding: 0.2rem;
  font-size: 0.85rem;
  background-color: #fff;
  display: grid;
  justify-items: center;
  align-items: center;
  font-weight: 700;
`;
const RowIndexGridItem = styled(GridItem)`
  justify-items: start;
  align-items: center;
`;
const BorderlessGridItem = styled(GridItem)`
  border: 0;
  background-color: transparent;
`;
const TotalTypographyGridItem = styled(GridItem)`
  font-size: 1.5rem;
  font-weight: 600;
`;
const TotalGridRow = styled.div`
  display: grid;
  margin-top: 1rem;
  grid-template-rows: 6rem
  grid-template-columns: 9.5rem 1fr;
  column-gap: 2rem;
`;
const TotalContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: minmax(4.5rem, 6.5rem) 3rem;
`;
const TotalCurrencyContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 3rem);
  grid-template-columns: 1fr;
`;
const TotalCellBodyContainer = styled.div`
  display: grid;
  grid-template-rows: repeat(2, 3rem);
  grid-template-columns: repeat(6, 1fr);
  column-gap: 0.5rem;
`;

const SubHeaderTitleTable = styled.table`
  font-size: 0.8rem;
`;
const SubHeaderTitleTableRow = styled.tr``;
const SubHeaderTitleTableHead = styled.thead``;
const SubHeaderTitleTableHeadCell = styled.th`
  border: 1px solid;
`;
const SubHeaderTitleTableBody = styled.tbody``;
const SubHeaderTitleTableBodyCell = styled.td`
  border: 1px solid;
`;
const TransactionCalculatorSimulatorOutputWizard = ({
  goBack,
  simulatorResult,
}) => {
  const {
    conversionRate,
    productAmountResult,
    shippingCostResult,
    escrowFeeResult,
    transferFeeResult,
    cardFeeResult,
    cancellationFeeResult,
    spPayoutFeeResult,
    nonSplitAmountResult,
    spPercentResult,
    escrowEarnedResult,
    excessLossResult,
    totalNairaResult,
    dollarTotalResult,
    amountPaidResult,
    spPayoutAmountResult,
    transaction,
    term,
  } = simulatorResult ?? {};

  const {
    txScenario,
    type,
    gateway_name,
    escrow_fee_bearer,
    tx_fee_bearer,
    commission,
    shipping_fee_bearer,
  } = transaction ?? {};

  const { non_split_amount, sp_card_fee } = term ?? {};

  return (
    <Wrapper>
      <GridWrapper>
        <HeaderTitle>Transaction Output</HeaderTitle>
        <SubHeaderTitle>Rate: 1$ = &#8358;{conversionRate}</SubHeaderTitle>
        <SubHeaderTitleTable>
          <SubHeaderTitleTableHead>
            <SubHeaderTitleTableRow>
              <SubHeaderTitleTableHeadCell>Service</SubHeaderTitleTableHeadCell>
              <SubHeaderTitleTableHeadCell>
                Scenario
              </SubHeaderTitleTableHeadCell>
              <SubHeaderTitleTableHeadCell>PayGate</SubHeaderTitleTableHeadCell>
              <SubHeaderTitleTableHeadCell>Escrow</SubHeaderTitleTableHeadCell>
              <SubHeaderTitleTableHeadCell>
                Switch fee
              </SubHeaderTitleTableHeadCell>
              <SubHeaderTitleTableHeadCell>
                Shipping
              </SubHeaderTitleTableHeadCell>
              <SubHeaderTitleTableHeadCell>
                SP Tfr. fee
              </SubHeaderTitleTableHeadCell>
              <SubHeaderTitleTableHeadCell>SP</SubHeaderTitleTableHeadCell>
              <SubHeaderTitleTableHeadCell>
                Non-Split
              </SubHeaderTitleTableHeadCell>
            </SubHeaderTitleTableRow>
          </SubHeaderTitleTableHead>
          <SubHeaderTitleTableBody>
            <SubHeaderTitleTableRow>
              <SubHeaderTitleTableBodyCell>
                {TRANSACTION_TYPES_MAPPING?.get(type?.toString()) ?? EM_DASH}
              </SubHeaderTitleTableBodyCell>
              <SubHeaderTitleTableBodyCell>
                {TRANSACTION_SCENARIOS_MAPPING?.get(txScenario) ?? EM_DASH}
              </SubHeaderTitleTableBodyCell>
              <SubHeaderTitleTableBodyCell>
                {PAYMENT_METHOD_MAPPING?.get(gateway_name) ?? EM_DASH}
              </SubHeaderTitleTableBodyCell>
              <SubHeaderTitleTableBodyCell>
                {FEE_BEARER_MAPPING?.get(escrow_fee_bearer) ?? EM_DASH}
              </SubHeaderTitleTableBodyCell>
              <SubHeaderTitleTableBodyCell>
                {FEE_BEARER_MAPPING?.get(tx_fee_bearer) ?? EM_DASH}
              </SubHeaderTitleTableBodyCell>
              <SubHeaderTitleTableBodyCell>
                {FEE_BEARER_MAPPING?.get(shipping_fee_bearer) ?? EM_DASH}
              </SubHeaderTitleTableBodyCell>
              <SubHeaderTitleTableBodyCell>
                {FEE_BEARER_MAPPING?.get(
                  sp_card_fee === 2 ? BOTH_FEE_BEARER_ID : SELLER_FEE_BEARER_ID
                ) ?? EM_DASH}
              </SubHeaderTitleTableBodyCell>
              <SubHeaderTitleTableBodyCell>
                {commission ? "Yes" : "No"}
              </SubHeaderTitleTableBodyCell>
              <SubHeaderTitleTableBodyCell>
                {Number.parseInt(non_split_amount ?? "0") !== 0 ? "Yes" : "No"}
              </SubHeaderTitleTableBodyCell>
            </SubHeaderTitleTableRow>
          </SubHeaderTitleTableBody>
        </SubHeaderTitleTable>
        <Divider />
        <GridContainer>
          <RowIndexGridContainer>
            <BorderlessGridItem />
            <RowIndexGridItem>Product Amount</RowIndexGridItem>
            <RowIndexGridItem>Amount Paid</RowIndexGridItem>
            <RowIndexGridItem>Shipping Cost</RowIndexGridItem>
            <RowIndexGridItem>Escrow Fee</RowIndexGridItem>
            <RowIndexGridItem>Transfer Fee</RowIndexGridItem>
            <RowIndexGridItem>Card Fee</RowIndexGridItem>
            <RowIndexGridItem>Cancellation Fee</RowIndexGridItem>
            <RowIndexGridItem>SP Payout Amount</RowIndexGridItem>
            <RowIndexGridItem>SP Payout Fee</RowIndexGridItem>
            <RowIndexGridItem>Non Split Amount</RowIndexGridItem>
            <RowIndexGridItem>SP %</RowIndexGridItem>
            <RowIndexGridItem>Escrow Earned</RowIndexGridItem>
            <RowIndexGridItem>Excess/ (Loss)</RowIndexGridItem>
          </RowIndexGridContainer>
          <GridCellContainer>
            <GridCellHeaderContainer>
              <GridItem>Buyer Share</GridItem>
              <GridItem>Seller Share</GridItem>
              <GridItem>Buyer Pays</GridItem>
              <GridItem>Refund</GridItem>
              <GridItem>SP Payout</GridItem>
              <GridItem>Seller Payout</GridItem>
            </GridCellHeaderContainer>
            <GridCellBodyContainer>
              {/* ----- Product Amount ---------- */}
              <GridItem>
                <NumberTextBox number={productAmountResult?.buyerShare} />
              </GridItem>
              <GridItem>
                <NumberTextBox
                  number={productAmountResult?.sellerShare}
                  isFee
                />
              </GridItem>
              <GridItem>
                <NumberTextBox number={productAmountResult?.buyerPays} />
              </GridItem>
              <GridItem>
                <NumberTextBox number={productAmountResult?.refund} />
              </GridItem>
              <GridItem>
                <NumberTextBox number={productAmountResult?.spPayout} />
              </GridItem>
              <GridItem>
                <NumberTextBox number={productAmountResult?.sellerPayout} />
              </GridItem>
              {/* ----- Amount Paid ---------- */}
              <GridItem>
                <NumberTextBox number={amountPaidResult?.buyerShare} />
              </GridItem>
              <GridItem>
                <NumberTextBox number={amountPaidResult?.sellerShare} isFee />
              </GridItem>
              <GridItem>
                <NumberTextBox number={amountPaidResult?.buyerPays} />
              </GridItem>
              <GridItem>
                <NumberTextBox number={amountPaidResult?.refund} />
              </GridItem>
              <GridItem>
                <NumberTextBox number={amountPaidResult?.spPayout} />
              </GridItem>
              <GridItem>
                <NumberTextBox number={amountPaidResult?.sellerPayout} />
              </GridItem>
              {/* ----- Shipping Cost ---------- */}
              <GridItem>
                <NumberTextBox number={shippingCostResult?.buyerShare} />
              </GridItem>
              <GridItem>
                <NumberTextBox number={shippingCostResult?.sellerShare} isFee />
              </GridItem>
              <GridItem>
                <NumberTextBox number={shippingCostResult?.buyerPays} />
              </GridItem>
              <GridItem>
                <NumberTextBox number={shippingCostResult?.refund} />
              </GridItem>
              <GridItem>
                <NumberTextBox number={shippingCostResult?.spPayout} />
              </GridItem>
              <GridItem>
                <NumberTextBox number={shippingCostResult?.sellerPayout} />
              </GridItem>
              {/* ----- Escrow Fee ---------- */}
              <GridItem>
                <NumberTextBox number={escrowFeeResult?.buyerShare} />
              </GridItem>
              <GridItem>
                <NumberTextBox number={escrowFeeResult?.sellerShare} isFee />
              </GridItem>
              <GridItem>
                <NumberTextBox number={escrowFeeResult?.buyerPays} />
              </GridItem>
              <GridItem>
                <NumberTextBox number={escrowFeeResult?.refund} isFee />
              </GridItem>
              <GridItem>
                <NumberTextBox number={escrowFeeResult?.spPayout} />
              </GridItem>
              <GridItem>
                <NumberTextBox number={escrowFeeResult?.sellerPayout} isFee />
              </GridItem>
              {/* ----- Transfer Fee ---------- */}
              <GridItem>
                <NumberTextBox number={transferFeeResult?.buyerShare} />
              </GridItem>
              <GridItem>
                <NumberTextBox number={transferFeeResult?.sellerShare} isFee />
              </GridItem>
              <GridItem>
                <NumberTextBox number={transferFeeResult?.buyerPays} />
              </GridItem>
              <GridItem>
                <NumberTextBox number={transferFeeResult?.refund} isFee />
              </GridItem>
              <GridItem>
                <NumberTextBox number={transferFeeResult?.spPayout} isFee />
              </GridItem>
              <GridItem>
                <NumberTextBox number={transferFeeResult?.sellerPayout} isFee />
              </GridItem>
              {/* ----- Card Fee ---------- */}
              <GridItem>
                <NumberTextBox number={cardFeeResult?.buyerShare} />
              </GridItem>
              <GridItem>
                <NumberTextBox number={cardFeeResult?.sellerShare} isFee />
              </GridItem>
              <GridItem>
                <NumberTextBox number={cardFeeResult?.buyerPays} />
              </GridItem>
              <GridItem>
                <NumberTextBox number={cardFeeResult?.refund} isFee />
              </GridItem>
              <GridItem>
                <NumberTextBox number={cardFeeResult?.spPayout} />
              </GridItem>
              <GridItem>
                <NumberTextBox number={cardFeeResult?.sellerPayout} isFee />
              </GridItem>
              {/* ----- Cancellation Fee ---------- */}
              <GridItem>
                <NumberTextBox number={cancellationFeeResult?.buyerShare} />
              </GridItem>
              <GridItem>
                <NumberTextBox
                  number={cancellationFeeResult?.sellerShare}
                  isFee
                />
              </GridItem>
              <GridItem>
                <NumberTextBox number={cancellationFeeResult?.buyerPays} />
              </GridItem>
              <GridItem>
                <NumberTextBox number={cancellationFeeResult?.refund} isFee />
              </GridItem>
              <GridItem>
                <NumberTextBox number={cancellationFeeResult?.spPayout} />
              </GridItem>
              <GridItem>
                <NumberTextBox number={cancellationFeeResult?.sellerPayout} />
              </GridItem>
              {/* ----- SP Payout Amount ---------- */}
              <GridItem>
                <NumberTextBox number={spPayoutAmountResult?.buyerShare} />
              </GridItem>
              <GridItem>
                <NumberTextBox
                  number={spPayoutAmountResult?.sellerShare}
                  isFee
                />
              </GridItem>
              <GridItem>
                <NumberTextBox number={spPayoutAmountResult?.buyerPays} />
              </GridItem>
              <GridItem>
                <NumberTextBox number={spPayoutAmountResult?.refund} isFee />
              </GridItem>
              <GridItem>
                <NumberTextBox number={spPayoutAmountResult?.spPayout} />
              </GridItem>
              <GridItem>
                <NumberTextBox
                  number={spPayoutAmountResult?.sellerPayout}
                  isFee
                />
              </GridItem>
              {/* ----- SP Payout Fee ---------- */}
              <GridItem>
                <NumberTextBox number={spPayoutFeeResult?.buyerShare} />
              </GridItem>
              <GridItem>
                <NumberTextBox number={spPayoutFeeResult?.sellerShare} isFee />
              </GridItem>
              <GridItem>
                <NumberTextBox number={spPayoutFeeResult?.buyerPays} />
              </GridItem>
              <GridItem>
                <NumberTextBox number={spPayoutFeeResult?.refund} isFee />
              </GridItem>
              <GridItem>
                <NumberTextBox number={spPayoutFeeResult?.spPayout} isFee />
              </GridItem>
              <GridItem>
                <NumberTextBox number={spPayoutFeeResult?.sellerPayout} isFee />
              </GridItem>
              {/* ----- Non Split Amount ---------- */}
              <GridItem>
                <NumberTextBox number={nonSplitAmountResult?.buyerShare} />
              </GridItem>
              <GridItem>
                <NumberTextBox
                  number={nonSplitAmountResult?.sellerShare}
                  isFee
                />
              </GridItem>
              <GridItem>
                <NumberTextBox number={nonSplitAmountResult?.buyerShare} />
              </GridItem>
              <GridItem>
                <NumberTextBox number={nonSplitAmountResult?.refund} isFee />
              </GridItem>
              <GridItem>
                <NumberTextBox number={nonSplitAmountResult?.spPayout} />
              </GridItem>
              <GridItem>
                <NumberTextBox number={nonSplitAmountResult?.sellerPayout} />
              </GridItem>
              {/* ----- SP Percent ---------- */}
              <GridItem>
                <NumberTextBox number={spPercentResult?.buyerShare} />
              </GridItem>
              <GridItem>
                <NumberTextBox number={spPercentResult?.sellerShare} isFee />
              </GridItem>
              <GridItem>
                <NumberTextBox number={spPercentResult?.buyerPays} />
              </GridItem>
              <GridItem>
                <NumberTextBox number={spPercentResult?.refund} isFee />
              </GridItem>
              <GridItem>
                <NumberTextBox number={spPercentResult?.spPayout} />
              </GridItem>
              <GridItem>
                <NumberTextBox number={spPercentResult?.sellerPayout} />
              </GridItem>
              {/* ----- Escrow Earned ---------- */}
              <GridItem>
                <NumberTextBox number={escrowEarnedResult?.buyerShare} />
              </GridItem>
              <BorderlessGridItem />
              <BorderlessGridItem />
              <BorderlessGridItem />
              <BorderlessGridItem />
              <BorderlessGridItem />
              {/* ----- Excess/Loss ---------- */}
              <GridItem>
                <NumberTextBox
                  number={excessLossResult?.buyerShare}
                  autoDetectFee
                />
              </GridItem>
              <BorderlessGridItem />
              <BorderlessGridItem />
              <BorderlessGridItem />
              <BorderlessGridItem />
              <BorderlessGridItem />
            </GridCellBodyContainer>
          </GridCellContainer>
        </GridContainer>
        <TotalGridRow>
          <TotalContainer>
            <TotalTypographyGridItem>Total</TotalTypographyGridItem>
            <TotalCurrencyContainer>
              <TotalTypographyGridItem>&#8358;</TotalTypographyGridItem>
              <TotalTypographyGridItem>$</TotalTypographyGridItem>
            </TotalCurrencyContainer>
          </TotalContainer>
          <TotalCellBodyContainer>
            {/* Total In Naira */}
            <BorderlessGridItem />
            <BorderlessGridItem />
            <GridItem>
              <NumberTextBox number={totalNairaResult?.buyerPays} />
            </GridItem>
            <GridItem>
              <NumberTextBox number={totalNairaResult?.refund} />
            </GridItem>
            <GridItem>
              <NumberTextBox number={totalNairaResult?.spPayout} />
            </GridItem>
            <GridItem>
              <NumberTextBox number={totalNairaResult?.sellerPayout} />
            </GridItem>
            {/* Total In Dollar */}
            <BorderlessGridItem />
            <BorderlessGridItem />
            <GridItem>
              <NumberTextBox number={dollarTotalResult?.buyerPays} isDollar />
            </GridItem>
            <GridItem>
              <NumberTextBox number={dollarTotalResult?.refund} isDollar />
            </GridItem>
            <GridItem>
              <NumberTextBox number={dollarTotalResult?.spPayout} isDollar />
            </GridItem>
            <GridItem>
              <NumberTextBox
                number={dollarTotalResult?.sellerPayout}
                isDollar
              />
            </GridItem>
          </TotalCellBodyContainer>
        </TotalGridRow>
      </GridWrapper>
      <ButtonGroup>
        <Button bgColor='#DC3545' onClick={goBack}>
          Restart
        </Button>
      </ButtonGroup>
    </Wrapper>
  );
};

const simulatorItemPropTypes = PropTypes.shape({
  buyerShare: PropTypes.number.isRequired,
  sellerShare: PropTypes.number.isRequired,
  buyerPays: PropTypes.number.isRequired,
  refund: PropTypes.number.isRequired,
  sellerPayout: PropTypes.number.isRequired,
  spPayout: PropTypes.number.isRequired,
}).isRequired;
TransactionCalculatorSimulatorOutputWizard.propTypes = {
  goBack: PropTypes.func.isRequired,
  simulatorResult: PropTypes.shape({
    amountPaidResult: simulatorItemPropTypes,
    cancellationFeeResult: simulatorItemPropTypes,
    cardFeeResult: simulatorItemPropTypes,
    conversionRate: PropTypes.number.isRequired,
    dollarTotalResult: simulatorItemPropTypes,
    escrowEarnedResult: simulatorItemPropTypes,
    escrowFeeResult: simulatorItemPropTypes,
    excessLossResult: simulatorItemPropTypes,
    nonSplitAmountResult: simulatorItemPropTypes,
    productAmountResult: simulatorItemPropTypes,
    shippingCostResult: simulatorItemPropTypes,
    spPayoutAmountResult: simulatorItemPropTypes,
    spPayoutFeeResult: simulatorItemPropTypes,
    spPercentResult: simulatorItemPropTypes,
    totalNairaResult: simulatorItemPropTypes,
    transferFeeResult: simulatorItemPropTypes,
    transaction: PropTypes.shape({
      txScenario: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      gateway_name: PropTypes.string.isRequired,
      escrow_fee_bearer: PropTypes.string.isRequired,
      tx_fee_bearer: PropTypes.string.isRequired,
      shipping_fee_bearer: PropTypes.string.isRequired,
      commission: PropTypes.string.isRequired,
    }),
    terms: PropTypes.shape({
      non_split_amount: PropTypes.number.isRequired,
      sp_card_fee: PropTypes.string.isRequired,
    }),
  }).isRequired,
};
export default TransactionCalculatorSimulatorOutputWizard;
