import { settingConstants} from '../constants';

 const  settings = (state = {}, action)=>{
  switch (action.type) {
    case settingConstants.settings.SET_REFUNDABLE:
      return { loading: true };
    case settingConstants.settings.SET_REFUNDABLE_SUCCESS:
      return {loading:false};
    case settingConstants.settings.SET_REFUNDABLE_FAILURE:
      return {loading:false};
    case settingConstants.settings.SET_PAYMENT:
      return { loading: true };
    case settingConstants.settings.SET_PAYMENT_SUCCESS:
      return { loading: false };
    case settingConstants.settings.SET_PAYMENT_FAILURE:
      return { loading: false };
    default:
      return state
  }
}

export default settings;