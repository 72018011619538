export const order = {

    ORDER_DETAILS_REQUEST: 'ORDER_DETAILS_REQUEST',
    ORDER_DETAILS_SUCCESS: 'ORDER_DETAILS_SUCCESS',
    ORDER_DETAILS_FAILURE: 'ORDER_DETAILS_FAILURE',

    PAY_DETAILS_REQUEST: 'PAY_DETAILS_REQUEST',
    PAY_DETAILS_SUCCESS: 'PAY_DETAILS_SUCCESS',
    PAY_DETAILS_FAILURE: 'PAY_DETAILS_FAILURE',

    ASSIGN_DELIVERY_MAN : 'ASSIGN_DELIVERY_MAN',
    ASSIGN_DELIVERY_MAN_SUCCESS : ' ASSIGN_DELIVERY_MAN_SUCCESS',
    ASSIGN_DELIVERY_MAN_FAILURE : ' ASSIGN_DELIVERY_MAN_FAILURE',

    PROCESS_PAYMENT_REQUEST: 'PROCESS_PAYMENT_REQUEST',
    PROCESS_PAYMENT_SUCCESS: ' PROCESS_PAYMENT_SUCCESS',
    PROCESS_PAYMENT_FAILURE: ' PROCESS_PAYMENT_FAILURE',

    START_POD: 'START_POD',
    START_POD_SUCCESS: ' START_POD_SUCCESS',
    START_POD_FAILURE: ' START_POD_FAILURE'
}