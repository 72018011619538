import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from "react-router-dom";

import Order from '../views/Order';
import {orderActions, transactionActions} from '../actions';
// import { payoutActions } from '../actions';

const mapStateToProps = (state) => {
  const {loading, orders, deliverySuccess, canceling, adding} = state.orders;
  const {canceling2} = state.transaction;
  const {deliveryMen} = state.users;


  const alert = state.alert;


  return {
    alert,
    adding,
    canceling,
    canceling2,
    deliveryMen,
    deliverySuccess
  };


}

export default withRouter(connect(mapStateToProps)(Order));