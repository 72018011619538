import React, {Component} from 'react';
import {css} from '@emotion/core';
import swal from '@sweetalert/with-react';
import Modal from 'react-responsive-modal';

import ReactTable from "react-table";
import 'react-table/react-table.css';
import * as moment from 'moment';
import axios from "axios";
import {BASE_URL} from "../constants";
import {ClipLoader} from "react-spinners";
import Select from "react-select";

const initialForm = {
  promo_code: '',
  promo_description: '',
  customer_id: null,
  user_id: null,
  usage_freq: 0,
  promo_user: 1,
  promo_percent: 0,
  promo_platform: 'BOTH',
  status: 1,
  start_date: moment().format('YYYY-MM-DD'),
  end_date: null,
  promo_type: 2,
}
const defaultSelectedUser = {
  label: 'Select an user',
  value: 0,
};

const override = css`
  display: block;
  margin-top: 10%;
  margin-left: 40%;
  margin-right: 60%;
  border-color: red;
`;

class PromoCodes extends Component {

  state = {
    filter: "",
    data: [],
    notCustom: true,
    period: "All",
    from_date: "",
    to_date: "",
    disputes: [],
    loading: false,
    role: 'seller',
    openPromoForm: false,
    promoForm: initialForm,
    editForm: false,
    buyers: [],
    sellers: [],
    isSaving: false,
    selectedUser: defaultSelectedUser,
  }

  componentDidMount() {

    this.getData();
    this.getBuyers();
    this.getSellers();
  }


  getSellers = () => {
    axios({
      method: 'get',
      mode: 'no-cors',
      url: `${BASE_URL}admin/users`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
      .then(res => {
        this.setState({
          sellers: res.data.data,

        })
      })
      .catch(err => {
        swal({
          icon: 'error',
          title: 'Error',
          text: 'Error fetching sellers',
        })
      })
      .finally(() => {
        this.setState({
          loading: false,
        })
      })
  }

  getBuyers = () => {
    axios({
      method: 'get',
      mode: 'no-cors',
      url: `${BASE_URL}admin/customers`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
      .then(res => {
        this.setState({
          buyers: res.data.data,
        })
      })
      .catch(err => {
        swal({
          icon: 'error',
          title: 'Error',
          text: 'Error fetching customers',
        })
      })
      .finally(() => {
        this.setState({
          loading: false,
        })
      })
  }

  getData = () => {
    this.setState({
      loading: true,
    })
    axios({
      method: 'get',
      mode: 'no-cors',
      url: `${BASE_URL}admin/promos`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
      .then(res => {
        this.setState({
          data: res.data.data,
          promos: res.data.data,
        })
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        this.setState({
          loading: false,
        })
      })
  }

  handleFilter = e => {
    this.setState({
      filter: e.target.value
    }, () => {

      if (this.state.filter === '') {
        this.setState({
          data: this.state.promos
        })
        return;
      }

      const filtered = [];
      let {filter: f} = this.state;
      f = f.toString().toUpperCase();

      this.state.promos.forEach(promo => {
        const customerName = promo.customer ? (promo.customer.firstname + " " + promo.customer.lastname) : '';
        const sellerName = promo.seller ? (promo.seller.firstname + " " + promo.seller.lastname) : '';

        if (
          promo.promo_code.toUpperCase().includes(f)
          || sellerName.toUpperCase().includes(f)
          || (promo.referral_benefit_id && promo.referral_benefit_id.toString().includes(f))
          || customerName.toUpperCase().includes(f)
        ) {
          filtered.push(promo)
        }

        this.setState({
          data: filtered
        })
      })

    })
  }
  promoFormChange = (e, callback) => {
    this.setState({
      promoForm: {
        ...this.state.promoForm,
        [e.target.name]: e.target.value,
      }
    }, state => {
      if (callback) {
        callback(state)
      }
    })
  }

  savePromo = e => {
    e.preventDefault();

    if (this.state.isSaving) {
      return;
    }

    const data = {
      ...this.state.promoForm,
    }

    let method = 'post';
    let url = `${BASE_URL}admin/promos`;

    if (this.state.editForm) {
      method = 'put';
      url = `${BASE_URL}admin/promos/${this.state.promoForm.id}`;
    }

    axios({
      method,
      mode: 'no-cors',
      url,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      },
      data
    })
      .then(() => {
        this.setState({
          openPromoForm: false,
        })

        swal({
          icon: 'success',
          title: 'Success',
          text: 'Promo code saved',
        })
        this.getData();
      })
      .catch(e => {
        swal({
          icon: 'error',
          title: 'Error',
          text: e.response.data.message,
        })
        console.log(e.response);
      })
  }

  deletePromo = id => {
    axios({
      method: 'delete',
      mode: 'no-cors',
      url: `${BASE_URL}admin/promos/${id}`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
      .then(() => {
        this.setState({
          openPromoForm: false,
        })
        swal({
          icon: 'success',
          title: 'Success',
          text: 'Promo code deleted',
        })
        this.getData();
      })
      .catch(e => {
        swal({
          icon: 'error',
          title: 'Error',
          text: 'Error',
        })
        console.log(e.response);
      })
  }

  setPromoField = (field, value) => {
    this.setState({
      promoForm: {
        ...this.state.promoForm,
        [field]: value,
      }
    })
  }

  render() {
    const {data, filterable, loading, promoForm, editForm, buyers, sellers} = this.state;
    var columns = [];

    columns = [
      {
        Header: props => <span><b>SN</b></span>,
        id: "sn",
        maxWidth: 80,
        Cell: (row) => {
          return <div className="text-center">{row.index + 1}</div>;
        }
      },
      {
        Header: props => <span><b>Promo ID</b></span>,
        id: "id",
        maxWidth: 80,
        accessor: d => d.id,
        Cell: (row) => {
          return <div className="text-center">{row.index + 1}</div>;
        }
      },
      {
        Header: props => <span><b>Actions</b></span>,
        id: "actions",
        accessor: d => d,
        width: 150,
        sortable: false,
        Cell: props => {
          return (
            <div className='text-center'>
              {
                (props.original.referral_benefit_id === 0 || !props.original.referral_benefit_id) &&
                <>
                  <button className="btn btn-primary mr-2" onClick={() => {
                    this.setState({
                      promoForm: props.value,
                      openPromoForm: true,
                      selectedUser: defaultSelectedUser,
                      editForm: true,
                    })
                  }}>
                    Edit
                  </button>
                  <button className="btn btn-danger" onClick={(e) => {
                    e.preventDefault();
                    swal({
                      title: 'Delete promo?',
                      icon: 'warning',
                      dangerMode: true,
                      content: <div>
                        <div>
                          Are you sure you want to delete this promo code?
                        </div>
                      </div>,
                      buttons: true
                    })
                      .then(willDelete => {
                        if (willDelete) {
                          this.deletePromo(props.value.id)
                        }
                      })

                  }}>
                    Delete
                  </button>
                </>
              }
            </div>
          )
        }
      },
      {
        Header: props => <span><b>RCB ID</b></span>,
        id: "rcb_id",
        maxWidth: 80,
        accessor: d => d.referral_benefit_id,
        Cell: ({original: {referral_benefit_id}}) => {
          return <div className="text-center">{
            (!referral_benefit_id || referral_benefit_id === 0) ?
              "N/A" :
              referral_benefit_id
          }</div>;
        }
      },
      {
        Header: props => <span><b>Promo Code</b></span>,
        id: "promo_code",
        accessor: d => d.promo_code,
        maxWidth: 140,
        Cell: props => <span className='number'>{props.value}</span>
      },
      {
        Header: props => <span><b>Promo Type</b></span>,
        id: "promo_type",
        accessor: d => d.promo_type,
        Cell: props => <span>
          {props.value == 1 && "Specific"}
          {props.value == 2 && "General"}
        </span>
      },
      {
        Header: props => <span><b>Buyer</b></span>,
        id: "customer",
        accessor: d => d.customer,
        maxWidth: 140,
        Cell: props => <span className='number'>
          {props.value ? (props.value.firstname + " " + props.value.lastname) : "N/A"}
        </span>
      },
      {
        Header: props => <span><b>Status</b></span>,
        id: "status",
        accessor: d => d.status,
        maxWidth: 140,
        Cell: props => {
          let status = "N/A";

          if (props.value === 1) {
            status = 'Inactive';
          }

          if (props.value === 2) {
            status = 'Active';
          }

          if (props.value === 3) {
            status = 'Used';
          }

          return (
            <span className='number'>
              {
                status || "N/A"
              }
            </span>
          )
        }
      },
      {
        Header: props => <span><b>Seller</b></span>,
        id: "seller",
        accessor: d => d.seller,
        maxWidth: 140,
        Cell: props => <span className='number'>
          {props.value ? (props.value.firstname + " " + props.value.lastname) : "N/A"}
        </span>
      },
      {
        Header: props => <span><b>Target User Type</b></span>,
        id: "promo_user",
        accessor: d => d.promo_user,
        maxWidth: 140,
        Cell: props => {
          let userType = 'N/A';

          if (props.value === 1) {
            userType = 'All Buyers & Sellers'
          }
          if (props.value === 2) {
            userType = 'All Sellers'
          }
          if (props.value === 3) {
            userType = 'All Buyers'
          }
          if (props.value === 4) {
            userType = 'Individual'
          }

          return (
            <span className='number'>
              {
                userType
              }
            </span>
          )
        }
      },
      {
        Header: props => <span><b>Promo Percent</b></span>,
        id: "promo_percent",
        accessor: d => d.promo_percent,
        maxWidth: 140,
        Cell: props => {
          return (
            <span className='number'>
              {
                props.value ? props.value * 100 : "N/A"
              }
            </span>
          )
        }
      },
      {
        Header: props => <span><b>Usage Frequency</b></span>,
        id: "usage_frequency",
        accessor: d => d.usage_freq,
        maxWidth: 140,
        Cell: props => {

          return (
            <div className="text-center">
              {
                props.value || "N/A"
              }
            </div>
          )
        }
      },
      {
        Header: props => <span><b>Start Date</b></span>,
        id: "start_date",
        accessor: d => d.start_date,
        maxWidth: 140,
        Cell: props => {
          return (
            <span className='number'>
              {
                props.value || "N/A"
              }
            </span>
          )
        }
      },
      {
        Header: props => <span><b>Stop Date</b></span>,
        id: "stop_date",
        accessor: d => d.stop_date,
        maxWidth: 140,
        Cell: props => {
          return (
            <span className='number'>
              {
                props.value || "N/A"
              }
            </span>
          )
        }
      },
      {
        Header: props => <span><b>Description</b></span>,
        id: "promo_description",
        accessor: d => d.promo_description,
        maxWidth: 140,
        Cell: props => {

          return (
            <span className='number'>
              {
                props.value || "N/A"
              }
            </span>
          )
        }
      },
      {
        Header: props => <span><b>Promo Platform</b></span>,
        id: "promo_platform",
        accessor: d => d.promo_platform,
        maxWidth: 140,
        Cell: props => {
          return (
            <span className='number'>
              {
                props.value || "N/A"
              }
            </span>
          )
        }
      },
    ]

    return (
      <div className="content-wrapper" style={{minHeight: 311}}>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-12">
                <h1 className="m-0 text-dark">Promo Codes</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid"><br/>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                {/* {alert && alert.message && alert.type!="alert-danger"&&<div className={`alert buyer-alert ${alert.type}`} ><a
                                aria-label="close" className="close alert-close">×</a> <span   className="alert-message-content">{alert.message}</span></div>} */}
                {!loading ?
                  <div className="card">

                    <div className="card-body">

                      <div className="row">
                        <div className="col-12 col-sm-4">
                          <div className="form-group">
                            <label for="filter">Filter</label>
                            <input type="text"
                                   placeholder="Filter"
                                   className="form-control"
                                   value={this.state.filter}
                                   onChange={this.handleFilter}/>
                          </div>
                        </div>
                        <div className="col-12 col-sm-4">
                          <div className="form-group">
                            <label>&nbsp;</label>
                            <div className="row">
                              <div className="col-12">
                                <button className="btn btn-info"
                                        onClick={() => this.setState({
                                          openPromoForm: true,
                                          editForm: false,
                                          selectedUser: defaultSelectedUser,
                                          promoForm: initialForm
                                        })}>
                                  Add Promo Code
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br/>

                      <div className="table-responsive">
                        <ReactTable
                          defaultPageSize={5}
                          data={data}
                          columns={columns}/>

                      </div>
                    </div>
                  </div>
                  :
                  <div className='sweet-loading'>
                    <ClipLoader
                      className={override}
                      sizeUnit={"px"}
                      size={70}

                      color={'blue'}
                      loading={loading}
                    />
                  </div>
                }
              </div>
            </div>
            <br/>
          </div>
        </section>
        <div>
          <Modal open={this.state.openPromoForm} onClose={() => this.setState({openPromoForm: false})} center styles={{
            modal: {
              maxWidth: '800px',
              width: '100%',
            },
            overlay: {
              zIndex: 1500,
            }
          }}>
            <form onSubmit={this.savePromo}>
              <div className="modal-header">
                <h4 className="modal-title">{editForm ? "Edit Promo" : "Create Promo"}</h4>
              </div>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label htmlFor="promo_code">Promo Code</label>
                    <input
                      type="text" name="promo_code"
                      value={promoForm.promo_code}
                      className="form-control"
                      onChange={this.promoFormChange}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label htmlFor="promo_user">User Type</label>
                    <select
                      name="promo_user"
                      value={promoForm.promo_user}
                      onChange={e => {
                        const value = e.target.value;
                        this.promoFormChange(e, () => {
                          if (value == 4) {
                            this.setState({
                              promoForm: {
                                ...this.state.promoForm,
                                promo_type: 1
                              }
                            })
                          } else {
                            this.setState({
                              promoForm: {
                                ...this.state.promoForm,
                                promo_type: 2
                              }
                            })
                          }
                        });
                      }}
                      className="form-control"
                    >
                      {
                        this.state.promoForm.promo_type == 2 &&
                        <>
                          <option value={1}>All Buyers and Sellers</option>
                          <option value={2}>All Sellers</option>
                          <option value={3}>All Buyers</option>
                        </>
                      }
                      {
                        this.state.promoForm.promo_type == 1 &&
                        <>
                          <option value={4}>Individuals</option>
                        </>
                      }
                    </select>
                  </div>
                </div>
                {
                  promoForm.promo_user == 4 && (
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <label htmlFor="promo_code">User Type</label>
                        <select
                          className="form-control"
                          name="user_type"
                          id="user_type"
                          value={promoForm.user_type || (promoForm.user_id !== null ? "seller" : "buyer")}
                          onChange={e => {
                            this.setState({
                              selectedUser: defaultSelectedUser,
                              promoForm: {
                                ...this.state.promoForm,
                                user_type: e.target.value,
                                customer_id: null,
                                user_id: null,
                              }
                            })
                          }}
                        >
                          <option value='seller'>Seller</option>
                          <option value='buyer'>Buyer</option>
                        </select>
                      </div>
                      <div className="col-md-6 col-sm-12">
                        {
                          (promoForm.user_type == 'seller' || (!promoForm.user_type && promoForm.user_id !== null)) ?
                            (<>
                                <label htmlFor="promo_code">Seller Name</label>
                                <Select
                                  type="text" name="user_id"
                                  value={this.state.selectedUser}
                                  onChange={selected => {
                                    this.setState({
                                      selectedUser: selected,
                                      promoForm: {
                                        ...this.state.promoForm,
                                        user_id: selected.value,
                                      }
                                    })
                                  }}
                                  options={sellers.map(seller => ({
                                    label: `${seller.firstname + ' ' + seller.lastname} (${seller.phone_number})`,
                                    value: seller.id,
                                  }))}
                                />
                              </>
                            )
                            :
                            (<>
                                <label htmlFor="promo_code">Buyer Name</label>
                                <Select
                                  type="text" name="user_id"
                                  value={this.state.selectedUser}
                                  onChange={selected => {
                                    this.setState({
                                      selectedUser: selected,
                                      promoForm: {
                                        ...this.state.promoForm,
                                        customer_id: selected.value,
                                      }
                                    })
                                  }}
                                  options={buyers.map(buyer => ({
                                    label: `${buyer.firstname + ' ' + buyer.lastname} (${buyer.phone_number})`,
                                    value: buyer.id,
                                  }))}
                                />
                              </>
                            )
                        }
                      </div>
                    </div>
                  )
                }

                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label htmlFor="usage_freq">Usage Frequency</label>
                    <input
                      type="text" name="usage_freq"
                      value={promoForm.usage_freq}
                      className="form-control"
                      onChange={this.promoFormChange}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label htmlFor="promo_percent">Promo Percent</label>
                    <input
                      name="promo_percent"
                      type="number"
                      max="100"
                      min="0"
                      value={parseInt(promoForm.promo_percent * 100)}
                      onChange={e => {
                        if (parseInt(e.target.value) <= 100 && parseInt(e.target.value) >= 0) {
                          e.target.value = parseInt(e.target.value) / 100;
                          this.promoFormChange(e);
                        }
                      }}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label htmlFor="usage_freq">Promo Type</label>
                    <select
                      name="promo_type"
                      value={promoForm.promo_type || "2"}
                      className="form-control"
                      onChange={(e) => {
                        const value = e.target.value;
                        this.promoFormChange(e, () => {
                          if (value == 2) {
                            this.setState({
                              user_id: null,
                              customer_id: null,
                            })

                            if (this.state.promoForm.promo_user == 4) {
                              this.setState({
                                promoForm: {
                                  ...this.state.promoForm,
                                  promo_user: "1",
                                }
                              })
                            }
                          } else {
                            this.setState({
                              promoForm: {
                                ...this.state.promoForm,
                                promo_user: "4"
                              }
                            })
                          }
                        });

                      }}
                    >
                      <option value="2">General</option>
                      <option value="1">Specific</option>
                    </select>
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label htmlFor="usage_freq">Status</label>
                    <select
                      name="status"
                      value={promoForm.status}
                      className="form-control"
                      onChange={this.promoFormChange}
                    >
                      <option value="1">Inactive</option>
                      <option value="2">Active</option>
                      <option value="3">Used</option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-12">
                    <label htmlFor="promo_description">Description</label>
                    <textarea name="promo_description" id="promo_description" className="form-control"
                              value={promoForm.promo_description} onChange={this.promoFormChange}/>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 col-sm-12">
                    <label htmlFor="start_date">Start Date</label>
                    <input
                      name="start_date"
                      value={moment(promoForm.start_date).format('YYYY-MM-DD').toString()}
                      className="form-control"
                      onChange={e => {
                        this.setState({
                          promoForm: {
                            ...promoForm,
                            start_date: e.target.value,
                          }
                        })
                      }}
                      type="date"
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label htmlFor="end_date">End Date</label>
                    <input
                      name="stop_date"
                      value={moment(promoForm.stop_date).format('YYYY-MM-DD').toString()}
                      className="form-control"
                      onChange={e => {
                        this.setState({
                          promoForm: {
                            ...promoForm,
                            stop_date: e.target.value,
                          }
                        })
                      }}
                      type="date"
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-sm-12 text-right">
                    <button type="submit" className="btn btn-primary">Save</button>
                  </div>
                </div>
              </div>
            </form>
          </Modal>
        </div>
      </div>

    )
  }
}

export default PromoCodes;