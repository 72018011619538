import { userConstants} from '../constants';

 const  register = (state = {}, action)=>{
  switch (action.type) {
    case userConstants.user.REGISTER_REQUEST:
      return { registering: true };
    case userConstants.user.REGISTER_SUCCESS:
      return {};
    case userConstants.user.REGISTER_FAILURE:
      return {};

      case userConstants.user.GET_OTP_REQUEST:
      return { sending: true };
    case userConstants.user.GET_OTP_SUCCESS:
      return {success:true};
    case userConstants.user.GET_OTP_FAILURE:
      return {success:false,error:action.error};
      case userConstants.user.SUBMIT_OTP_REQUEST:
      return { submitting: true };
    case userConstants.user.SUBMIT_OTP_SUCCESS:
      return {success:true};
    case userConstants.user.SUBMIT_OTP_FAILURE:
      return {success:false,error:action.error};
      case userConstants.user.SUBMIT_BUSINESS_REQUEST:
      return { submitting: true };
    case userConstants.user.SUBMIT_BUSINESS_SUCCESS:
      return {success:true};
    case userConstants.user.SUBMIT_BUSINESS_FAILURE:
      return {success:false,error:action.error};
    case userConstants.user.CONFIRM_BUYER_REQUEST:
      return { confirming: true };
    case userConstants.user.CONFIRM_BUYER_SUCCESS:
      return {success:true};
    case userConstants.user.CONFIRM_BUYER_FAILURE:
      return {success:false,error:action.error};
    default:
      return state
  }
}

export default register;