import { BASE_URL } from '../constants';
import axios from 'axios';
import { userService } from '.';
import { userActions } from '../actions';



const createDispatcher=(data)=>{
    return  axios({
        method: 'post',
        mode: 'no-cors',
        url : `${BASE_URL}deliveryman/create  `,data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization':'Bearer '+localStorage.getItem("user"),
            'Content-Type': 'application/json',
        }
    })
}
const viewDispatcherType=()=>{
    return  axios({
        method: 'get',
        mode: 'no-cors',
        url : `${BASE_URL}deliverymantype/view`,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization':'Bearer '+localStorage.getItem("user"),
            'Content-Type': 'application/json',
        }
    })
}

const editDispatcher=(data)=>{
    return  axios({
        method: 'put',
        mode: 'no-cors',
        url : `${BASE_URL}deliveryman/update/${data.id} `,
        data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization':'Bearer '+localStorage.getItem("user"),
            'Content-Type': 'application/json',
        }
    })
}


const deleteDispatcher=(id)=>{
    return  axios({
        method: 'delete',
        mode: 'no-cors',
        url : `${BASE_URL}deliveryman/delete/${id}`,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization':'Bearer '+localStorage.getItem("user"),
            'Content-Type': 'application/json',
        }
    })
}

const createCourier=(data)=>{
    return  axios({
        method: 'post',
        mode: 'no-cors',
        url : `${BASE_URL}deliveryman/add_courier  `,data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization':'Bearer '+localStorage.getItem("user"),
            'Content-Type': 'application/json',
        }
    })
}
const viewCouriers=()=>{
    return  axios({
        method: 'get',
        mode: 'no-cors',
        url : `${BASE_URL}deliveryman/viewcourier `,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization':'Bearer '+localStorage.getItem("user"),
            'Content-Type': 'application/json',
        }
    })
}

const editCourier=(data)=>{
    return  axios({
        method: 'put',
        mode: 'no-cors',
        url : `${BASE_URL}deliveryman/courier/update/${data.id} `,
        data,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization':'Bearer '+localStorage.getItem("user"),
            'Content-Type': 'application/json',
        }
    })
}


const deleteCourier=(id)=>{
    return  axios({
        method: 'delete',
        mode: 'no-cors',
        url : `${BASE_URL}deliveryman/courier/delete/${id}  `,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization':'Bearer '+localStorage.getItem("user"),
            'Content-Type': 'application/json',
        }
    })
}


export const delivery = {
   
    createCourier,
    editCourier,
    viewCouriers,
    deleteCourier,
    createDispatcher,
    editDispatcher,
    deleteDispatcher,
    viewDispatcherType
};


