import React, {Component} from 'react';
import {userActions, alertActions} from '../actions';
import {Link, withRouter} from 'react-router-dom';
import PhoneInput, {formatPhoneNumber} from 'react-phone-number-input';
import swal from '@sweetalert/with-react'
import Modal from 'react-responsive-modal';

import {OrderEmail, OrderDeliveryEmail} from './ModalViews';

const initialState = {
  email: '',
  password: '',
  submitted: false
};
var count = 0;

class Login extends Component {

  // componentDidMount = () => {
  //   if(localStorage.getItem("user")!=null){
  //   this.props.history.push("/app");
  //   }
  // }

  constructor(props) {
    super(props);

    // reset login status
    // dispatch(userActions.user.logout());

    this.state = {
      show: true,
      email: '',
      phone: '',
      password: '',
      submitted: false,
      type: this.props.match.params.type,
      orderEmail: false,
      orderDeliveryEmail: false,
      default: false,
      staticEmail: ''
    };

  }


  componentDidMount = () => {
    if (this.props.match.params.mailType !== "delivery") {
      if (this.props.match.params.code) {
        this.setState({orderEmail: true})
      }
    } else {
      this.setState({orderDeliveryEmail: true})
    }

  }
  componentWillReceiveProps = (props) => {
    if (props.alert && props.alert.message && props.alert.type === "alert-danger") {

      if (props.alert.message === "unverified") {
        count++;
        if (count < 2) {
          //This account is yet to be verified. To verify your account, please check your email for your verification link
          swal({
            title: "Error",
            text: "Your phone number and email address is yet to be verified. To verify, please check your email for your verification link",
            type: "error",
            buttons: ["Resend email", "Close"]
          }).then((val) => {

            if (!val) {
              if (props && props.user) {
                let data = {email: props.user && props.user.email};
                this.props.dispatch(userActions.user.resendEmail(data, this.props.match.params.type, this.props.history))
                this.props.dispatch(alertActions.alert.clear());
              }
              this.props.dispatch(alertActions.alert.clear());
              return;
            }
            this.props.dispatch(alertActions.alert.clear());
          });
          return;
        }
        return;
      }
      swal("Error", props.alert.message, "error").then(() => {
        this.props.dispatch(alertActions.alert.clear());
      });
    }
    if (props.alert && props.alert.message && props.alert.type === "alert-success") {
      if (props.alert.message === 'Resent email') {
        swal({
          title: "Success",
          content: <div>A verification email has been sent to <b>{this.props.msg}</b>. Please access the email to verify
            your phone number</div>,
          icon: "success"
        }).then(() => {
          this.props.dispatch(alertActions.alert.clear());
        });
        return;
      }
      swal("Success", props.alert.message, "success").then(() => {
        this.props.dispatch(alertActions.alert.clear());
      });
    }
  }
  handleChange = (e) => {

    const {name, value} = e.target;
    this.setState({[name]: value});
  }

  handlePhone = (value, name) => {
    // value = value.replace(/[^a-zA-Z ]/g, "");
    this.setState({
      ...this.state,
      [name]: value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({submitted: true});
    const {email, password} = this.state;
    const credentials = {email, password}
        const {dispatch} = this.props;
    if (email && password) {
      this.setState({staticEmail: email});
      dispatch(userActions.user.login(credentials, this.props.history));
    }
  }

  toggleModal = (name) => {
    this.setState(
       {[name]: !(this.state[name])}
    )
  }

  render() {
    const {loggingIn, loading} = this.props;
    const {email, phone, password} = this.state;
    return (
       <div className="background-gradient" style={{height: '100vh'}}>
         <nav className="navbar navbar-expand-lg fixed-top navbar-inverse">
           <a className="navbar-brand" href="https://www.atarapay.com">
             <img src="/dist/img/logo-header.png" alt="" className="logo-img img img-responsive"/>
           </a>
           <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText"
                   aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
             <span className="navbar-toggler-icon"></span>
           </button>
         </nav>
         <OrderEmail open={this.state.orderEmail}
                     onClose={() => {
                       this.toggleModal("orderEmail")
                     }}
                     name={this.props.match.params.name}
                     amount={this.props.match.params.amount}
                     code={this.props.match.params.code}/>

         <OrderDeliveryEmail open={this.state.orderDeliveryEmail}
                             onClose={() => {
                               this.toggleModal("orderDeliveryEmail")
                             }}
                             name={this.props.match.params.name}
                             phone={this.props.match.params.amount}
                             code={this.props.match.params.code}/>

         <div className="row row-margin top-margin-60">
           <div className="col-sm-3 col-md-3"/>
           <div className="col-sm-8 col-md-6 col-12">
             <div className="card card-margin">
               <div className="tab-content">
                 <div role="tabpanel" id="seller" className={`tab-pane left-margin-35 active`}><br/>
                   <form onSubmit={this.handleSubmit}>
                     <h4><strong>Admin Login</strong></h4>
                     <p><span className="text-danger">*</span> Indicates mandatory fields</p>
                     <div className="custom-label form-group ">
                       <label className="small" htmlFor="buyer_email">
                         <small>Please Enter Your Email Address<span className="text-danger">*</span></small>
                       </label>
                       <input
                          type="text" name="email" value={email} onChange={this.handleChange} id="buyer_email"
                          required="required" placeholder="Email Address"
                          className="custom-input"/>
                     </div>
                     <div className="custom-label form-group"><label className="small" htmlFor="buyer_password"><small
                        className="small">Please Enter Your Password<span
                        className="text-danger">*</span></small></label> <input
                        type="password" name="password" value={password} onChange={this.handleChange}
                        id="buyer_password" required="required" placeholder="********" className="custom-input"/>
                     </div>
                     <div className="custom-label form-group">
                       <button name="button" className="btn btn-dark-blue btn-login" disabled={loggingIn}
                               value="Login">{loggingIn ? "Sending..." : "Login"}</button>
                     </div>
                   </form>
                 </div>
               </div>
             </div>
           </div>
           <div className="col-sm-3 col-md-3"/>
         </div>
         {/* <Modal open={this.state.default} onClose={() => this.toggleModal("default")} center>

                    <div className="modal-header">
                        <h4 className="modal-title">Error</h4>
                    </div>
                    <div className="modal-body">
                        <div className="row form-group">
                            <div className="col-sm-12">
                                <p >This account is yet to be verified. To verify your account,<br/> please check your email for your verification link</p>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer"><button type="button" onClick={() => this.toggleModal("default")} data-dismiss="modal"
                        className="btn btn-primary">Close</button>
                        &nbsp;&nbsp;
            <button type="button" disabled={loading} onClick={() => this.props.dispatch(userActions.user.resendEmail( staticEmail, this.props.match.params.type, this.props.history))} className="btn btn-danger">{loading ? "Sending..." : "Resend Email"}</button></div>

                </Modal> */}
       </div>


    )
  }
}

export default withRouter(Login);