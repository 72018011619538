import React , {Component} from 'react';
import {Link} from 'react-router-dom';



class Help extends Component{

    static contextTypes = {
        router: () => true, // replace with PropTypes.object if you use them
    }

    render(){
        return(
            <div class="content-wrapper" style={{minHeight: 128}}>
            <div class="container"><br/><br/>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="alert alert-info">
                                    Escrow: Transaction Status Help
                                </div>
                            </div>
                        </div> <br/>
                        <div class="row">
                            <div class="col-sm-12">
                                    <h4><strong><strong>Step 1: Buyer and Seller Agree to Terms</strong></strong></h4>
                                    <p>Both parties agree to terms of the transaction, which includes a description of the merchandise, sale price, number of delivery days and any shipping information.</p>
                                    
                                <hr/>
                                    <h4><strong><strong>Step 2: Buyer Makes Payment to AtaraPay</strong></strong></h4>
                                    <p>Once agreement is reached by both parties, Buyer then makes payment offline via cash to our bank account or online via card, mobile payment or bank transfer. AtaraPay verifies the payment.</p>
                                <hr/>
                                    <h4><strong><strong>Step 3: Seller Ships Merchandise to Buyer</strong></strong></h4>
                                    <p>Upon payment verification, the Seller is informed and authorized to deliver the merchandise or service to Buyer. Seller is expected to deliver the merchandize or service within the Maximum Delivery Days agreed by both parties.</p>
                                <hr/>
                                    <h4><strong><strong>Step 4: Buyer Inspects Merchandise or Service</strong></strong></h4>
                                    <p>At point of delivery of merchandize or service, Buyer is required to conduct final inspection and accept or reject using our application supported by mobile, SMS and web.</p>
                                <hr/>
                                    <h4><strong><strong>Step 5: AtaraPay Pays Seller (or Refunds Buyer)</strong></strong></h4>
                                    <p>AtaraPay pays the Seller, if the delivery is accepted or refunds Buyer if rejected. The transaction is complete.</p>
                                    <button class="btn btn-info btn-md" onClick={this.context.router.history.goBack} >Back</button>
                            </div>
                        </div> <br/><br/><br/>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

export default Help;