import { termConstants } from '../constants';

 const  term = (state = {}, action)=>{
  switch (action.type) {
    case termConstants.term.CREATE_TERMS:
      return { loading: true };
    case termConstants.term.CREATE_TERM_SUCCESS:
      return {...state,term:{...action.term},success:true};
    case termConstants.term.CREATE_TERM_FAILURE:
      return {...state ,success:false};

    case termConstants.term.EDIT_TERMS:
      return { loading: true };
    case termConstants.term.EDIT_TERM_SUCCESS:
      return {...state,term:{...state.term,...action.term} ,success:true};
    case termConstants.term.EDIT_TERM_FAILURE:
      return {...state,success:false};
    
    case termConstants.term.REQUEST_TERM:
      return { loading: true };
    case termConstants.term.REQUEST_TERM_SUCCESS:
      return {term:{ ...action.term} };
    case termConstants.term.REQUEST_TERM_FAILURE:
      return { error: action.error};
    default:
      return state
  }
}

export default term;