import React from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import SpinnerIcon from "../SpinnerIcon";
const StyledButton = styled.button`
  width: 15vw;
  height: 3rem;
  color: #fff;
  background-color: ${(props) => props?.bgColor ?? "#fff"};
  font-weight: 600;
  border-radius: 10px;
  border: #007bff;
  &:disabled {
    cursor: not-allowed;
    background-color: #f2f0f0;
    color: #817b7b;
  }
  ${({ loading }) => {
    return (
      loading &&
      css`
        cursor: not-allowed;
        background-color: #f2f0f0;
        color: #817b7b;
      `
    );
  }}
  &:disabled {
    cursor: not-allowed;
    background-color: #f2f0f0;
    color: #817b7b;
  }
`;
const Button = ({ children, loading, ...props }) => {
  if (loading) {
    return (
      <StyledButton {...props} loading>
        <SpinnerIcon />
      </StyledButton>
    );
  }
  return <StyledButton {...props}>{children}</StyledButton>;
};
Button.propTypes = {
  children: PropTypes.string.isRequired,
  bgColor: PropTypes.string,
};
export default Button;
