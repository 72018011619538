const toInternationalFormat = (phone = '', prefix = '+234') => {
    return prefix + phone.toString().substring(1);
}
const formatTransactionData = data => {

    /**
     * Convert Every price field to Naira Form
     * Clean Multiples for Additional Services
     */

    let additional_service_amount = 0;
    data.additional_services.map(service => {
        additional_service_amount += parseInt(service.cost * 100);
    })

    additional_service_amount=(additional_service_amount*data.conversion_ratio);


    /** Amount Payed and Shipping Cost is "" (String) Fix */
    data.amount_payed = data.amount_payed === "" ? 0 : (data.amount_payed*data.conversion_ratio);
    data.shipping_cost = data.shipping_method === 0 ? 0 : data.shipping_cost === "" ? 0 : (data.shipping_cost*data.conversion_ratio)

    data.additional_services = Array.from(data.additional_services, service => ({...service, cost: service.cost * 100}))
    return {
        ...data,
        amount_payed: (data.quantity * data.amount_payed) * 100 ,
        extra: JSON.stringify(data.extra),
        additional_service_amount,
        shipping_cost: data.shipping_cost * 100,
        additional_services : data.additional_services.filter(service => service.selected),
    }
}

const rformatTransactionData = data => {
    const rselected= Array.from(data.additional_services, service => ({...service, cost: service.cost / 100, selected: true}))
    return {
        ...data,
        amount_payed: parseFloat(data.amount_net / data.quantity / 100),
        additional_service_amount: parseFloat(data.additional_service_amount/100),
        shipping_cost: data.shipping_cost / 100,
        additional_services: rselected,
        buyer_phone: toInternationalFormat(data.buyer_phone),
        seller_phone: toInternationalFormat(data.seller_phone),
    }
    
}

const formatTransctionType = intValue => {
    switch (intValue) {
        case 2:
            return 'general'
        case 3:
            return 'milestone' 
        default:
            return null
    }
}

const calculateTransactionEscrow = (taxable) => {
    
    if ((1.5 / 100) * parseFloat(taxable) >= 50000)  {
        return 50000;
    } else if ((1.5 / 100) * parseFloat(taxable) <= 500) {
        return 500;
    } else {
        return (1.5 / 100) * parseFloat(taxable);
    }
}

const calculateUsdTransactionEscrow = (taxable) => {
    return calculateTransactionEscrow(taxable);
}
const calculatePayableShipping = (data) => {
    let x;
    switch (data.shipping_fee_bearer) {
        case 'customer':
            x = 1
            break;
        case 'seller':
            x = 0;
            break;
        default:
            x = 0.5
    }

    return data.shipping_cost * x;
}

/** Calculate Shipping for Display */
const calculateShipping = (data,d=1,di=0) => {
    
    let x, y;
    d = data.shipping_fee_bearer === 'customer' ? 1 : d;
    switch (data.shipping_fee_bearer) {
        case 'customer':
            x = 1
            y = 1
            break;
        case 'seller':
            x = di;
            y = 1
            break;
        default:
            x = 0.5
            y = 0.5
    }
    return {
        customer: parseFloat(data.shipping_cost * x),
        seller: parseFloat(data.shipping_cost * y * d)
    }
}

const calculatePayableEscrow = (data, display = 0) => {
    let x,y
    switch (data.escrow_fee_bearer) {
        case 'customer':
             y = 1
            break;
        case 'seller':
             y = display;
            break;
        default:
             y = 0.5
    }
    switch (data.shipping_fee_bearer) {
        case 'customer':
            x = 1
            break;
        case 'seller':
            x = 0;
            break;
        default:
            x = 0.5
    }

    const payable_minus_escrow = eval(
        (parseFloat(data.amount_payed * data.quantity) + parseFloat(data.additional_service_amount) + parseFloat(data.shipping_cost * x)))

    return eval(calculateTransactionEscrow(payable_minus_escrow) * y);
}

const calculateCardFee = (data, role, fmt = 0, action) => {
    let Escrowfee=calculatePayableEscrow(data);

    let cost = parseFloat((fmt === 1 ? data.amount_net / 100 : data.amount_payed) * data.quantity) + parseFloat(calculateShipping(data)[role])+Escrowfee;

    let fee = calculateFinalCardFeePLusTx(cost,'Paystack','NGN');
    if(action === "refund"){
        return fee-50;
    }    
    return fee;
}

const calculateUsdCardFee = (data, role, fmt = 0, action='') => {  
    let Escrowfee=calculatePayableEscrow(data);  
    let cost = parseFloat((fmt === 1 ? data.amount_net / 100 : data.amount_payed) * data.quantity) + parseFloat(calculateShipping(data)[role])+Escrowfee;
    let fee = calculateFinalCardFeePLusTx(cost,'Paystack','USD');
    return (fee/data.conversion_ratio);
}




const calculateUSSDCardFee = (data, role, fmt = 0, action='') => {
    let Escrowfee=calculatePayableEscrow(data);    
    let cost = parseFloat((fmt === 1 ? data.amount_net / 100 : data.amount_payed) * data.quantity) + parseFloat(calculateShipping(data)[role])+Escrowfee;
    let fee = calculateFinalCardFeePLusTx(cost,'USSD','NGN');
    return fee ;
}


const calculateTransactionPayable = (data,fmt = 0, modal=0) => {
    let x,y
    switch (data.escrow_fee_bearer) {
        case 'customer':
             y = 1
            break;
        case 'seller':
             y = 0;
            break;
        default:
             y = 0.5
    }
    switch (data.shipping_fee_bearer) {
        case 'customer':
            x = 1
            break;
        case 'seller':
            x = 0;
            break;
        default:
            x = 0.5
    }

    const payable_minus_escrow = eval(
        (parseFloat((fmt === 1 ? data.amount_net / 100 : data.amount_payed) * (modal === 1 ? 1 : data.quantity)) + parseFloat((fmt === 1 ? data.additional_service_amount / 100 : data.additional_service_amount)) + parseFloat((fmt === 1 ? data.shipping_cost / 100 : data.shipping_cost) * x)))
    const payable_plus_escrow = eval(payable_minus_escrow + (calculateTransactionEscrow(payable_minus_escrow) * y) );
    return payable_plus_escrow;
} 

const calculateTransactionPayableByBuyer = (data) => {
    let x,y
    switch (data.escrow_fee_bearer) {
        case 'customer':
             y = 1;
            break;
        case 'seller':
             y = 0;
            break;
        default:
             y = 0.5
    }
    switch (data.tx_fee_bearer) {
        case 'customer':
            x = 1;
            break;
        case 'seller':
            x = 0;
            break;
        default:
            x = 1;
    }
    let totalAmount=(data.amount_net)/100;    
    let addServiceAmt=data.additional_service_amount/100;
    let shippingCost=(data.shipping_cost*x)/100;
    let payable_minus_escrow = eval( totalAmount + addServiceAmt  + shippingCost);
    let escrowFees= (calculateTransactionEscrow(payable_minus_escrow) * y);
    let payable_plus_escrow = eval(payable_minus_escrow + escrowFees);
    let cardFee=calculateFinalCardFeePLusTx(payable_plus_escrow,data.gateway_name,data.currency)*x;
    return (payable_plus_escrow+cardFee);

}

const calculateFinalCardFeePLusTx=(ngnOrderAmount,gateway,currency)=>{
    let cardfeePercent=(gateway=='USSD')?0.75:(currency=='USD')?3.9:1.5;
    let maxCappingValue=(gateway=='USSD')?100:2000;
    let additionalChargeCheckUp=(currency=='USD')?0:2500;
    let aditionalCharge=(gateway=='USSD')?0:100;
    let cardFee=(cardfeePercent*ngnOrderAmount)/100;
    let txFee=(gateway=='USSD')?80:50;
    if(ngnOrderAmount>=additionalChargeCheckUp){ 
        cardFee=cardFee+aditionalCharge;
    }
    if(currency!='USD'){
        cardFee=(cardFee>=maxCappingValue)?maxCappingValue:cardFee;
    } 
    return (cardFee+txFee);

}

const calculateTransactionRefundable = (data, fmt = 0) => {
    let x, y
    switch (data.escrow_fee_bearer) {
        case 'customer':
            y = 0;
            break;
        case 'seller':
            y = 1;
            break;
        default:
            y = 0.5
    }
    switch (data.shipping_fee_bearer) {
        case 'customer':
            x = 1
            break;
        case 'seller':
            x = 0;
            break;
        default:
            x = 0.5
    }    
    const payable_minus_escrow = eval(
        
        (parseFloat((fmt === 1 ? data.amount_payed / 100 : fmt === 2 ? data.amount_net / 100 : data.amount_payed) * (fmt === 1 || fmt === 2 ? 1 : data.quantity)) + parseFloat((fmt === 1 || fmt === 2 ? data.additional_service_amount / 100 : data.additional_service_amount)) + parseFloat((fmt === 1 || fmt === 2 ? data.shipping_cost / 100 : data.shipping_cost) * x)))

    const payable_plus_escrow = eval(payable_minus_escrow - (calculateTransactionEscrow(payable_minus_escrow) * y));

    return payable_plus_escrow;
} 

export const toLocaleDisplay = (quantity , curency= 'NGN') => {
     return quantity.toLocaleString(undefined, { style: 'currency', currency: curency, currencyDisplay: 'symbol', minimumFractionDigits: 2, maximumFractionDigits: 2})
}
export default {
    formatTransactionData,
    formatTransctionType,
    calculateTransactionPayable,
    calculateTransactionPayableByBuyer,
    calculateTransactionRefundable,
    calculatePayableEscrow,
    calculateTransactionEscrow,
    calculatePayableShipping,
    calculateShipping,
    rformatTransactionData,
    calculateCardFee,
    calculateUSSDCardFee,
    calculateUsdCardFee,
    toLocaleDisplay
}