import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import POD from '../views/Pod';
import { orderActions, userActions } from '../actions';

const mapStateToProps = (state) => {

    const { sending, success } = state.users;
    const {loading} = state.orders
    return {
        sending,
        success,
        loading,
        alert: state.alert
    };
}


const mapDispatchToProps = (dispatch) => ({
    dispatch,
    processPod: (type, data, history) => dispatch(orderActions.order.processPod(type, data, history)),
    logout: (type, history) => dispatch(userActions.user.logout(type, history))
}
)


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(POD));