import React, {Component} from 'react';
import ReactTable from "react-table";
import * as axios from "axios";
import {BASE_URL} from "../../constants";

class ReferralBenefitsReport extends Component {
  state = {
    loading: false,

    data: [],
    dataOriginal: [],

    notCustom: true,
  }

  componentDidMount() {
    this.loadReport();
  }

  loadReport = () => {
    this.setState({loading: true})
    axios({
      method: 'get',
      mode: 'no-cors',
      url: `${BASE_URL}admin/reports/referral-benefits`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
      .then(({data: {data}}) => {
        console.log(data);
        this.setState({
          dataOriginal: data,
          data,
        })
      })
      .catch(err => {
        console.log(err.response);
      })
      .finally(() => this.setState({loading: false}))

  };

  handleFilter = e => {
    const {target: {value}} = e;
    const {dataOriginal: data} = this.state;

    const includes = property => {

      return property ? property.toString().toLowerCase().includes(value.toLowerCase()) : false
    }

    if (value === "" || value === null || value === undefined) {
      this.setState({data: this.state.dataOriginal});
      return;
    }


    const filtered = [];

    for (var i = 0; i < data.length; i++) {

      const {
        id,
        user: {email},
        name,
        total_registrations,
        promo_usage_freq,
        usage_count,
      } = data[i];

      if (
        includes(id.toString()) ||
        includes(name) ||
        includes(email) ||
        includes(promo_usage_freq) ||
        includes(total_registrations) ||
        includes(usage_count)
      ) {
        filtered.push(data[i]);
      }
    }

    this.setState({data: filtered})
  }

  render() {

    const columns = [
      {
        Header: props => <span><b>ID</b></span>,
        id: "id",
        filterable: false,
        maxWidth: 200,
        accessor: d => d.id,
        Cell: ({original: props}) => {
          return <div className="text-center">{props.id}</div>;
        }
      },
      {
        Header: props => <span><b>RCB Name</b></span>,
        id: "name",
        filterable: false,
        maxWidth: 200,
        accessor: d => d.name,
        Cell: ({original: props}) => {
          return <span>{props.name}</span>;
        }
      },
      {
        Header: props => <span><b>Affected User</b></span>,
        id: "users",
        filterable: false,
        maxWidth: 200,
        sortable: false,
        Cell: ({original: props}) => {
          return <span>
            {
              props.user && <div>{props.user.firstname} {props.user.lastname} ({props.user.email})</div>
            }
          </span>;
        }
      },
      {
        Header: props => <span><b>Required Registrations</b></span>,
        id: "required_registrations",
        filterable: false,
        maxWidth: 200,
        accessor: d => d.required_registrations,
        Cell: ({original: props}) => {
          return <div className="text-center">
            {props.required_registrations}
          </div>;
        }
      },
      {
        Header: props => <span><b>Registration Count</b></span>,
        id: "total_registrations",
        filterable: false,
        maxWidth: 200,
        accessor: d => d.total_registrations,
        Cell: ({original: props}) => {
          return <div className="text-center">
            {props.total_registrations}
          </div>;
        }
      },
      {
        Header: props => <span><b>Promo Usage Count</b></span>,
        id: "usage_count",
        filterable: false,
        maxWidth: 200,
        accessor: d => d.usage_count,
        Cell: ({original: props}) => {
          return <div className="text-center">
            {props.usage_count === "N/A" ? "Yet to be fulfilled" : props.usage_count}
          </div>;
        }
      },
      {
        Header: props => <span><b>Total Usage Frequency</b></span>,
        id: "promo_usage_freq",
        filterable: false,
        maxWidth: 200,
        accessor: d => d.promo_usage_freq,
        Cell: ({original: props}) => {
          return <span className="text-center">
            {props.promo_usage_freq}
          </span>;
        }
      },
    ];
    return (
      <div className={'content-wrapper'}>

        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">

              <div className="col-sm-12">
                <h1 className="m-0 text-dark">Referral Benefits</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid">
            <div className="row ">
              <div className="col-12 col-sm-12 col-md-12">
                <div className="alert animated bounceIn alert-danger d-none search-alert">
                  <a aria-label="close" className="close alert-close">×</a>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 col-md-4">
                        <div className="form-group"><label htmlFor="filters">Filter</label>
                          <input type="text"
                                 name={'filters'}
                                 className={'form-control'}
                                 onChange={this.handleFilter}
                          />
                        </div>
                      </div>
                      {/*<div className="col-12 col-sm-3">*/}
                      {/*  <div className="form-group">*/}
                      {/*    <label htmlFor="from">Date From</label>*/}
                      {/*    <input*/}
                      {/*       type="date"*/}
                      {/*       name="from_date"*/}
                      {/*       value={this.state.from_date}*/}
                      {/*       disabled={this.state.notCustom}*/}
                      {/*       onChange={this.handleDate}*/}
                      {/*       className="form-control"/>*/}
                      {/*  </div>*/}
                      {/*</div>*/}
                      {/*<div className="col-12 col-sm-3">*/}
                      {/*  <div className="form-group">*/}
                      {/*    <label htmlFor="from">Date To</label>*/}
                      {/*    <input*/}
                      {/*       type="date"*/}
                      {/*       name="to_date"*/}
                      {/*       value={this.state.to_date}*/}
                      {/*       disabled={this.state.notCustom}*/}
                      {/*       onChange={this.handleDate}*/}
                      {/*       className="form-control"/>*/}
                      {/*  </div>*/}
                      {/*</div>*/}
                      <div className="col-12 col-sm-2">
                        <div className="form-group"><label>&nbsp;</label>
                          <div className="row">
                            <div className="col-12">
                              <button disabled={this.state.notCustom} onClick={() => {
                                this.setState({data: [...this.filterDates(this.state.dataOriginal)]})
                              }} className="btn btn-info btn-block form-control">
                                <center>Search</center>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br/>
                    <div className="table-responsive">
                      <ReactTable
                        loading={this.state.loading}
                        defaultPageSize={5}
                        filterable
                        data={this.state.data}
                        columns={columns}/>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default ReferralBenefitsReport;