import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import RecoverPass from '../views/recoverPassword';
import { userActions } from '../actions';

const mapStateToProps = (state) =>{
    
    const { resetting,success } = state.users;
    return {
        resetting,
        success,
        alert : state.alert
    };
 }
 

 const mapDispatchToProps = (dispatch) => ({
    dispatch,
     resetPass: (type, data, history) => dispatch(userActions.user.resetPassword(type, data,history))
    
}
)
 

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(RecoverPass));