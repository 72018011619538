export const dispute = {
    ORDER_DISPUTES_REQUEST: 'ORDER_DISPUTES_REQUEST',
    ORDER_DISPUTES_SUCCESS: 'ORDER_DISPUTES_SUCCESS',
    ORDER_DISPUTES_FAILURE: 'ORDER_DISPUTES_FAILURE',

    ORDER_DISPUTE_REQUEST: 'ORDER_DISPUTE_REQUEST',
    ORDER_DISPUTE_SUCCESS: 'ORDER_DISPUTE_SUCCESS',
    ORDER_DISPUTE_FAILURE: 'ORDER_DISPUTE_FAILURE',

    RAISE_DISPUTE_REQUEST: 'RAISE_DISPUTE_REQUEST',
    RAISE_DISPUTE_SUCCESS: 'RAISE_DISPUTE_SUCCESS',
    RAISE_DISPUTE_FAILURE: 'RAISE_DISPUTE_FAILURE',

    CANCEL_DISPUTE_REQUEST: 'CANCEL_DISPUTE_REQUEST',
    CANCEL_DISPUTE_SUCCESS: 'CANCEL_DISPUTE_SUCCESS',
    CANCEL_DISPUTE_FAILURE: 'CANCEL_DISPUTE_FAILURE',
}