export const settings = {
    SET_REFUNDABLE: 'SET_REFUNDABLE',
    SET_REFUNDABLE_SUCCESS: 'SET_REFUNDABLE_SUCCESS',
    SET_REFUNDABLE_FAILURE: 'SET_REFUNDABLE_FAILURE',


    SET_PAYMENT: 'SET_PAYMENT',
    SET_PAYMENT_SUCCESS: 'SET_PAYMENT_SUCCESS',
    SET_PAYMENT_FAILURE: 'SET_PAYMENT_FAILURE',

    SET_CARD_FEE: 'SET_CARD_FEE',
    SET_CARD_FEE_SUCCESS: 'SET_CARD_FEE_SUCCESS',
    SET_CARD_FEE_FAILURE: 'SET_CARD_FEE_FAILURE',
}