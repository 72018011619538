export const SELLER_FEE_BEARER_ID = "seller";
export const SELLER_FEE_BEARER_NAME = "Seller";
export const BUYER_FEE_BEARER_ID = "customer";
const BUYER_FEE_BEARER_NAME = "Buyer";
export const BOTH_FEE_BEARER_ID = "both";
export const BOTH_FEE_BEARER_NAME = "Both";
export const FEE_BEARERS = [
  {
    id: SELLER_FEE_BEARER_ID,
    label: SELLER_FEE_BEARER_NAME,
  },
  {
    id: BUYER_FEE_BEARER_ID,
    label: BUYER_FEE_BEARER_NAME,
  },
];
export const ALL_FEE_BEARERS = [
  {
    id: SELLER_FEE_BEARER_ID,
    label: SELLER_FEE_BEARER_NAME,
  },
  {
    id: BUYER_FEE_BEARER_ID,
    label: BUYER_FEE_BEARER_NAME,
  },
  {
    id: BOTH_FEE_BEARER_ID,
    label: BOTH_FEE_BEARER_NAME,
  },
];
export const SELLER_OR_BOTH_FEE_BEARERS = [
  {
    id: SELLER_FEE_BEARER_ID,
    label: SELLER_FEE_BEARER_NAME,
  },
  {
    id: BOTH_FEE_BEARER_ID,
    label: BOTH_FEE_BEARER_NAME,
  },
];

export const FEE_BEARER_MAPPING = new Map([
  [SELLER_FEE_BEARER_ID, SELLER_FEE_BEARER_NAME],
  [BUYER_FEE_BEARER_ID, BUYER_FEE_BEARER_NAME],
  [BOTH_FEE_BEARER_ID, BOTH_FEE_BEARER_NAME],
]);

export const NO_SELECT_OPTION_ID = "no";
export const YES_NO_SELECT_OPTIONS = [
  {
    id: NO_SELECT_OPTION_ID,
    label: "No",
  },
  {
    id: "yes",
    label: "Yes",
  },
];
