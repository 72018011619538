export const term = {
    CREATE_TERM: 'CREATE_TERM',
    CREATE_TERM_SUCCESS: 'CREATE_TERM_SUCCESS',
    CREATE_TERM_FAILURE: 'CREATE_TERM_FAILURE',

    EDIT_TERM: 'EDIT_TERM',
    EDIT_TERM_SUCCESS: 'EDIT_TERM_SUCCESS',
    EDIT_TERM_FAILURE: 'EDIT_TERM_FAILURE',

    REQUEST_TERM: 'REQUEST_TERM',
    REQUEST_TERM_SUCCESS: 'REQUEST_TERM_SUCCESS',
    REQUEST_TERM_FAILURE: 'REQUEST_TERM_FAILURE',
    MAX_DELIVERY_DAY:'7'
}