import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import Confirmation from '../views/confirmation';
import { userActions } from '../actions';
// import { payoutActions } from '../actions';


const mapStateToProps = (state) =>{
    const { sending,submitting} = state.register;
    

    const alert  = state.alert;
    
    return {
        alert,
        sending,
        submitting
       

       
    };
     
     
 }
 const mapDispatchToProps = (dispatch) => ({
   
    get:(type,token,history)=>dispatch(userActions.user.getOtp(type,token,history)),
    send:(type,otp,token,history)=>dispatch(userActions.user.submitOtp(type,otp,token,history)),
    dispatch
})
 

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Confirmation));