import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import swal from '@sweetalert/with-react';
import qs from 'query-string';

import { orderActions } from '../actions';

class PaymentGateway extends Component {

    componentDidMount() {
        let params = qs.parse(this.props.location.search);
        if(params.reference){
            this.props.processPayment(params.reference, this.props.history);
        }
        else{
          this.props.processPayment(params.paymentReference, this.props.history);  
        }
        
    }

    componentWillReceiveProps(props) {
        if (props.alert && props.alert.message && props.alert.type === 'alert-danger') {
            // swal("Error", props.alert.message, "error");
            
        }
    }

    render() {
        const { alert, loading, result } = this.props;
        return (
            <div style={{ height: '100vh', backgroundColor: '#f7fcfc' }}>
                <nav class="navbar navbar-expand-lg fixed-top navbar-inverse">
                    <a class="navbar-brand" href="http://www.atarapay.com">
                        <img src="/dist/img/logo-header.png" alt="" className="logo-img img img-responsive" />
                    </a>
                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                </nav>
                <section>
                    <div class="row row-margin top-margin-60">
                        <div className="col-sm-3 col-md-3"></div>
                        <div className="col-sm-8 col-md-6 col-12">
                        {this.props.alert && this.props.alert.message && this.props.alert.type === 'alert-danger' ?
                                <div className="card card-margin text-center">
                                    <p style={{ fontSize: 18, marginBottom: 28 }}>{this.props.alert.message}</p>
                                </div> :
                            <div className="card card-margin text-center">
                            <p style={{fontSize: 18, marginBottom: 30}}>Request in progress....</p>
                        <p>You will be redirected in few seconds</p>
                            </div> 
                              }
                        <div class="col-sm-3 col-md-3"></div>
                       
                    </div>
                    </div>
                    {/* </div> */}
                </section>
               
                
               
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    const { loading, result } = state.orders;
    const alert = state.alert;

    return {
        loading,
        result,
        alert
    };


}
const mapDispatchToProps = (dispatch) => ({

    processPayment: (reference, history) => dispatch(orderActions.order.processPayment(reference, history)),

}
)


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentGateway));