import React, {Component} from 'react';
import StageThree from './transaction/StageThree';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux'
import {transactionActions, alertActions, payoutActions, termActions} from '../actions';
import transactionHelper from '../helpers/transaction';
// import './transaction/transaction.css';

import swal from '@sweetalert/with-react';

// const transaction = {
//     type: 'general',
//     product_name: 'product_name',
//     product_category: 'automobile',
//     buyer_email: 'buyer@buyer.com',
//     buyer_phone: '08099887765',
//     amount: 200000,
//     quantity: 2,
//     escrow_bearer: 'seller',
//     shipping_method: 1,
//     shipping_cost: 3000,
//     max_delivery_days: 1,
//     shipping_bearer: 'seller',
//     description: 'Get me a car',
//     is_additional: 1,
//     additional_services: [
//         {
//             service: 'Title Collection',
//             cost: 3000,
//             selected: true
//         }
//     ],
//     extra: {}
// }
class SingleTransaction extends Component {    
    ///dummy
    //shall be replace with async action of props
    componentDidMount = () => {
        // this.props.getTerms(this.props.history)
        if(!localStorage.getItem("user")){
         if (this.props.location.pathname.includes("transaction/pay")){
             return this.props.history.push("/login/buyer");
         }else if(this.props.location.pathname.includes("transaction/seller")){
             return this.props.history.push("/login/seller");
         } else if (this.props.location.pathname.includes("transaction/buyer")){
             return this.props.history.push("/login/buyer");
         }
        }
        this.props.getTransactions(localStorage.getItem("trust_user_type"), this.props.history)
    }
    state = {
        type: '',
        product_name: '',
        category: '',
        buyer_email: '',
        buyer_phone: '',
        seller_email: '',
        seller_phone: '',
        amount_payed: 0,
        quantity: 1,
        escrow_fee_bearer: '',
        shipping_method: 0,
        shipping_cost: 0,
        max_delivery_days: 0,
        shipping_fee_bearer: '',
        product_desc: '',
        is_additional_serivces: 0,
        additional_services: [],
        extra: {},
        alt_phone: ''
    }

    editTransaction = (data, history) => {

        // const transaction = {
        //     ...data, 
        //     amount_payed: eval(parseInt(data.amount_payed / data.quantity)),
        //     additional_services: data.additional_services.map( service => ({...service, selected:true}))
        // }
        // const transaction = transactionHelper.rformatTransactionData(data);
        this.props.editTransaction(data, history);
        this.props.history.push('/app/transaction');
    }

    cancelTransaction = (response) => {
        this.props.cancel(this.props.role, response, this.props.history)
    }

    handlePhone = (value, name) => {
        let data = { ...this.state.data, [name]: value }
        this.setState({ data });
    }
    // componentWillUnmount = () => {
    //     this.props.deSelectTransaction()
    // }
    componentWillReceiveProps = (props) => {
        if(props.alert && props.alert.message && props.alert.type==="alert-danger"){
            swal("", props.alert.message, "error").then(() => {
                props.dispatch(alertActions.alert.clear())
            })
        } else if(props.alert && props.alert.message && props.alert.type==="alert-success"){
            swal("Success", props.alert.message, "success").then(() => {
                this.props.dispatch(alertActions.alert.clear())
                props.history.push(`/app/transactions`);
            })
        }
    }
    render() {
        const {id} = this.props.match.params;
        const { transactions, role, terms, user, accounts } = this.props
        let data = transactions && transactions.find(transaction => transaction.id == id);
        data = data && transactionHelper.rformatTransactionData(data);
        if (!data) {
            this.props.history.push('/app/transactions');
        }
        return (
            <div>
                {data && 
                <StageThree 
                    user={user}
                    role={role}
                    accounts={accounts}
                    terms={terms}
                    data={data&&data} 
                    pay={this.props.pay}
                    cancel={this.cancelTransaction}
                    handleViewChange={this.changeView} 
                    editTransaction={this.editTransaction} 
                    handlePhone={this.handlePhone}
                    history={this.props.history}
                />}
            </div>
            
        )
    }
}

export default withRouter(
    connect(
        state => ({
            transactions: state.transaction.transactions,
            role: localStorage.getItem('trust_user_type'),
            terms: state.terms,
            user: state.users.user,
            accounts: state.payout.accounts
        }),
        dispatch => ({
            getTransactions: (type, history) => dispatch(transactionActions.transaction.getTransactions(type, history)),
            cancel: (type, response, history) => dispatch(transactionActions.transaction.cancelTransaction(type, response, history)),
            pay: (type, _transaction, alt_phone, history) => dispatch(transactionActions.transaction.makePayment(type, _transaction, alt_phone, history)),
            deSelectTransaction:() => dispatch(transactionActions.transaction.cancelEditTransaction()),
            viewPayout:(type, history)=>dispatch(payoutActions.payout.view(type, history)),
            getTerms: (history) => dispatch(termActions.getTerm(history)),
            dispatch
        })
    )(SingleTransaction)
);