import React, {Component} from 'react';
import {css} from '@emotion/core';
import swal from '@sweetalert/with-react';
import Modal from 'react-responsive-modal';

import ReactTable from "react-table";
import 'react-table/react-table.css';
import * as moment from 'moment';
import axios from "axios";
import {BASE_URL} from "../constants";
import {ClipLoader} from "react-spinners";
import Select from "react-select";

const initialForm = {
  user_type: 'individual_seller',
  user_id: null,
  name: '',
  description: '',
  promo_id: null,
  required_registrations: 0,
  required_transactions: 0,
  expiration_date: moment().add({days: 2}),
  start_date: moment(),
  status: 'active',
  users: [],
  userSelects: [],
  promo_usage_freq: 1,
  promo_percent: 0,
}
const defaultSelectedUser = {
  label: 'Select an user',
  value: 0,
};

const override = css`
  display: block;
  margin-top: 10%;
  margin-left: 40%;
  margin-right: 60%;
  border-color: red;
`;

class ReferralBenefits extends Component {

  state = {
    filter: "",
    data: [],
    notCustom: true,
    period: "All",
    from_date: "",
    to_date: "",
    disputes: [],
    loading: false,
    role: 'seller',
    openReferralForm: false,
    referralForm: initialForm,
    editForm: false,
    buyers: [],
    sellers: [],
    isSaving: false,
    selectedUser: defaultSelectedUser,
    promos: [],
  }

  componentDidMount() {

    this.getData();
    this.getBuyers();
    this.getSellers();
    // this.getPromos();
  }


  getSellers = () => {
    axios({
      method: 'get',
      mode: 'no-cors',
      url: `${BASE_URL}admin/users`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
      .then(res => {
        this.setState({
          sellers: res.data.data,

        })
      })
      .catch(err => {
        swal({
          icon: 'error',
          title: 'Error',
          text: 'Error fetching sellers',
        })
      })
      .finally(() => {
        this.setState({
          loading: false,
        })
      })
  }

  getBuyers = () => {
    axios({
      method: 'get',
      mode: 'no-cors',
      url: `${BASE_URL}admin/customers`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
      .then(res => {
        this.setState({
          buyers: res.data.data,
        })
      })
      .catch(err => {
        swal({
          icon: 'error',
          title: 'Error',
          text: 'Error fetching customers',
        })
      })
      .finally(() => {
        this.setState({
          loading: false,
        })
      })
  }

  // getPromos = () => {
  //   axios({
  //     method: 'get',
  //     mode: 'no-cors',
  //     url: `${BASE_URL}admin/promos/active`,
  //     headers: {
  //       'Access-Control-Allow-Origin': '*',
  //       'Authorization': 'Bearer ' + localStorage.getItem("user"),
  //       'Content-Type': 'application/json',
  //     }
  //   })
  //     .then(({data}) => {
  //       this.setState({
  //         promos: data.data
  //       })
  //     })
  //     .catch(e => {
  //       swal({
  //         title: 'error',
  //         icon: 'error',
  //         content: 'Error fetching promos',
  //       })
  //     })
  // }

  getData = () => {
    this.setState({
      loading: true,
    })
    axios({
      method: 'get',
      mode: 'no-cors',
      url: `${BASE_URL}admin/referral-benefits`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
      .then(res => {
        this.setState({
          data: res.data.data,
          referralBenefits: res.data.data,
        })
      })
      .catch(err => {
        console.log(err)
      })
      .finally(() => {
        this.setState({
          loading: false,
        })
      })
  }

  handleFilter = e => {
    this.setState({
      filter: e.target.value
    }, () => {

      if (this.state.filter === '') {
        this.setState({
          data: this.state.referralBenefits
        })
        return;
      }

      const filtered = [];
      let {filter: f} = this.state;
      f = f.toString().toUpperCase();

      this.state.referralBenefits.forEach(referralBenefit => {

        let name = '';
        if (referralBenefit.user) {
          name = referralBenefit.user;
        }

        if (
          referralBenefit.name.toUpperCase().includes(f)
          || (referralBenefit.promo && referralBenefit.promo.promo_code.toUpperCase().includes(f))
          || name.toUpperCase().includes(f)
          || referralBenefit.required_registrations.toString().toUpperCase().includes(f)
          || referralBenefit.required_transactions.toString().toUpperCase().includes(f)
          || referralBenefit.expiration_date.toString().toUpperCase().includes(f)
          || referralBenefit.start_date.toString().toUpperCase().includes(f)
        ) {
          filtered.push(referralBenefit);
        }

      })

      this.setState({
        data: filtered
      })

    })
  }
  referralFormChange = e => {
    this.setState({
      referralForm: {
        ...this.state.referralForm,
        [e.target.name]: e.target.value,
      }
    })
  }

  saveRCB = e => {
    e.preventDefault();

    if (this.state.isSaving) {
      return;
    }

    const data = {
      ...this.state.referralForm,
      users: Array.isArray(this.state.referralForm.selectedUsers) ? this.state.referralForm.selectedUsers.map(u => u.value) : [],
      description: this.getDescription()
    }
    let method = 'post';
    let url = `${BASE_URL}admin/referral-benefits`

    if (this.state.editForm) {
      method = 'put';
      url = `${BASE_URL}admin/referral-benefits/${this.state.referralForm.id}`
    }

    axios({
      method,
      mode: 'no-cors',
      url,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      },
      data
    })
      .then((res) => {
        this.setState({
          openReferralForm: false,
        })

        swal({
          icon: 'success',
          title: 'Success',
          text: 'Referral benefit saved',
        })
        this.getData();
      })
      .catch(e => {
        swal({
          title: 'error',
          icon: 'error',
          text: e.response.data.message,
        })
        console.log(e.response);
      })
  }

  deleteRCB = id => {
    axios({
      method: 'delete',
      mode: 'no-cors',
      url: `${BASE_URL}admin/referral-benefits/${id}`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
      .then(() => {
        this.setState({
          openReferralForm: false,
        })
        swal({
          icon: 'success',
          title: 'Success',
          text: 'referral benefit deleted',
        })
        this.getData();
      })
      .catch(e => {
        swal({
          icon: 'error',
          title: 'Error',
          text: 'Error',
        })
        console.log(e.response);
      })
  }

  disableRCB = id => {
    axios({
      method: 'delete',
      mode: 'no-cors',
      url: `${BASE_URL}admin/referral-benefits/disable/${id}`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
      .then(() => {
        this.setState({
          openReferralForm: false,
        })
        swal({
          icon: 'success',
          title: 'Success',
          text: 'Referral benefit disabled.',
        })
        this.getData();
      })
      .catch(e => {
        swal({
          icon: 'error',
          title: 'Error',
          text: 'Error',
        })
      })
  }

  enableRCB = id => {
    axios({
      method: 'delete',
      mode: 'no-cors',
      url: `${BASE_URL}admin/referral-benefits/enable/${id}`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
      .then(() => {
        this.setState({
          openReferralForm: false,
        })
        swal({
          icon: 'success',
          title: 'Success',
          text: 'Referral benefit enabled.',
        })
        this.getData();
      })
      .catch(e => {
        swal({
          icon: 'error',
          title: 'Error',
          text: 'Error',
        })
      })
  }

  setPromoField = (field, value) => {
    this.setState({
      referralForm: {
        ...this.state.referralForm,
        [field]: value,
      }
    })
  }

  getDescription = () => {

    const {referralForm} = this.state;

    return `If the user shares their referral code to ${referralForm.required_registrations} new users, send promo code ${referralForm.promo ? referralForm.promo.promo_code : ''} to the referrer. Valid until ${moment(referralForm.expiration_date).format('YYYY-MM-DD')}`;
  }

  render() {
    const {data, filterable, loading, referralForm, editForm, buyers, sellers, promos} = this.state;
    var columns = [];

    columns = [
      {
        Header: props => <span><b>SN</b></span>,
        id: "sn",
        filterable,
        maxWidth: 80,
        accessor: d => d,// String-based value accessors!
        Cell: (row) => {
          return <div className="text-center">{row.index + 1}</div>;
        }
      },
      {
        Header: props => <span><b>RCB ID</b></span>,
        id: "id",
        filterable,
        maxWidth: 80,
        accessor: d => d.id,// String-based value accessors!
        Cell: props => {
          return <div className="text-center">{props.value}</div>;
        }
      },
      {
        Header: props => <span><b>Name</b></span>,
        id: "name",
        accessor: d => d.name,
        Cell: props => <span>{props.value} {props.original.is_default && <span className="badge badge-primary">
          {props.original.user_type === 'all' && "Globally "} Active {props.original.user_type === 'sellers' && "for all sellers"} {props.original.user_type === 'buyers' && "for all buyers "}
        </span>}</span>
      },
      {
        Header: props => <span><b>Description</b></span>,
        id: "description",
        accessor: d => d.description,
        Cell: props => <span>{props.value}</span>
      },
      {
        Header: props => <span><b>User Type</b></span>,
        id: "user_type",
        accessor: d => d.user_type,
        Cell: props => {
          let userType = "N/A";

          if (props.original.user_type === 'individual_seller') {
            userType = 'Individual Seller(s)';
          } else if (props.original.user_type === 'individual_buyer') {
            userType = 'Individual Buyer(s)';
          } else if (props.original.user_type === 'all') {
            userType = "Global"
          } else if (props.original.user_type === 'buyers') {
            userType = "All Buyers"
          } else if (props.original.user_type === 'sellers') {
            userType = "All Sellers"
          }
          return <span>{userType}</span>
        }
      },
      {
        Header: props => <span><b>Users</b></span>,
        id: 'users',
        accessor: d => d.users,
        Cell: props => {

          let users = "None";
          if (Array.isArray(props.value)) {
            users = props.value.map(user => `${user.firstname} ${user.lastname}`).join(', ')
          }

          if (props.original.user_type === 'sellers') {
            users = "All sellers";
          }
          if (props.original.user_type === 'buyers') {
            users = "All Buyers";
          }

          return <span>{users}</span>
        }
      },
      {
        Header: props => <span><b>Registrations required</b></span>,
        id: "required_registrations",
        accessor: d => d.required_registrations,
        Cell: props => <div className='text-center'>
          {props.value}
        </div>
      },
      // {
      //   Header: props => <span><b>Transactions required</b></span>,
      //   id: "required_transactions",
      //   accessor: d => d.required_transactions,
      //   Cell: props => <span className='number'>
      //     {props.value}
      //   </span>
      // },
      {
        Header: props => <span><b>Usage Frequency</b></span>,
        id: "promo_usage_freq",
        accessor: d => d.promo_usage_freq,
        Cell: props => {
          return (
            <div className="text-center">
              {props.original.promo_usage_freq}
            </div>
          )
        }
      },
      {
        Header: props => <span><b>Status</b></span>,
        id: "status",
        accessor: d => d.status,
        Cell: props => {
          let status = "N/A";

          if (props.value === 'active') {
            status = 'Active';
          }

          if (props.value === 'inactive') {
            status = 'inactive';
          }

          return (
            <span>
              {
                status || "N/A"
              }
            </span>
          )
        }
      },
      {
        Header: props => <span><b>Start Date</b></span>,
        id: "start_date",
        accessor: d => d.start_date,
        Cell: props => <span>
          {props.value || "N/A"}
        </span>
      },
      {
        Header: props => <span><b>Expiration Date</b></span>,
        id: "expiration_date",
        accessor: d => d.expiration_date,
        Cell: props => <span>
          {props.value || "N/A"}
        </span>
      },
      {
        Header: props => <span><b>Actions</b></span>,
        id: "actions",
        accessor: d => d,
        width: 150,
        sortable: false,
        Cell: ({original: props}) => {
          return (
            <div className='text-center'>
              {
                props.status === 'inactive' &&
                <button className="btn btn-success" onClick={(e) => {
                  e.preventDefault();
                  this.enableRCB(props.id)
                }}>
                  Enable
                </button>
              }
              {
                props.status === 'active' &&
                <button className="btn btn-danger" onClick={(e) => {
                  e.preventDefault();
                  this.disableRCB(props.id)
                }}>
                  Disable
                </button>
              }
            </div>
          )
        }
      },
    ]

    return (
      <div className="content-wrapper" style={{minHeight: 311}}>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-12">
                <h1 className="m-0 text-dark">Referral Benefits</h1>
              </div>
            </div>
          </div>
        </div>
        <section className="content">
          <div className="container-fluid"><br/>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12">
                {/* {alert && alert.message && alert.type!="alert-danger"&&<div className={`alert buyer-alert ${alert.type}`} ><a
                                aria-label="close" className="close alert-close">×</a> <span   className="alert-message-content">{alert.message}</span></div>} */}
                {!loading ?
                  <div className="card">

                    <div className="card-body">

                      <div className="row">
                        <div className="col-12 col-sm-4">
                          <div className="form-group">
                            <label for="filter">Filter</label>
                            <input type="text"
                                   placeholder="Filter"
                                   className="form-control"
                                   value={this.state.filter}
                                   onChange={this.handleFilter}/>
                          </div>
                        </div>
                        <div className="col-12 col-sm-4">
                          <div className="form-group">
                            <label>&nbsp;</label>
                            <div className="row">
                              <div className="col-12">
                                <button className="btn btn-info"
                                        onClick={() => this.setState({
                                          openReferralForm: true,
                                          editForm: false,
                                          selectedUser: defaultSelectedUser,
                                          referralForm: initialForm
                                        })}>
                                  Add Referral Benefit
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <br/>

                      <div className="table-responsive">
                        <ReactTable
                          defaultPageSize={5}
                          data={data}
                          columns={columns}/>

                      </div>
                    </div>
                  </div>
                  :
                  <div className='sweet-loading'>
                    <ClipLoader
                      className={override}
                      sizeUnit={"px"}
                      size={70}

                      color={'blue'}
                      loading={loading}
                    />
                  </div>
                }
              </div>
            </div>
            <br/>
          </div>
        </section>
        <div>
          <Modal open={this.state.openReferralForm} onClose={() => this.setState({openReferralForm: false})} center
                 styles={{
                   modal: {
                     maxWidth: '800px',
                     width: '100%',
                   },
                   overlay: {
                     zIndex: 1500,
                   }
                 }}>
            <form onSubmit={this.saveRCB}>
              <div className="modal-header">
                <h4 className="modal-title">{editForm ? "Edit Referral Benefit" : "Create Referral Benefit"}</h4>
              </div>
              <div className="modal-body">
                <div className="row mb-3">
                  <div className="col-md-6 col-sm-12">
                    <label htmlFor="name">Referral Benefit Name</label>
                    <input
                      type="text" name="name"
                      value={referralForm.name}
                      className="form-control"
                      onChange={this.referralFormChange}
                    />
                  </div>
                  <div className="col-md-6 col-sm-12">
                    <label htmlFor="user_type">Target User</label>
                    <select name="user_type" value={referralForm.user_type} onChange={e => {
                      const target = {
                        name: e.target.name,
                        value: e.target.value
                      };

                      this.setState({
                        selectedUser: defaultSelectedUser,
                        referralForm: {
                          ...referralForm,
                          selectedUsers: [],
                        }
                      }, () => {
                        this.referralFormChange({target});
                      })
                    }}
                            className="form-control">
                      <option value={'all'}>Global</option>
                      <option value={'buyers'}>All Buyers</option>
                      <option value={'sellers'}>All Sellers</option>
                      <option value={'individual_buyer'}>Individual Buyer</option>
                      <option value={'individual_seller'}>Individual Seller</option>
                    </select>
                  </div>
                </div>
                {
                  <div className="row mb-3">

                    <div className="col-md-6 col-sm-12">
                      <label htmlFor="status">Status</label>
                      <select
                        name="status"
                        value={referralForm.status}
                        className="form-control"
                        onChange={this.referralFormChange}
                      >
                        <option value="inactive">Inactive</option>
                        <option value="active">Active</option>
                      </select>
                    </div>
                    {

                      (referralForm.user_type === 'individual_buyer' || referralForm.user_type === 'individual_seller') && (
                        <div className="col-md-6 col-sm-12">
                          {
                            referralForm.user_type === 'individual_buyer' &&
                            (<>
                                <label htmlFor="user_id">Buyer Name</label>
                                <Select
                                  isMulti
                                  type="text" name="user_id"
                                  value={referralForm.selectedUsers}
                                  onChange={selected => {
                                    this.setState({
                                      referralForm: {
                                        ...this.state.referralForm,
                                        // users: selected,
                                        selectedUsers: selected,
                                      }
                                    })
                                  }}
                                  options={buyers.map(buyer => ({
                                    label: `${buyer.firstname + ' ' + buyer.lastname} (${buyer.phone_number})`,
                                    value: buyer.id,
                                  }))}
                                />
                              </>
                            )
                          }

                          {
                            referralForm.user_type === 'individual_seller' && (
                              <>
                                <label htmlFor="user_id">Seller Name</label>
                                <Select
                                  isMulti
                                  type="text" name="user_id"
                                  value={referralForm.selectedUsers}
                                  onChange={selected => {
                                    this.setState({
                                      referralForm: {
                                        ...this.state.referralForm,
                                        // user_id: selected.value,
                                        selectedUsers: selected,
                                      }
                                    })
                                  }}
                                  options={sellers.map(seller => ({
                                    label: `${seller.firstname + ' ' + seller.lastname} (${seller.phone_number})`,
                                    value: seller.id,
                                  }))}
                                />
                              </>
                            )
                          }
                        </div>
                      )
                    }
                  </div>

                }

                <div className="row mb-3">

                  <div className="col-md-6 col-sm-12">
                    <label htmlFor="start_date">Start Date</label>
                    <input
                      name="start_date"
                      value={moment(referralForm.start_date).format('YYYY-MM-DD').toString()}
                      className="form-control"
                      onChange={e => {
                        this.setState({
                          referralForm: {
                            ...referralForm,
                            start_date: e.target.value,
                          }
                        })
                      }}
                      type="date"
                    />
                  </div>

                  <div className="col-md-6 col-sm-12">
                    <label htmlFor="expiration_date">Expiration</label>
                    <input
                      name="expiration_date"
                      value={moment(referralForm.expiration_date).format('YYYY-MM-DD').toString()}
                      className="form-control"
                      onChange={e => {
                        this.setState({
                          referralForm: {
                            ...referralForm,
                            expiration_date: e.target.value,
                          }
                        })
                      }}
                      type="date"
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-sm-12 col-md-6">
                    <label htmlFor="required_registrations">Required registrations</label>
                    <input
                      className="form-control" type="number" name="required_registrations"
                      min="0"
                      value={referralForm.required_registrations}
                      onChange={this.referralFormChange}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <label htmlFor="required_transactions">Required transactions</label>
                    <input className="form-control" type="number" name="required_transactions"
                           min="0"
                           value={referralForm.required_transactions}
                           disabled={true}
                           onChange={this.referralFormChange}
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-sm-12 col-md-6">
                    <label htmlFor="promo_usage_freq">Promo Usage Frequency</label>
                    <input
                      className="form-control"
                      type="number"
                      name="promo_usage_freq"
                      min="0"
                      value={referralForm.promo_usage_freq}
                      onChange={e => {
                        const value = parseInt(e.target.value) || '';

                        this.referralFormChange({
                          target: {
                            name: 'promo_usage_freq',
                            value
                          }
                        });
                      }}
                    />
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <label htmlFor="promo_percent">Promo percent</label>
                    <input
                      className="form-control"
                      type="number"
                      name="promo_percent"
                      min="0"
                      max="100"
                      value={referralForm.promo_percent}
                      onChange={e => {
                        const value = parseInt(e.target.value);

                        if (value > 100 || value < 0) {
                          return;
                        }
                        this.referralFormChange(e)
                      }}
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-sm-12">
                    <label htmlFor="description">Description</label>
                    <textarea
                      readOnly
                      name="description"
                      id="description"
                      className="form-control"
                      value={this.getDescription()}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-sm-12 text-right">
                    <button type="submit" className="btn btn-primary">Save</button>
                  </div>
                </div>
              </div>
            </form>
          </Modal>
        </div>
      </div>

    )
  }
}

export default ReferralBenefits;