import React, { Component } from "react";
import { Link } from "react-router-dom";
import { USER_ROLE_SELLER, USER_ROLE_MARKET } from "../constants";
import Avatar from "react-avatar";
import $ from "jquery";

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openPayout: false,
      openReports: false,
    };
  }

  getVerifiedStyle = () => {
    const { user } = this.props;
    if (
      user &&
      user.role &&
      (user.role.name === USER_ROLE_SELLER ||
        user.role.name === USER_ROLE_MARKET) &&
      (user.verified !== 1 ||
        user.support_verified !== 1 ||
        user.seller_id == null)
    ) {
      return {
        color: "darkgray",
        fontWeight: "400",
      };
    } else {
      return {
        // color: '#343a40'
      };
    }
  };

  CloseMenu = () => {
    if ($(window).width() >= 768) {
      $("body").addClass("sidebar-open").removeClass("sidebar-collapse");
    } else {
      $("body").addClass("sidebar-collapse").removeClass("sidebar-open");
    }
  };

  render() {
    const { openPayout, openReports } = this.state;
    const { user } = this.props;
    const { pathname } = this.props.location;
    //console.log(user);
    return (
      <div class='wrapper'>
        <aside className='main-sidebar sidebar-light-primary elevation-4'>
          {/* <!-- Brand Logo --> */}
          <a href='https://www.atarapay.com' className='brand-link'>
            <span className='brand-text'>
              {" "}
              <img
                src='/dist/img/logo.3fc64c3.png'
                alt='AtaraPay'
                style={{ opacity: 0.8, width: "140px", paddingLeft: 15 }}
              />
            </span>
          </a>

          {/* <!-- Sidebar --> */}
          <div className='sidebar' style={{ position: "relative" }}>
            {/* <!-- Sidebar user panel (optional) --> */}
            <Link
              onClick={(e) => {
                if (
                  user &&
                  user.role &&
                  (user.role.name === USER_ROLE_SELLER ||
                    user.role.name === USER_ROLE_MARKET) &&
                  (user.verified !== 1 ||
                    user.support_verified !== 1 ||
                    user.seller_id === null)
                ) {
                  e.preventDefault();
                }
              }}
              style={this.getVerifiedStyle()}
              to='/app/seller/profile/view'
            >
              <div className='user-panel mt-3 pb-3 mb-3 d-flex'>
                <div className='image'>
                  <Avatar
                    color={"rgb(52, 120, 246)"}
                    name={user && user.name}
                    maxInitials={2}
                    size={30.23}
                    textSizeRatio='1.75'
                    className={"img-circle elevation-2"}
                  />
                </div>
                <div className='info'>
                  {
                    <span href='#' className={"d-block"}>
                      {user && user.name}
                      <small>
                        <br />
                        Admin
                      </small>
                    </span>
                  }
                </div>
              </div>
            </Link>

            {/* <!-- Sidebar Menu --> */}
            <nav className='mt-2'>
              <ul
                className='nav nav-pills nav-sidebar flex-column'
                data-widget='treeview'
                role='menu'
                data-accordion='false'
              >
                {/* <!-- Add icons to the links using the .nav-icon className
                       with font-awesome or any other icon font library --> */}
                <li className='nav-item'>
                  <Link
                    to='/app/dashboard'
                    className={`nav-link ${
                      pathname === "/app/dashboard" ? "active" : ""
                    }`}
                  >
                    <i className='nav-icon fa fa-dashboard'></i>
                    <p>&nbsp; Dashboard</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    to='/app/admins'
                    className={`nav-link ${
                      pathname === "/app/admins" ? "active" : ""
                    }`}
                  >
                    <i className='nav-icon fa fa-user'></i>
                    <p>&nbsp; Admins</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    to='/app/sellers'
                    className={`nav-link ${
                      pathname === "/app/sellers" ? "active" : ""
                    }`}
                  >
                    <i className='nav-icon fa fa-user-circle-o'></i>
                    <p>&nbsp; Associate Sellers</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    to='/app/orders'
                    className={`nav-link ${
                      pathname === "/app/orders" ? "active" : ""
                    }`}
                  >
                    <i className='nav-icon fa fa-shopping-cart'></i>
                    <p>&nbsp; Orders</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    to='/app/payments'
                    className={`nav-link ${
                      pathname === "/app/payments" ? "active" : ""
                    }`}
                  >
                    <i className='nav-icon fa fa-history'></i>
                    <p>&nbsp; Payment History</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    to='/app/support'
                    className={`nav-link ${
                      pathname === "/app/support" ? "active" : ""
                    }`}
                  >
                    <i className='nav-icon fa fa-support'></i>
                    <p>&nbsp; Support</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    to='/app/simulator/transaction'
                    className={`nav-link ${
                      pathname === "/app/simulator/transaction" ? "active" : ""
                    }`}
                  >
                    <i className='nav-icon fa fa-calculator'></i>
                    <p>&nbsp; Simulator</p>
                  </Link>
                </li>
                <li className='nav-item'>
                  <Link
                    to='/app/approve'
                    className={`nav-link ${
                      pathname === "/app/approve" ? "active" : ""
                    }`}
                  >
                    <i className='nav-icon fa fa-support'></i>
                    <p>&nbsp; Business Approvals</p>
                  </Link>
                </li>

                <li className='nav-item'>
                  <a
                    style={this.getVerifiedStyle()}
                    className={`nav-link ${
                      pathname === "/app/payout/details" ? "active" : ""
                    }`}
                    onClick={() => {
                      this.setState({ openPayout: !openPayout });
                    }}
                  >
                    &nbsp;<i className='nav-icon fa fa-money'></i>&nbsp;&nbsp;
                    <p>
                      Payout
                      <i className='right fa fa-angle-left'></i>
                    </p>
                  </a>

                  {openPayout && (
                    <ul className='nav '>
                      <li className='nav-item' style={{ width: "100%" }}>
                        <Link
                          to='/app/payouts'
                          className={`nav-link ${
                            pathname === "/app/payouts" ? "active" : ""
                          }`}
                        >
                          <span className='nav-icon'>&nbsp;&nbsp;</span>
                          <p>&nbsp; Payouts</p>
                        </Link>
                      </li>
                      <li className='nav-item' style={{ width: "100%" }}>
                        {/**/}
                        <Link
                          to='/app/refunds'
                          className={`nav-link ${
                            pathname === "/app/refunds" ? "active" : ""
                          }`}
                        >
                          <span className='nav-icon'>&nbsp;&nbsp;</span>
                          <p>&nbsp; Refunds</p>
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
                <li className='nav-item'>
                  <Link
                    to='/app/disputes'
                    className={`nav-link ${
                      pathname === "/app/disputes" ? "active" : ""
                    }`}
                  >
                    <i className='nav-icon fa fa-question'></i>
                    <p>&nbsp; Disputes</p>
                  </Link>
                </li>

                <li className='nav-item'>
                  <Link
                    to='/app/promos'
                    className={`nav-link ${
                      pathname === "/app/promos" ? "active" : ""
                    }`}
                  >
                    <i className='nav-icon fa fa-question'></i>
                    <p>&nbsp; Promo Codes</p>
                  </Link>
                </li>

                <li className='nav-item'>
                  <Link
                    to='/app/referral_benefits'
                    className={`nav-link ${
                      pathname === "/app/referral_benefits" ? "active" : ""
                    }`}
                  >
                    <i className='nav-icon fa fa-question'></i>
                    <p>&nbsp; Referral Benefits</p>
                  </Link>
                </li>

                <li className='nav-item'>
                  <a
                    style={this.getVerifiedStyle()}
                    className={`nav-link ${
                      pathname === "/app/payout/details" ? "active" : ""
                    }`}
                    onClick={() => {
                      this.setState({ openReports: !openReports });
                    }}
                  >
                    &nbsp;<i className='nav-icon fa fa-money'></i>&nbsp;&nbsp;
                    <p>
                      Reports
                      <i className='right fa fa-angle-left'></i>
                    </p>
                  </a>

                  {openReports && (
                    <ul className='nav '>
                      <li className='nav-item' style={{ width: "100%" }}>
                        <Link
                          to='/app/reports/referral-benefits'
                          className={`nav-link ${
                            pathname === "/app/reports/referral-benefits"
                              ? "active"
                              : ""
                          }`}
                        >
                          <span className='nav-icon'>&nbsp;&nbsp;</span>
                          <p>&nbsp; Referral Benefits</p>
                        </Link>
                      </li>
                      <li className='nav-item' style={{ width: "100%" }}>
                        <Link
                          to='/app/reports/revenue-details'
                          className={`nav-link ${
                            pathname === "/app/reports/revenue-details"
                              ? "active"
                              : ""
                          }`}
                        >
                          <span className='nav-icon'>&nbsp;&nbsp;</span>
                          <p>&nbsp; Revenue Details</p>
                        </Link>
                      </li>
                      <li className='nav-item' style={{ width: "100%" }}>
                        <Link
                          to='/app/reports/revenue-summary'
                          className={`nav-link ${
                            pathname === "/app/reports/revenue-summary"
                              ? "active"
                              : ""
                          }`}
                        >
                          <span className='nav-icon'>&nbsp;&nbsp;</span>
                          <p>&nbsp; Revenue Summary</p>
                        </Link>
                      </li>
                      <li className='nav-item' style={{ width: "100%" }}>
                        <Link
                          to='/app/reports/transactions'
                          className={`nav-link ${
                            pathname === "/app/reports/transactions"
                              ? "active"
                              : ""
                          }`}
                        >
                          <span className='nav-icon'>&nbsp;&nbsp;</span>
                          <p>&nbsp; Transactions report</p>
                        </Link>
                      </li>
                      <li className='nav-item' style={{ width: "100%" }}>
                        <Link
                          to='/app/reports/contact-details'
                          className={`nav-link ${
                            pathname === "/app/reports/contact-details"
                              ? "active"
                              : ""
                          }`}
                        >
                          <span className='nav-icon'>&nbsp;&nbsp;</span>
                          <p>&nbsp; Contact Details</p>
                        </Link>
                      </li>
                      <li className='nav-item' style={{ width: "100%" }}>
                        <Link
                          to='/app/reports/user-accounts'
                          className={`nav-link ${
                            pathname === "/app/reports/user-accounts"
                              ? "active"
                              : ""
                          }`}
                        >
                          <span className='nav-icon'>&nbsp;&nbsp;</span>
                          <p>&nbsp; User Accounts</p>
                        </Link>
                      </li>
                      <li className='nav-item' style={{ width: "100%" }}>
                        <Link
                          to='/app/reports/notification-details'
                          className={`nav-link ${
                            pathname === "/app/reports/notification-details"
                              ? "active"
                              : ""
                          }`}
                        >
                          <span className='nav-icon'>&nbsp;&nbsp;</span>
                          <p>&nbsp; Notification Details</p>
                        </Link>
                      </li>
                      <li className='nav-item' style={{ width: "100%" }}>
                        <Link
                          to='/app/reports/notification-cost'
                          className={`nav-link ${
                            pathname === "/app/reports/notification-cost"
                              ? "active"
                              : ""
                          }`}
                        >
                          <span className='nav-icon'>&nbsp;&nbsp;</span>
                          <p>&nbsp; Notification Cost</p>
                        </Link>
                      </li>
                      <li className='nav-item' style={{ width: "100%" }}>
                        <Link
                          to='/app/reports/delivery-terms-settings'
                          className={`nav-link ${
                            pathname === "/app/reports/delivery-terms-settings"
                              ? "active"
                              : ""
                          }`}
                        >
                          <span className='nav-icon'>&nbsp;&nbsp;</span>
                          <p>&nbsp; Delivery Terms</p>
                        </Link>
                      </li>
                      <li className='nav-item' style={{ width: "100%" }}>
                        <Link
                          to='/app/reports/order-details'
                          className={`nav-link ${
                            pathname === "/app/reports/order-details"
                              ? "active"
                              : ""
                          }`}
                        >
                          <span className='nav-icon'>&nbsp;&nbsp;</span>
                          <p>&nbsp; Order Details Report</p>
                        </Link>
                      </li>
                      <li className='nav-item' style={{ width: "100%" }}>
                        <Link
                          to='/app/reports/order-summary'
                          className={`nav-link ${
                            pathname === "/app/reports/order-summary"
                              ? "active"
                              : ""
                          }`}
                        >
                          <span className='nav-icon'>&nbsp;&nbsp;</span>
                          <p>&nbsp; Order Summary</p>
                        </Link>
                      </li>
                    </ul>
                  )}
                </li>
              </ul>
            </nav>
          </div>
        </aside>
      </div>
    );
  }
}

export default SideBar;
