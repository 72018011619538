import React, { Component } from "react";
import Header from "./components/_header";
import SideBar from "./components/_sidebar";
import Main from "./components/Main";
import Footer from "./components/Footer";
import { Switch, Route } from "react-router-dom";
import Login from "./components/_login";
import { connect } from "react-redux";
import Register from "./components/_register";
import ForgotPass from "./components/_forgotPass";
import Identification from "./views/Identification";
import Dashboard from "./components/_dashboard";
import ResetPass from "./components/_resetPass";
import Confirmation from "./components/_confirm";
import BuyerConfirm from "./components/_buyerConfirm";
import POD from "./components/_pod";
import SupportVerification from "./components/SupportVerification";
import PaymentGateway from "./components/PaymentGateway";
import "./App.css";
import { withRouter } from "react-router-dom";
import { alertActions, orderActions, userActions } from "./actions";
import AuthCheck from "./helpers/auth";
import Auth from "./helpers/auths";
import { ClipLoader } from "react-spinners";
import swal from "@sweetalert/with-react";
import { jsx, css } from "@emotion/core";

const override = css`
  display: block;
  margin-top: 10%;
  margin-left: 50%;
  margin-right: 40%;
  border-color: red;
`;

// const override = css`
// display: block;
// margin-top:10%;
// margin-left:50%;
// margin-right:40%;
// border-color: red;
// `;

class App extends Component {
  constructor(props) {
    console.log("loaded app");
    super(props);
    this.state = {
      isAuth: false,
      isLoading: true,
    };
    //
    // this.props.history.listen((location, action) => {
    //     // clear alert on location change
    //     const {dispatch} = this.props;
    //     dispatch(alertActions.alert.clear())
    //
    // });
  }

  // componentDidMount = () => {
  //  this.props.getUser(localStorage.getItem("trust_user_type"),this.props.history);

  // }
  render() {
    const { user } = this.props;
    /* console.log(this.state.isAuth); */
    return (
      <div>
        <Switch>
          {/* <Route exact redirect="/app" path="/" component={Dasboard} /> */}
          <Route path="/login/:type?" component={Auth(Login)} />
          <Route
            path="/mail/:mailType?/:name/:amount/:code"
            component={Login}
          />
          <Route exact path="/forgot/:type" component={ForgotPass} />
          <Route path="/pod/:type?/:orderNo?/:phone?" component={POD} />
          <Route
            path="/seller/business/:idNumber?/:action?"
            component={Identification}
          />
          <Route path="/reset/:type/:email?/:token?" component={ResetPass} />
          <Route
            path="/seller/business/:token/:answer"
            component={SupportVerification}
          />
          <Route
            path="/signup/:type/:email/:phone/:ref?"
            component={Register}
          />
          <Route
            path="/register/:type?/:getstarted?/:email?/:phone?/:subrole?"
            component={Register}
          />

          <Route path="/confirmation/:type/:token" component={Confirmation} />
          <Route exact path="/payment/engine" component={PaymentGateway} />
          <Route
            redirect="/app/dashboard"
            path="/"
            component={() => (
              // (user&&user?
              <div>
                <Header />
                <SideBar />
                <Main />
                <Footer />
              </div>
              // :<div>Loading</div>)
            )}
          />
        </Switch>
      </div>
    );
  }
}

// const mapStateToProps=(state)=>{

//   const {user}= state.users;

//   return{
//    user,
//   }
// }

// const mapDispatchToProps=(dispatch)=>({
//   dispatch,
//  getUser:(type,history)=>dispatch(userActions.user.getDetails(type,history)),
//  getOrders:(type,history)=>dispatch(orderActions.order.view(type,history)),
//  clear:()=> dispatch(alertActions.alert.clear())

// })

export default withRouter(connect()(App));
