import React, {Component} from 'react';
import ReactTable from "react-table";
import * as axios from "axios";
import {BASE_URL} from "../../constants";
import * as moment from "moment";
import {ClipLoader} from "react-spinners";


const formatToSixDigits = (number) => {
  var output = number + '';
  while (output.length < 6) {
    output = '0' + output;
  }
  return output;
}

class NotificationCost extends Component {
  state = {
    data: {
      total_emails: 0,
      total_sms: 0,

    },
    // dataOriginal: [],

    notCustom: true,

    date_to: null,
    date_from: null,

    page: 0,
    pagesCount: 1,
    pageSize: 5,
    search: '',
    oldSearch: '',
  }

  componentDidMount() {

    this.loadReport();
  }

  calcRevenue = d => {
    const amountPaid = d.amount_payed || 0;
    const amountNet = d.amount_net || 0;
    const revenue = ((amountPaid - amountNet) / 100).toLocaleString('en', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

    return revenue;
  }

  loadReport = () => {

    let url = `${BASE_URL}admin/reports/notification-cost?q=1`;

    if (this.state.date_from) {
      url = url + `&date_from=${this.state.date_from.format("YYYY-MM-DD")}`
    }
    if (this.state.date_to) {
      url = url + `&date_to=${this.state.date_to.format("YYYY-MM-DD")}`
    }

    this.setState({loading: true})

    axios({
      method: 'get',
      mode: 'no-cors',
      url,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
       .then(({data: {data}}) => {
         console.log(data);
         this.setState({
           // dataOriginal,
           data,
         })
       })
       .catch(err => {
         console.log(err.response);
       })
       .finally(() => this.setState({loading: false}))

  };


  handleSelect = (e) => {
    const {value} = e.target;
    if (value === "Custom") {
      this.setState({notCustom: false})
    }
    else if (value=== "All"){
      this.setState({
        date_from: null
      }, this.loadReport)
    }
    else {

      this.setState({
        notCustom: true,
        date_from: moment(value),
        period: value,
      }, this.loadReport)
    }

    // this.filterPeriod(value);
    // const period = {period: value}
    /* console.log(value); */
    /* console.log(this.state.period); */
    /* console.log(this.state.data); */
  }

  filterPeriod = (value) => {
    const today = moment();
    let date_from = null;
    let date_to = null;

    if (value === "This Week") {
      date_from = today.startOf('week');
    }

    if (value === "This Month") {
      date_from = today.startOf('month');
    }

    if (value === "60 Days Ago") {

      date_from = today.subtract({months: 2,});
    }

    if (value === "90 Days Ago") {
      date_from = today.subtract({months: 3,});
    }

    if (value !== "Custom") {
      this.setState({date_from, date_to}, () => {
        this.loadReport();
      })
    }
  }

  filterDates = (data) => {
    var ordersBetweenTheTwoDates = [];

    for (var i = 0; i < data.length; i++) {

      var isBetweenDates = moment(data[i].created_at).isBetween(this.state.from_date, this.state.to_date); // true
      /* console.log(isBetweenDates); */
      if (isBetweenDates) {
        ordersBetweenTheTwoDates.push(data[i]);
      }
    }

    this.table_rows = ordersBetweenTheTwoDates;
    //this.notCustom = true;
    this.page = 1;
    //this.to_date = null;
    //this.from_date = null;
    /* console.log(this.table_rows); */
    return this.table_rows;
  }
  // componentDidMount= ()=>{
  //     'i got called';
  //     this.props.view("seller",this.props.history);
  // }
  handleDate = (e) => {
    const {name, value} = e.target;
    const v = moment(value)
    this.setState({[name]: v});
  }


  render() {
    const {loading, data} = this.state;

    return (
       <div className={'content-wrapper'}>

         <div className="content-header">
           <div className="container-fluid">
             <div className="row mb-2">

               <div className="col-sm-12">
                 <h1 className="m-0 text-dark">Notification Cost Report</h1>
               </div>
             </div>
           </div>
         </div>
         <section className="content">
           <div className="container-fluid">
             <div className="row ">
               <div className="col-12 col-sm-12 col-md-12">
                 <div className="alert animated bounceIn alert-danger d-none search-alert">
                   <a aria-label="close" className="close alert-close">×</a>
                 </div>
                 <div className="card">
                   <div className="card-body">
                     <div className="row">
                       <div className="col-12 col-sm-4">
                         <div className="form-group"><label htmlFor="period">Period</label>
                           <select className="form-control" name="filter" onChange={this.handleSelect}>
                             <option value="All">All</option>
                             <option value={moment().startOf("week").format()}>This Week</option>
                             <option value={moment().startOf("month").format()}>This Month</option>
                             <option value={moment().subtract({month: 2}).format()}>60 Days Ago</option>
                             <option value={moment().subtract({month: 3}).format()}>90 Days Ago</option>
                             <option value="Custom">Custom</option>
                           </select></div>
                       </div>

                       <div className="col-12 col-sm-3">
                         <div className="form-group">
                           <label htmlFor="from">Date From</label>
                           <input
                              type="date"
                              name="date_from"
                              value={this.state.date_from ? this.state.date_from.format('YYYY-MM-DD') : null}
                              disabled={this.state.notCustom}
                              onChange={this.handleDate}
                              className="form-control"/>
                         </div>
                       </div>
                       <div className="col-12 col-sm-3">
                         <div className="form-group">
                           <label htmlFor="from">Date To</label>
                           <input
                              type="date"
                              name="date_to"
                              value={this.state.date_to ? this.state.date_to.format('YYYY-MM-DD') : null}
                              disabled={this.state.notCustom}
                              onChange={this.handleDate}
                              className="form-control"/>
                         </div>
                       </div>
                       <div className="col-12 col-sm-2">
                         <div className="form-group"><label>&nbsp;</label>
                           <div className="row">
                             <div className="col-12">
                               <button disabled={this.state.notCustom} onClick={e => {
                                 e.preventDefault();
                                 this.loadReport();
                               }} className="btn btn-info btn-block form-control">
                                 <center>Search</center>
                               </button>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div>
                     <br/>
                     {
                       !loading ?
                          <div>

                            <div className={'row pt-5'}>
                              <div className="col-md-6 text-center">
                                <h4>Total No of Emails Sent</h4>
                                <div className="display-4">{data.total_emails}</div>
                              </div>
                              <div className="col-md-6 text-center">
                                <h4>Total No of SMS Sent</h4>
                                <div className="display-4">{data.total_sms}</div>
                              </div>
                            </div>
                            <div className={'row pt-5'}>
                              <div className="col-md-4 text-center">
                                <h4>Total Email Cost</h4>
                                <div className="display-4">{data.currency == 'USD' ? '$' : '₦'}{data.email_cost}</div>
                              </div>

                              <div className="col-md-4 text-center">
                                <h4>Total SMS Cost</h4>
                                <div className="display-4">{data.currency == 'USD' ? '$' : '₦'}{data.sms_cost}</div>
                              </div>

                              <div className="col-md-4 text-center">
                                <h4>Total Cost</h4>
                                <div className="display-4">{data.currency == 'USD' ? '$' : '₦'}{data.total_cost}</div>
                              </div>
                            </div>

                          </div>
                           :
                           <div style={{
                             padding: 40,
                             textAlign: 'center',
                           }}>
                             <ClipLoader
                                 loading={loading}
                                 size={50}
                             />
                           </div>
                     }
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </section>
       </div>
    );
  }
}

export default NotificationCost;