import React, {Component} from 'react';
import ReactTable from "react-table";
import * as axios from "axios";
import {BASE_URL} from "../../constants";
import * as moment from "moment";


const formatToSixDigits = (number) => {
  var output = number + '';
  while (output.length < 6) {
    output = '0' + output;
  }
  return output;
}

class UserAccountsReport extends Component {
  state = {
    loading: false,

    data: [],
    dataOriginal: [],

    notCustom: true,
  }

  componentDidMount() {

    this.loadReport();
  }

  loadReport = () => {
    this.setState({loading: true})
    axios({
      method: 'get',
      mode: 'no-cors',
      url: `${BASE_URL}admin/reports/user-accounts`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
       .then(({data: {data}}) => {

         this.setState({
           dataOriginal: data.map(d => {

             const amountPaid = d.amount_payed || 0;
             const amountNet = d.amount_net || 0;
             const revenue = ((amountPaid - amountNet) / 100).toLocaleString('en', {
               minimumFractionDigits: 2,
               maximumFractionDigits: 2
             });

             return {...d, revenue}
           })
         }, () => this.setState({data: this.state.dataOriginal}))
       })
       .catch(err => {
         console.log(err.response);
       })
       .finally(() => this.setState({loading: false}))

  };


  // handleSelect = (e) => {
  //   const {value} = e.target;
  //   if (value === "Custom") {
  //     this.setState({notCustom: false})
  //   }
  //   this.filterPeriod(value);
  //   // const period = {period: value}
  //   this.setState({period: value});
  //   /* console.log(value); */
  //   /* console.log(this.state.period); */
  //   /* console.log(this.state.data); */
  // }
  //
  // filterPeriod = (value) => {
  //   const data = this.state.dataOriginal;
  //   /* console.log(data); */
  //
  //   if (value === "All") {
  //     /* console.log(data); */
  //     this.table_rows = data;
  //     this.notCustom = true;
  //     this.page = 1;
  //     this.to_date = null;
  //     this.from_date = null;
  //     this.setState({notCustom: true, data: [...this.state.dataOriginal]});
  //   }
  //
  //   if (value === "This Week") {
  //     var ordersThisWeek = [];
  //
  //     for (var i = 0; i < data.length; i++) {
  //       var now = moment();
  //       var input = moment(data[i].created_at);
  //       var isThisWeek = (now.isoWeek() === input.isoWeek())
  //
  //       if (isThisWeek) {
  //         ordersThisWeek.push(data[i]);
  //       }
  //     }
  //
  //     this.table_rows = ordersThisWeek;
  //     this.notCustom = true;
  //     this.page = 1;
  //     this.to_date = null;
  //     this.from_date = null;
  //     /* console.log(this.table_rows); */
  //     this.setState({notCustom: true, data: [...this.table_rows]});
  //   }
  //
  //   if (value === "This Month") {
  //     var ordersThisMonth = [];
  //
  //     for (let i = 0; i < data.length; i++) {
  //       //var now = moment();
  //       let input = moment(data[i].created_at);
  //       var isThisMonth = input.isSame(new Date(), 'month');
  //
  //       if (isThisMonth) {
  //         ordersThisMonth.push(data[i]);
  //       }
  //     }
  //
  //     this.table_rows = ordersThisMonth;
  //     this.notCustom = true;
  //     this.page = 1;
  //     this.to_date = null;
  //     this.from_date = null;
  //     this.setState({notCustom: true, data: [...this.table_rows]});
  //   }
  //
  //   if (value === "60 Days Ago") {
  //     var ordersSixtyDaysAgo = [];
  //
  //     for (let i = 0; i < data.length; i++) {
  //
  //       var sixty_days_ago = moment().subtract(60, 'days');
  //
  //       var isBetweenSixtyDays = moment(data[i].created_at).isAfter(sixty_days_ago);
  //
  //       if (isBetweenSixtyDays) {
  //         ordersSixtyDaysAgo.push(data[i]);
  //       }
  //     }
  //
  //     this.table_rows = ordersSixtyDaysAgo;
  //     this.notCustom = true;
  //     this.page = 1;
  //     this.to_date = null;
  //     this.from_date = null;
  //     this.setState({notCustom: true, data: [...this.table_rows]});
  //   }
  //
  //   if (value === "90 Days Ago") {
  //     var ordersNinetyDaysAgo = [];
  //
  //     for (let i = 0; i < data.length; i++) {
  //
  //       var ninety_days_ago = moment().subtract(90, 'days');
  //
  //       var isBetweenNinetyDays = moment(data[i].created_at).isAfter(ninety_days_ago);
  //
  //       if (isBetweenNinetyDays) {
  //         ordersNinetyDaysAgo.push(data[i]);
  //       }
  //     }
  //
  //     this.table_rows = ordersNinetyDaysAgo;
  //     this.notCustom = true;
  //     this.page = 1;
  //     this.to_date = null;
  //     this.from_date = null;
  //     this.setState({notCustom: true, data: [...this.table_rows]});
  //   }
  //
  //   if (value === "Custom") {
  //     this.table_rows = data;
  //     this.to_date = null;
  //     this.from_date = null;
  //
  //   }
  // }
  //
  // filterDates = (data) => {
  //   var ordersBetweenTheTwoDates = [];
  //
  //   for (var i = 0; i < data.length; i++) {
  //
  //     var isBetweenDates = moment(data[i].created_at).isBetween(this.state.from_date, this.state.to_date); // true
  //     /* console.log(isBetweenDates); */
  //     if (isBetweenDates) {
  //       ordersBetweenTheTwoDates.push(data[i]);
  //     }
  //   }
  //
  //   this.table_rows = ordersBetweenTheTwoDates;
  //   //this.notCustom = true;
  //   this.page = 1;
  //   //this.to_date = null;
  //   //this.from_date = null;
  //   /* console.log(this.table_rows); */
  //   return this.table_rows;
  // }
  // // componentDidMount= ()=>{
  // //     'i got called';
  // //     this.props.view("seller",this.props.history);
  // // }
  // handleDate = (e) => {
  //   const {name, value} = e.target;
  //   this.setState({[name]: value});
  //
  // }


  handleFilter = e => {
    const {target: {value}} = e;
    const {dataOriginal: data} = this.state;

    const includes = property => property ? property.toString().toLowerCase().includes(value.toLowerCase()) : false

    if (value === "" || value === null || value === undefined) {
      this.setState({data: this.state.dataOriginal});
      return;
    }


    const filtered = [];

    for (var i = 0; i < data.length; i++) {
      const {
        name,
        contact,
        last_login,
        last_transaction,
        total_revenue,
        total_transactions,
      } = data[i];
      if (
         includes(name) ||
         includes(contact) ||
         includes(last_login) ||
         includes(last_transaction) ||
         includes(total_revenue) ||
         includes(total_transactions)
      ) {
        filtered.push(data[i]);
      }
    }

    this.setState({data: filtered})
  }

  render() {

    const columns = [
      {
        Header: props => <span><b>Contact</b></span>,
        id: "name",
        filterable: false,
        accessor: d => d.name,
        Cell: ({original: props}) => {
          return <span>{props.name || "N/A"}</span>;
        }
      },
      {
        Header: props => <span><b>Contact</b></span>,
        id: "contact",
        filterable: false,
        accessor: d => d.contact,
        Cell: ({original: props}) => {
          return <span>{props.contact || "N/A"}</span>;
        }
      },
      {
        Header: props => <span><b>Last Date/Time Logged In</b></span>,
        id: "last_login",
        filterable: false,
        accessor: d => d.last_login,
        Cell: ({original: props}) => {
          return <span>{props.last_login || "N/A"}</span>;
        }
      },
      {
        Header: props => <span><b>Last Date/Time AtaraPay Earned from User</b></span>,
        id: "last_transaction",
        filterable: false,
        accessor: d => d.last_transaction,
        Cell: ({original: props}) => {
          return <span>{props.last_transaction || "N/A"}</span>;
        }
      },
      {
        Header: props => <span><b>Total Escrow Revenue</b></span>,
        id: "total_revenue",
        filterable: false,
        accessor: d => d.total_revenue,
        Cell: ({original: props}) => {
          return <span>₦ {props.total_revenue ? props.total_revenue.toLocaleString('en', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }) : "0.00"}</span>;
        }
      },
      {
        Header: props => <span><b>Total No. of Transactions</b></span>,
        id: "total_transactions",
        filterable: false,
        accessor: d => d.total_transactions,
        Cell: ({original: props}) => {
          return <span>{props.total_transactions}</span>;
        }
      },
    ];
    return (
       <div className={'content-wrapper'}>

         <div className="content-header">
           <div className="container-fluid">
             <div className="row mb-2">

               <div className="col-sm-12">
                 <h1 className="m-0 text-dark">User Accounts</h1><br/>
               </div>
             </div>
           </div>
         </div>
         <section className="content">
           <div className="container-fluid">
             <div className="row ">
               <div className="col-12 col-sm-12 col-md-12">
                 <div className="alert animated bounceIn alert-danger d-none search-alert">
                   <a aria-label="close" className="close alert-close">×</a>
                 </div>
                 <div className="card">
                   <div className="card-body">
                     <div className="row">
                       <div className="col-12 col-md-4">
                         <div className="form-group"><label htmlFor="filters">Filter</label>
                           <input type="text"
                                  name={'filters'}
                                  className={'form-control'}
                                  onChange={this.handleFilter}
                           />
                         </div>
                       </div>
                       {/*<div className="col-12 col-sm-3">*/}
                       {/*  <div className="form-group">*/}
                       {/*    <label htmlFor="from">Date From</label>*/}
                       {/*    <input*/}
                       {/*       type="date"*/}
                       {/*       name="from_date"*/}
                       {/*       value={this.state.from_date}*/}
                       {/*       disabled={this.state.notCustom}*/}
                       {/*       onChange={this.handleDate}*/}
                       {/*       className="form-control"/>*/}
                       {/*  </div>*/}
                       {/*</div>*/}
                       {/*<div className="col-12 col-sm-3">*/}
                       {/*  <div className="form-group">*/}
                       {/*    <label htmlFor="from">Date To</label>*/}
                       {/*    <input*/}
                       {/*       type="date"*/}
                       {/*       name="to_date"*/}
                       {/*       value={this.state.to_date}*/}
                       {/*       disabled={this.state.notCustom}*/}
                       {/*       onChange={this.handleDate}*/}
                       {/*       className="form-control"/>*/}
                       {/*  </div>*/}
                       {/*</div>*/}
                       <div className="col-12 col-sm-2"/>
                     </div>
                     <br/>
                     <div className="table-responsive">
                       <ReactTable
                          loading={this.state.loading}
                          defaultPageSize={5}
                          filterable
                          data={this.state.data}
                          columns={columns}/>

                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </section>
       </div>
    );
  }
}

export default UserAccountsReport;