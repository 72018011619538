import React , {Component} from 'react';
import termSchema from '../validation/terms';
import { alertActions } from '../actions'
import {Link} from 'react-router-dom';

const initialState={
	email: '',
	password: "",
	password_confirmation: "",
	token: "",
	 submitted: false
};

class RecoverPass extends Component{

	constructor(props) {
        super(props);
        this.state = {
			email: '',
			password: "",
			password_confirmation: "",
			token: "",
			 submitted: false
        };
    
	}
	
	handleChange=(e)=>{
    
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    hideAlert=()=>{
        const { dispatch } = this.props;
        dispatch(alertActions.alert.clear());
    }

    handleSubmit=(e)=>{
		e.preventDefault();
        this.setState({ submitted: true });
        const { email,password,
			password_confirmation } = this.state;
		const data = 
		{
			"email": this.props.match.params.email,
			"password":password,
			"password_confirmation": password_confirmation,
			"token": this.props.match.params.token
		}
		const type = this.props.match.params.type;
        const { dispatch } = this.props;
        if (password, password_confirmation) {
           this.props.resetPass(type,data,this.props.history)
        }
    }
   render(){
	const {resetting,alert } = this.props;
    const {email,password,
		password_confirmation} = this.state;
       return(
		   <div className="background-gradient" style={{ height: '100vh' }}>
			   <nav class="navbar navbar-expand-lg fixed-top navbar-inverse">
				   <a class="navbar-brand" href="https://www.atarapay.com">
					   <img src="/dist/img/logo-header.png" alt="" className="logo-img img img-responsive" />
				   </a>
				   <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
					   <span class="navbar-toggler-icon"></span>
				   </button>
			   </nav>

			   <div class="row row-margin top-margin-60">
				   <div className="col-sm-3 col-md-3"></div>
				   <div className="col-sm-8 col-md-6 col-12">
					   <div className="card card-margin">

			  		<br />
	      			<h4><strong>Change Password</strong></h4>
					<br />
                    <form onSubmit={this.handleSubmit} >
					{alert&&alert.message&&<div class={`alert animated bounceIn buyer-alert ${alert.type}`}><a  aria-label="close" onClick={this.hideAlert} class="close alert-close">×</a>
                                 <span  class="alert-message-content">{alert.message}</span></div>}

					<div class="form-group">
						<label for="password"><small>Please enter your new password*</small></label>

						<input type="password" name="password" value={password} onChange={this.handleChange} v-model="password" id="password" class="form-control" required="required" />
					</div>

					<div class="form-group">
						<label for="password_confirmation"><small>Please confirm your new password*</small></label>

						<input type="password" name="password_confirmation" id="password_confirmation" value={password_confirmation} id="password_confirmation" onChange={this.handleChange}  v-model="password_confirmation" id="password_confirmation" class="form-control" required="required" />
					</div>
					
					<div class="form-group">
						<input type="submit" name="button" value="Change Password" class="btn btn-dark-blue form-control"  />
					</div>
					<hr/>
                        <p   class="text-center"> Log in as <Link to="/login/buyer" class="">Buyer</Link> or <Link to="/login/seller" class="">Seller</Link> </p>
					</form>

				</div>
			</div>
			<div class="col-sm-2 col-md-2"></div>
		</div>

	</div>
    
       );
   }
}

export default RecoverPass;