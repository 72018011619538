import React, {Component} from 'react';
import termSchema from '../validation/terms';
import {termActions, alertActions} from '../actions'
import {Link} from 'react-router-dom';
import Modal from 'react-responsive-modal';
import swal from '@sweetalert/with-react'
import * as axios from "axios";
import {BASE_URL} from "../constants";
import {connect} from "react-redux";


class Profile extends Component {

  state = {
    dashboard: [],
    admins: [],
    processing: false,
    addAdmin: false,
    editAdmin: false,
    editAdminInfo: {
      id: 0,
      name: '',
      email: '',
    },
    deleteAdminId: false,
  }

  constructor(props) {
    super(props);
  }

  handleEditAdmin = e => {
    e.preventDefault();

    const name = this.editAdminName.value;
    const email = this.editAdminEmail.value;
    const password = this.editAdminPass.value;
    const passconf = this.editAdminPassConf.value;

    if (password !== passconf) {
      swal({
        title: 'Password don\'t match',
        text: "Passwords don't match",
      })

      return;
    }
    this.setState({
      processing: true,
    })

    const data = {
      name,
      email,
      password,
    }

    axios({

      method: 'post',
      mode: 'no-cors',
      url: `${BASE_URL}admin/edit/${this.props.user.id}`,
      data,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
       .then(({data}) => {
         console.log(data);

         if (data.status === 'success') {
           this.setState({
             editAdmin: false,
           })

           swal({
             title: 'Saved',
             text: 'Your information has been successfully saved.',
           })
         }

       })
       .catch(err => {

       })
       .finally(() => {

         this.setState({
           processing: false,
         })

       })
  }


  render() {

    const {user} = this.props;
    return (
       <div>
         <div class="content-wrapper">

           <div class="content-header">
             <div class="container-fluid">
               <div class="row mb-2">
                 <div class="col-sm-12">
                   <h1 class="m-0 text-dark">Update your account</h1>
                 </div>
               </div>
             </div>
           </div>

           <section class="content">
             <div class="container-fluid">

               <button type="button" class="btn btn-primary" onClick={() => {
                 this.onOpenModal()
               }}>
                 <i class="fa fa-lock"></i>&nbsp;&nbsp;&nbsp;Update Password
               </button>
               <br/><br/>
               <div class="row">
                 <div class="col-12 col-sm-12 col-md-12">
                   <div class="alert update-alert alert-danger d-none">
                     <a class="close alert-close pointer" aria-label="close" onClick={this.hideAlert}>&times;</a>
                     <span class="update-alert-message-content"></span>
                   </div>
                   <div class="callout callout-info">
                     {
                       user &&
                       <form action="">
                         <div className="form-group">
                           <label htmlFor="">Name</label>
                           <input ref={ref => this.editAdminName = ref} type="text"
                                  defaultValue={user.name} className={'form-control'}/>
                         </div>
                         <div className="form-group">
                           <label htmlFor="">Email</label>
                           <input ref={ref => this.editAdminEmail = ref} type="email"
                                  defaultValue={user.email} className={'form-control'}/>
                         </div>
                         <div className="form-group">
                           <label htmlFor="">Password</label>
                           <input ref={ref => this.editAdminPass = ref} type="password" className={'form-control'}/>
                         </div>
                         <div className="form-group">
                           <label htmlFor="">Confirm Password</label>
                           <input ref={ref => this.editAdminPassConf = ref} type="password" className={'form-control'}/>
                         </div>
                         <button onClick={this.handleEditAdmin} disabled={this.state.processing} type="button"
                                 className="btn btn-primary">{this.state.processing ? 'Editing..' : 'Edit Admin'}</button>
                       </form>
                     }
                   </div>

                 </div>
               </div>
               <br/>

             </div>
           </section>

         </div>
       </div>
    )
  }
}

const mapStateToProps = state => {
  const {user} = state.users;
  return {
    user
  };
}

export default connect(mapStateToProps)(Profile);
