const convertNairaPropsToKoboProps = (props) => {
  if (props && !Array.isArray(props) && typeof props === "object") {
    const newProps = { ...props };
    const {
      sellerPhone,
      refundOption,
      txType,
      cardFeePercent,
      escrowPercent,
      logisticsFee,
      sellerCommision,
      ...rest
    } = newProps;
    const keys = Object.keys(rest);
    keys.forEach((key) => {
      newProps[key] = getNumberValueInKobo(props[key]);
    });

    newProps.cardFeePercent = getNumberValue(cardFeePercent);
    newProps.escrowPercent = getNumberValue(escrowPercent);
    newProps.logisticsFee = getNumberValue(logisticsFee);
    newProps.sellerCommision = getNumberValue(sellerCommision);
    newProps.txType = getNumberValue(txType);

    return newProps;
  } else {
    return props;
  }
};

/**
 * getNumberValue - Returns only number value, if value passed is not type number returns same value
 */
const getNumberValue = (value) => {
  if (!isNaN(value)) {
    return parseFloat(value);
  }
  return value;
};

/**
 * getNumberValue - Returns only number value, if value passed is not type number returns same value
 */
const getNumberValueInKobo = (value) => {
  if (!isNaN(value)) {
    return getNumberValue(value) * 100;
  }
  return value;
};

export default convertNairaPropsToKoboProps;
