import { BASE_URL, LOCALSTORAGE_TOKEN_KEY } from '../constants';
import axios from 'axios';
import { settingService } from '.';
import { settingActions } from '../actions';


 const setRefundable=(setting)=>{
   return  axios({
        method: 'post',
        mode: 'no-cors',
        url : `${BASE_URL}seller/term/refundableFlag`,
        data: setting,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization':'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        }
    })

}

const setPayment = (setting) => {
    return axios({
        method: 'post',
        mode: 'no-cors',
        url: `${BASE_URL}seller/term/paymentFrequency`,
        data: setting,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        }
    })

}

const setCardFee = (setting) => {
    return axios({
        method: 'post',
        mode: 'no-cors',
        url: `${BASE_URL}seller/term/setCardFee`,
        data: setting,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        }
    })
}

const setCallbackUrl = (setting) => {
    return axios({
        method: 'post',
        mode: 'no-cors',
        url: `${BASE_URL}seller/term/setCallbackUrl`,
        data: setting,
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': 'Bearer ' + localStorage.getItem(LOCALSTORAGE_TOKEN_KEY),
            'Content-Type': 'application/json',
        }
    })
}
export const settings = {
    setRefundable,
    setPayment,
    setCardFee,
    setCallbackUrl
};


