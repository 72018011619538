import React, { Component } from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";

import {
  userActions,
  orderActions,
  payoutActions,
  logisticActions,
} from "../actions";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import Transactions from "./_transactions";
import SingleTransaction from "../components/_single_transaction";
import ForgotPass from "./../views/forgotPassword";
import Business from "./../components/_business";
import Help from "./../views/transaction/Help";

import BusinessApprovals from "../views/BusinessApprovals";
import Authorization from "../helpers/Authorization";

import swal from "@sweetalert/with-react";
import Admins from "../components/_admins";
import Sellers from "../views/Sellers";
import Profile from "../views/profile";
import Dashboard from "../views/Dashboard";
import Order from "./_order";

import PayOutHistory from "./_payouts";
import RefundHistory from "./_refunds";
import Disputes from "./_disputes";
import Payments from "./_payments";
import Support from "./_support";
import RevenueDetails from "../views/reports/RevenueDetails";
import RevenueSummary from "../views/reports/RevenueSummary";
import TransactionsReport from "../views/reports/TransactionsReport";
import ContactDetailsReport from "../views/reports/ContactDetailsReport";
import UserAccountsReport from "../views/reports/UserAccountsReport";
import NotificationDetails from "../views/reports/NotificationDetails";
import NotificationCost from "../views/reports/NotificationCost";
import DeliveryTerms from "../views/reports/DeliveryTerms";
import OrderDetails from "../views/reports/OrderDetails";
import OrderSummary from "../views/reports/OrderSummary";
import PromoCodes from "../views/PromoCodes";
import ReferralBenefits from "../views/ReferralBenefits";
import ReferralBenefitsReport from "../views/reports/ReferralBenefitsReport";
import TransactionCalculatorSimulator from "../views/TransactionCalculatorSimulator";

class Main extends Component {
  componentDidMount() {
    if (!localStorage.getItem("user")) {
      this.props.history.push("/login");
    } else {
      this.props.getUser(this.props.history);
    }
  }
  render() {
    return (
      <Route>
        <Switch>
          <Route
            exact
            redirect='/app/dashboard'
            path='/'
            render={() => <Redirect to={"/app/dashboard"} />}
          />
          <Route
            exact
            path='/app/dashboard'
            component={Authorization(Dashboard)}
          />
          <Route
            exact
            redirect='/app/sellers'
            path='/app/sellers'
            component={Authorization(Sellers)}
          />
          <Route
            exact
            redirect='/app/admins'
            path='/app/admins'
            component={Authorization(Admins)}
          />
          <Route
            exact
            redirect='/app/orders'
            path='/app/orders'
            component={Authorization(Order)}
          />
          <Route exact path='/app/payouts' component={PayOutHistory} />
          <Route exact path='/app/refunds' component={RefundHistory} />
          <Route exact path='/app/disputes' component={Disputes} />
          <Route exact path='/app/payments' component={Payments} />
          <Route exact path='/app/support' component={Support} />
          <Route exact path='/app/approve' component={BusinessApprovals} />

          <Route
            exact
            path='/app/reports/revenue-details'
            component={RevenueDetails}
          />
          <Route
            exact
            path='/app/reports/revenue-summary'
            component={RevenueSummary}
          />
          <Route
            exact
            path='/app/reports/transactions'
            component={TransactionsReport}
          />
          <Route
            exact
            path='/app/reports/contact-details'
            component={ContactDetailsReport}
          />
          <Route
            exact
            path='/app/reports/user-accounts'
            component={UserAccountsReport}
          />
          <Route
            exact
            path='/app/reports/notification-details'
            component={NotificationDetails}
          />
          <Route
            exact
            path='/app/reports/notification-cost'
            component={NotificationCost}
          />
          <Route
            exact
            path='/app/reports/delivery-terms-settings'
            component={DeliveryTerms}
          />
          <Route
            exact
            path='/app/reports/order-details'
            component={OrderDetails}
          />
          <Route
            exact
            path='/app/reports/order-summary'
            component={OrderSummary}
          />
          <Route
            exact
            path='/app/reports/referral-benefits'
            component={ReferralBenefitsReport}
          />

          <Route exact path='/app/promos' component={PromoCodes} />
          <Route
            exact
            path='/app/referral_benefits'
            component={ReferralBenefits}
          />

          <Route exact path='/app/profile' component={Profile} />

          <Route
            exact
            path='/app/simulator/transaction/'
            component={Authorization(TransactionCalculatorSimulator)}
          />
        </Switch>
      </Route>
    );
  }
}

const mapStateToProps = (state) => {
  // const { loading,success} = state.support;
  const { user } = state.users;
  // const alert  = state.alert;
  return {
    user,
  };
};
const mapDispatchToProps = (dispatch) => ({
  viewCouriers: (history) =>
    dispatch(logisticActions.delivery.viewCouriers(history)),
  viewKey: (type, history) => dispatch(userActions.user.viewKey(type, history)),
  viewDisputes: (type, history) =>
    dispatch(orderActions.order.viewDisputes(type, history)),
  getUser: (type, history) =>
    dispatch(userActions.user.getDetails(type, history)),
  viewPayout: (type, history) =>
    dispatch(payoutActions.payout.view(type, history)),
  viewPayouts: (type, history) =>
    dispatch(payoutActions.payout.all(type, history)),
  viewPayments: (type, history) =>
    dispatch(orderActions.order.viewPayments(type, history)),
  viewDashboard: (type, history) =>
    dispatch(userActions.user.viewDashboard(type, history)),
  getOrders: (type, history) =>
    dispatch(orderActions.order.view(type, history)),
  getDispatchers: (type, history) =>
    dispatch(userActions.user.getDispatchers(type, history)),
  //  clear:()=> dispatch(alertActions.alert.clear())
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
