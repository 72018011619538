import React from "react";
import swal from "@sweetalert/with-react";

import { payoutService } from "../service.js";
import { payoutConstants } from "../constants";
import { alertActions, userActions } from "./";
const create = (details, type, history) => {
  return (dispatch) => {
    dispatch(request(details));
    payoutService.payout
      .create(details, type)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(success(res.data.data));
          dispatch(
            alertActions.alert.success(
              "Bank account details successfully added.".replace(/_/g, " ")
            )
          );
          //  history.push('/app/payout/details');
        } else {
          dispatch(failure(res.data.message));
          dispatch(
            alertActions.alert.error(res.data.message.replace(/_/g, " "))
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            dispatch(userActions.user.logout(type, history));
            // history.push('/login');
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message.replace(/_/g, " ")
              )
            );
          }
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message.replace(/_/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message.replace(/_/g, " ")));
        }

        console.log(err.config);
      });
  };
  function request(details) {
    return { type: payoutConstants.payout.PAYOUT_DETAIL_ADD_REQUEST, details };
  }
  function success(account) {
    return { type: payoutConstants.payout.PAYOUT_DETAIL_ADD_SUCCESS, account };
  }
  function failure(error) {
    return { type: payoutConstants.payout.PAYOUT_DETAIL_ADD_FAILURE, error };
  }
};

const managePhotoId = (id, action, history) => {
  return (dispatch) => {
    // dispatch(request(details));
    payoutService.payout
      .managePhotoId(id, action)
      .then((res) => {
        // return console.log("result", res);
        if (res.data.status === "success") {
          // dispatch(success(res.data.data));
          swal({
            title: "Success",
            content: (
              <div>
                photoId {action === "decline" ? "declined" : "approved"}{" "}
                sucessful
              </div>
            ),
            icon: "success",
          }).then(() => {
            history.push("/");
          });
          // dispatch(
          //   alertActions.alert.success(
          //     `photoId ${
          //       action === "decline" ? "declined" : "approved"
          //     } sucessful`
          //   )
          // );
          // history.push("/app/payout/details");
        } else {
          // dispatch(failure(res.data.message));
          swal({
            title: "Error",
            content: (
              <div>
                photoId {action === "decline" ? "decline" : "approval"} failed
              </div>
            ),
            icon: "error",
          }).then(() => {
            history.push("/");
          });
          // dispatch(
          //   alertActions.alert.error(
          //     `photoId ${action === "decline" ? "decline" : "approval"} failed`
          //   )
          // );
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            dispatch(userActions.user.logout("seller", history));
            // history.push('/login');
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message?.replace(/_/g, " ")
              )
            );
          }
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message?.replace(/_/g, " ")));
        }

        console.log(err.config);
      });
  };
  function request(details) {
    return { type: payoutConstants.payout.MANAGE_PHOTO_ID_REQUEST, details };
  }
  function success(account) {
    return { type: payoutConstants.payout.MANAGE_PHOTO_ID_SUCCESS, account };
  }
  function failure(error) {
    return { type: payoutConstants.payout.MANAGE_PHOTO_ID_FAILURE, error };
  }
};

const all = (type, history) => {
  return (dispatch) => {
    dispatch(request());
    payoutService.payout
      .all(type)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(success(res.data.data));
        } else {
          dispatch(failure(res.data.message));
          // dispatch(alertActions.alert.error((res.data.message).replace(/_/g, " ")));
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            dispatch(userActions.user.logout(history));
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message.replace(/_/g, " ")
              )
            );
          }
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message.replace(/_/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message.replace(/_/g, " ")));
        }

        console.log(err.config);
      });
  };
  function request() {
    return { type: payoutConstants.payout.ALL_PAYOUTS_REQUEST };
  }
  function success(payouts) {
    return { type: payoutConstants.payout.ALL_PAYOUTS_SUCCESS, payouts };
  }
  function failure(error) {
    return { type: payoutConstants.payout.ALL_PAYOUTS_FAILURE, error };
  }
};

const view = (type, history) => {
  return (dispatch) => {
    dispatch(request());
    payoutService.payout
      .get(type)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(success(res.data.data));
        } else {
          dispatch(failure(res.data.message));
          // dispatch(alertActions.alert.error((res.data.message).replace(/_/g, " ")));
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status == 401) {
            dispatch(userActions.user.logout(history));
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message.replace(/_/g, " ")
              )
            );
          }
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message.replace(/_/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message.replace(/_/g, " ")));
        }

        console.log(err.config);
      });
  };
  function request() {
    return { type: payoutConstants.payout.PAYOUT_DETAILS_REQUEST };
  }
  function success(details) {
    return { type: payoutConstants.payout.PAYOUT_DETAILS_SUCCESS, details };
  }
  function failure(error) {
    return { type: payoutConstants.payout.PAYOUT_DETAILS_FAILURE, error };
  }
};

const edit = (data, type, history) => {
  return (dispatch) => {
    dispatch(request());
    payoutService.payout
      .edit(data, type)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(success(res.data.data));
          dispatch(
            alertActions.alert.success(
              "Account type has been successfully updated".replace(/_/g, " ")
            )
          );
          history.push("/app/payout/details");
        } else {
          dispatch(failure(res.data.message));
          dispatch(
            alertActions.alert.error(res.data.message.replace(/_/g, " "))
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            dispatch(userActions.user.logout(type, history));
            // history.push('/login');
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message.replace(/_/g, " ")
              )
            );
          }
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message.replace(/_/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message.replace(/_/g, " ")));
        }

        console.log(err.config);
      });
  };
  function request() {
    return { type: payoutConstants.payout.PAYOUT_DETAIL_EDIT_REQUEST };
  }
  function success(account) {
    return { type: payoutConstants.payout.PAYOUT_DETAIL_EDIT_SUCCESS, account };
  }
  function failure(error) {
    return { type: payoutConstants.payout.PAYOUT_DETAIL_EDIT_FAILURE, error };
  }
};

const setDefault = (id, type, history) => {
  return (dispatch) => {
    dispatch(request());
    payoutService.payout
      .setDefault(id, type)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(success(res.data.data));
          dispatch(
            alertActions.alert.success(
              "Bank account is successfully set as Default".replace(/_/g, " ")
            )
          );
          history.push("/app/payout/details");
        } else {
          dispatch(failure(res.data.message));
          dispatch(
            alertActions.alert.error(res.data.message.replace(/_/g, " "))
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            dispatch(userActions.user.logout(type, history));
            // history.push('/login');
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message.replace(/_/g, " ")
              )
            );
          }
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message.replace(/_/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message.replace(/_/g, " ")));
        }

        console.log(err.config);
      });
  };
  function request() {
    return { type: payoutConstants.payout.PAYOUT_DETAIL_SET_DEFAULT_REQUEST };
  }
  function success(account) {
    return {
      type: payoutConstants.payout.PAYOUT_DETAIL_SET_DEFAULT_SUCCESS,
      account,
    };
  }
  function failure(error) {
    return {
      type: payoutConstants.payout.PAYOUT_DETAIL_SET_DEFAULT_FAILURE,
      error,
    };
  }
};

const delDetail = (id, type, history) => {
  return (dispatch) => {
    dispatch(request());
    payoutService.payout
      .delDetail(id, type)
      .then((res) => {
        if (res.data.status === "success") {
          dispatch(success(res.data.data));
          dispatch(
            alertActions.alert.success(
              "Bank account details deleted successfully".replace(/_/g, " ")
            )
          );
          //  history.push('/app/payout/details');
        } else {
          dispatch(failure(res.data.message));
          dispatch(
            alertActions.alert.error(res.data.message.replace(/_/g, " "))
          );
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            dispatch(userActions.user.logout(type, history));
            // history.push('/login');
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message.replace(/_/g, " ")
              )
            );
          } else {
            dispatch(failure(err.response.data.message));
            dispatch(
              alertActions.alert.error(
                err.response.data.message.replace(/_/g, " ")
              )
            );
          }
        } else if (err.request) {
          console.log(err.request);
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message.replace(/_/g, " ")));
        } else {
          console.log(err.message);
          dispatch(failure(err.message));
          dispatch(alertActions.alert.error(err.message.replace(/_/g, " ")));
        }

        // console.log(err.config)
      });
  };
  function request() {
    return { type: payoutConstants.payout.PAYOUT_DETAIL_DELETE_REQUEST };
  }
  function success(accounts) {
    return {
      type: payoutConstants.payout.PAYOUT_DETAIL_DELETE_SUCCESS,
      accounts,
    };
  }
  function failure(error) {
    return { type: payoutConstants.payout.PAYOUT_DETAIL_DELETE_FAILURE, error };
  }
};
export const payout = {
  create,
  all,
  view,
  edit,
  setDefault,
  delDetail,
  managePhotoId,
};
