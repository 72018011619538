import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import Payment from '../views/paymentHistory';
import { orderActions } from '../actions';
// import { payoutActions } from '../actions';

const mapStateToProps = (state) =>{
    const { loading,payments} = state.orders;
    

    const alert  = state.alert;
    
    return {
        alert,
        loading,
        payments,
    };
     
     
 }
 const mapDispatchToProps = (dispatch) => ({
   
     viewPayments:(type,history)=>dispatch(orderActions.order.viewPayments(type,history)),
    dispatch
}
)
 

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Payment));