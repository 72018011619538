import React, {Component} from 'react';
import ReactTable from "react-table";
import * as axios from "axios";
import {BASE_URL} from "../../constants";
import * as moment from "moment";


const formatToSixDigits = (number) => {
  var output = number + '';
  while (output.length < 6) {
    output = '0' + output;
  }
  return output;
}

class OrderSummary extends Component {
  state = {
    loading: false,

    data: [],
    dataOriginal: [],

    notCustom: true,

    date_to: null,
    date_from: null,
  }

  componentDidMount() {

    this.loadReport();
  }

  loadReport = () => {

    let url = `${BASE_URL}admin/reports/revenue-summary?q=1`;

    if(this.state.date_from){
      url = url + `&date_from=${this.state.date_from.format("YYYY-MM-DD")}`
    }
    if(this.state.date_to){
      url = url + `&date_to=${this.state.date_to.format("YYYY-MM-DD")}`
    }

    this.setState({loading: true})

    axios({
      method: 'get',
      mode: 'no-cors',
      url,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
       .then(({data: {data}}) => {
         const dataOriginal = [];

         console.log(data.p2p, 'p2p')

         const append = (dt, type, gateway) => {
           dt.forEach(d => {
             dataOriginal.push({
               amount_net: d.amount_net,
               amount_paid: d.amount_payed,
               // revenue: this.calcRevenue(d),
               revenue: parseFloat(d.escrow_fee),
               gateway_name: gateway || d.gateway_name,
               total: d.total,
               type: type,
             })
           })
         }

         append(data.p2p, 2)
         append(data.eCommerce, 1)
         append(data.ataraNg, 4)
         append(data.all, -1, 'All')

         this.setState({
           dataOriginal,
           data: dataOriginal,
         })
       })
       .catch(err => {
         console.log(err.response);
       })
       .finally(() => this.setState({loading: false}))

  };


  handleSelect = (e) => {
    const {value} = e.target;
    if (value === "Custom") {
      this.setState({notCustom: false})
    }
    else{
      this.setState({notCustom: true})
    }

    this.filterPeriod(value);
    // const period = {period: value}
    this.setState({period: value});
    /* console.log(value); */
    /* console.log(this.state.period); */
    /* console.log(this.state.data); */
  }

  filterPeriod = (value) => {
    const today = moment();
    let date_from = null;
    let date_to = null;

    if (value === "This Week") {
      date_from = today.startOf('week');
    }

    if (value === "This Month") {
      date_from = today.startOf('month');
    }

    if (value === "60 Days Ago") {

      date_from = today.subtract({months: 2,});
    }

    if (value === "90 Days Ago") {
      date_from = today.subtract({months: 3,});
    }

    if(value !== "Custom") {
      this.setState({date_from, date_to}, () => {
        this.loadReport();
      })
    }
  }

  filterDates = (data) => {
    var ordersBetweenTheTwoDates = [];

    for (var i = 0; i < data.length; i++) {

      var isBetweenDates = moment(data[i].created_at).isBetween(this.state.from_date, this.state.to_date); // true
      /* console.log(isBetweenDates); */
      if (isBetweenDates) {
        ordersBetweenTheTwoDates.push(data[i]);
      }
    }

    this.table_rows = ordersBetweenTheTwoDates;
    //this.notCustom = true;
    this.page = 1;
    //this.to_date = null;
    //this.from_date = null;
    /* console.log(this.table_rows); */
    return this.table_rows;
  }
  // componentDidMount= ()=>{
  //     'i got called';
  //     this.props.view("seller",this.props.history);
  // }
  handleDate = (e) => {
    const {name, value} = e.target;
    const v = moment(value)
    this.setState({[name]: v});
  }


  render() {

    const columns = [
      {
        Header: props => <span><b>Number of Transactions</b></span>,
        id: "id",
        filterable: false,
        accessor: d => d.total,
        Cell: props => {
          return <span>{props.original.total}</span>;
        }
      },
      {
        Header: props => <span><b>Gateway</b></span>,
        id: "gateway",
        filterable: false,
        accessor: d => d.gateway_name,
        Cell: p => {

          return <span> {p.original.gateway_name === 'USSD' ?  'Monnify': p.original.gateway_name === 'Paystack' ? 'PayStack' : p.original.gateway_name === 'All' ? "All" :'Other'} {}</span>;
        },
      },
      {
        Header: props => <span><b>Revenue</b></span>,
        id: "revenue",
        filterable: false,
        accessor: d => d.revenue,
        Cell: p => {

          return <span>₦ {p.original.revenue ? p.original.revenue.toLocaleString('en', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : "N/A"}</span>;
        },
      },
      {
        Header: props => <span><b>Service Type</b></span>,
        id: "service",
        filterable: false,
        accessor: d => d.type,
        Cell: p => {

          return <span>{p.original.type == '1' ? 'eCommerce' : (p.original.type == '2' || p.original.type == '3') ? 'P2P' : p.original.type == '4' ? 'AtaraNG' : p.original.type == '-1' ? 'All' : 'Other'}</span>;
        },
      },
    ];
    return (
       <div className={'content-wrapper'}>

         <div className="content-header">
           <div className="container-fluid">
             <div className="row mb-2">

               <div className="col-sm-12">
                 <h1 className="m-0 text-dark">Order Summary</h1>
               </div>
             </div>
           </div>
         </div>
         <section className="content">
           <div className="container-fluid">
             <div className="row ">
               <div className="col-12 col-sm-12 col-md-12">
                 <div className="alert animated bounceIn alert-danger d-none search-alert">
                   <a aria-label="close" className="close alert-close">×</a>
                 </div>
                 <div className="card">
                   <div className="card-body">
                     <div className="row">
                       <div className="col-12 col-sm-2">
                         <div className="form-group"><label htmlFor="period">Period</label>
                           <select className="form-control" name="filter" onChange={this.handleSelect}>
                             <option value="All">All</option>
                             <option value="This Week">This Week</option>
                             <option value="This Month">This Month</option>
                             <option value="60 Days Ago">60 Days Ago</option>
                             <option value="90 Days Ago">90 Days Ago</option>
                             <option value="Custom">Custom</option>
                           </select></div>
                       </div>
                       <div className="col-12 col-sm-2">

                       </div>
                       <div className="col-12 col-sm-3">
                         <div className="form-group">
                           <label htmlFor="from">Date From</label>
                           <input
                              type="date"
                              name="date_from"
                              value={this.state.date_from ? this.state.date_from.format('YYYY-MM-DD') : null}
                              disabled={this.state.notCustom}
                              onChange={this.handleDate}
                              className="form-control"/>
                         </div>
                       </div>
                       <div className="col-12 col-sm-3">
                         <div className="form-group">
                           <label htmlFor="from">Date To</label>
                           <input
                              type="date"
                              name="date_to"
                              value={this.state.date_to ? this.state.date_to.format('YYYY-MM-DD') : null}
                              disabled={this.state.notCustom}
                              onChange={this.handleDate}
                              className="form-control"/>
                         </div>
                       </div>
                       <div className="col-12 col-sm-2">
                         <div className="form-group"><label>&nbsp;</label>
                           <div className="row">
                             <div className="col-12">
                               <button disabled={this.state.notCustom} onClick={e => {
                                 e.preventDefault();
                                 this.loadReport();
                               }} className="btn btn-info btn-block form-control">
                                 <center>Search</center>
                               </button>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div>
                     <br/>
                     <div className="table-responsive">
                       <ReactTable
                          loading={this.state.loading}
                          defaultPageSize={5}
                          filterable
                          data={this.state.data}
                          columns={columns}/>

                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </section>
       </div>
    );
  }
}

export default OrderSummary;