import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";



import SideBar from './SideBar';

const mapStateToProps = (state) =>{
    
    // const { loading,success} = state.support;
    const {user} = state.users;
    // const alert  = state.alert;
    return {
        user
    };
 }
 

export default withRouter(connect(mapStateToProps)(SideBar));