import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";



import Support from '../views/Support';

const mapStateToProps = (state) =>{
    
    const { loading,success} = state.support;
    const {user} = state.users;

    const alert  = state.alert;
    return {
        alert,
        loading,
        success,
        user
    };
 }
 

export default withRouter(connect(mapStateToProps)(Support));