import { BASE_URL } from "../constants";
import axios from "axios";
import { userService } from ".";
import { userActions } from "../actions";

const create = (data, type) => {
  console.log("user", localStorage.getItem("user"));
  return axios({
    method: "post",
    mode: "no-cors",
    url: `${BASE_URL + type}/payout/create`,
    data: data,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("user"),
      "Content-Type": "application/json",
    },
  });
};

const managePhotoId = (id, action) => {
  return axios({
    method: "get",
    mode: "no-cors",
    url: `${BASE_URL}admin/managePhotoId/${id}/${action == "decline" ? 0 : 1}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("user"),
      "Content-Type": "application/json",
    },
  });
};

const all = (type) => {
  console.log(`${BASE_URL + type}/payouts`);
  return axios({
    method: "get",
    mode: "no-cors",
    url: `${BASE_URL + type}/payouts`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("user"),
      "Content-Type": "application/json",
    },
  });
};

const get = (type) => {
  return axios({
    method: "get",
    mode: "no-cors",
    url: `${BASE_URL + type}/payout/view`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("user"),
      "Content-Type": "application/json",
    },
  });
};

const edit = (data, type) => {
  return axios({
    method: "put",
    mode: "no-cors",
    url: `${BASE_URL + type}/payout/edit/${data.id}`,
    data: { type: data.type },
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("user"),
      "Content-Type": "application/json",
    },
  });
};

const setDefault = (id, type) => {
  return axios({
    method: "put",
    mode: "no-cors",
    url: `${BASE_URL + type}/payout/setDefault/${id}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("user"),
      "Content-Type": "application/json",
    },
  });
};

const delDetail = (id, type) => {
  return axios({
    method: "delete",
    mode: "no-cors",
    url: `${BASE_URL + type}/payout/delete/${id}`,
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + localStorage.getItem("user"),
      "Content-Type": "application/json",
    },
  });
};
export const payout = {
  create,
  all,
  get,
  edit,
  setDefault,
  delDetail,
  managePhotoId,
};
