import React, { forwardRef } from "react";
import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import COLORS from "../../theme/colors";
import Label from "../Label";
const Wrapper = styled.div`
  width: 20vw;
`;
const TextFieldInput = styled.input`
  width: 100%;
  height: 3rem;
  ${({ invalid }) => {
    return invalid
      ? css`
          border-color: ${COLORS.textFieldBorderColor};
        `
      : css``;
  }}
`;
const TextField = forwardRef(
  ({ label, labelDescription, input, meta }, ref) => {
    const { touched, error } = meta;
    const invalid = touched && !!error;
    return (
      <Wrapper ref={ref}>
        {label && (
          <Label description={labelDescription} hasError={invalid && error}>
            {label}
          </Label>
        )}
        <TextFieldInput
          placeholder={label ?? ""}
          {...input}
          {...meta}
          invalid={invalid}
        />
        {invalid && error && (
          <Label description={error} hasError={invalid && error} />
        )}
      </Wrapper>
    );
  }
);
TextField.displayName = "TextField";
TextField.propTypes = {
  label: PropTypes.string,
  labelDescription: PropTypes.string,
};
export default TextField;
