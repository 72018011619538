import { userConstants } from '../constants';


const initialState = {};

 const auth = (state = initialState, action)=> {
  switch (action.type) {
    case userConstants.user.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case userConstants.user.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
    case userConstants.user.LOGIN_UNVERIFIED:
      return {
        loggedIn: false,
        user: action.error
      };
    case userConstants.user.LOGIN_FAILURE:
      return {};
    
    default:
      return state
  }
}

export default auth;