export const TRANSACTION_TYPE_ECOMMERCE_ID = "1";
const TRANSACTION_TYPE_ECOMMERCE_NAME = "Ecommerce";
export const TRANSACTION_TYPE_P2P_ID = "2";
const TRANSACTION_TYPE_P2P_NAME = "P2P";
export const TRANSACTION_TYPES = [
  { id: TRANSACTION_TYPE_ECOMMERCE_ID, label: TRANSACTION_TYPE_ECOMMERCE_NAME },
  { id: TRANSACTION_TYPE_P2P_ID, label: TRANSACTION_TYPE_P2P_NAME },
];
export const TRANSACTION_TYPES_MAPPING = new Map([
  [TRANSACTION_TYPE_ECOMMERCE_ID, TRANSACTION_TYPE_ECOMMERCE_NAME],
  [TRANSACTION_TYPE_P2P_ID, TRANSACTION_TYPE_P2P_NAME],
]);

export const PAYMENT_METHOD_USSD_ID = "USSD";
const PAYMENT_METHOD_USSD_NAME = "Monnify";
export const PAYMENT_METHOD_PAYSTACK_ID = "paystack";
const PAYMENT_METHOD_PAYSTACK_NAME = "Paystack";

export const PAYMENT_METHODS = [
  {
    id: PAYMENT_METHOD_USSD_ID,
    label: PAYMENT_METHOD_USSD_NAME,
  },
  {
    id: PAYMENT_METHOD_PAYSTACK_ID,
    label: PAYMENT_METHOD_PAYSTACK_NAME,
  },
];

export const PAYMENT_METHOD_MAPPING = new Map([
  [PAYMENT_METHOD_USSD_ID, PAYMENT_METHOD_USSD_NAME],
  [PAYMENT_METHOD_PAYSTACK_ID, PAYMENT_METHOD_PAYSTACK_NAME],
]);

export const TRANSACTION_SCENARIO_ACCEPTANCE_ID = "acceptance";
const TRANSACTION_SCENARIO_ACCEPTANCE_NAME = "Acceptance";

const TRANSACTION_SCENARIO_REJECTION_WITH_SLA_ID = "rejectionWithSla";
const TRANSACTION_SCENARIO_REJECTION_WITH_SLA_NAME =
  "Rejection with option 5 or 6";

const TRANSACTION_SCENARIO_REJECTION_WITHOUT_SLA_ID = "rejectionWithoutSla";
const TRANSACTION_SCENARIO_REJECTION_WITHOUT_SLA_NAME =
  "Rejection without option 5 or 6";

const TRANSACTION_SCENARIO_CANCELLATION_WITHOUT_SLA_ID =
  "cancellationWithoutSla";
const TRANSACTION_SCENARIO_CANCELLATION_WITHOUT_SLA_NAME =
  "Cancellation Within SLA";

const TRANSACTION_SCENARIO_CANCELLATION_WITH_SLA_ID = "cancellationWithSla";
const TRANSACTION_SCENARIO_CANCELLATION_WITH_SLA_NAME =
  "Cancellation After SLA";

const TRANSACTION_SCENARIO_DISPUTE_WITH_OPTION_SELLER_CANCELS_ID =
  "sellerCancelDisputeWithSla";
const TRANSACTION_SCENARIO_DISPUTE_WITH_OPTION_SELLER_CANCELS_NAME =
  "Dispute with option 5 or 6 - seller cancels";

const TRANSACTION_SCENARIO_DISPUTE_WITHOUT_OPTION_SELLER_CANCELS_ID =
  "sellerCancelDisputeWithoutSla";
const TRANSACTION_SCENARIO_DISPUTE_WITHOUT_OPTION_SELLER_CANCELS_NAME =
  "Dispute without option 5 or 6 - seller cancels";

const TRANSACTION_SCENARIO_DISPUTE_WITH_OPTION_BUYER_CANCELS_ID =
  "buyerCancelDisputeWithSla";
const TRANSACTION_SCENARIO_DISPUTE_WITH_OPTION_BUYER_CANCELS_NAME =
  "Dispute with option 5 or 6 - buyer cancels";

const TRANSACTION_SCENARIO_DISPUTE_WITHOUT_OPTION_BUYER_CANCELS_ID =
  "buyerCancelDisputeWithoutSla";
const TRANSACTION_SCENARIO_DISPUTE_WITHOUT_OPTION_BUYER_CANCELS_NAME =
  "Dispute without option 5 or 6 - buyer cancels";

export const TRANSACTION_SCENARIOS_MAPPING = new Map([
  [TRANSACTION_SCENARIO_ACCEPTANCE_ID, TRANSACTION_SCENARIO_ACCEPTANCE_NAME],
  [
    TRANSACTION_SCENARIO_REJECTION_WITH_SLA_ID,
    TRANSACTION_SCENARIO_REJECTION_WITH_SLA_NAME,
  ],
  [
    TRANSACTION_SCENARIO_REJECTION_WITHOUT_SLA_ID,
    TRANSACTION_SCENARIO_REJECTION_WITHOUT_SLA_NAME,
  ],
  [
    TRANSACTION_SCENARIO_CANCELLATION_WITHOUT_SLA_ID,
    TRANSACTION_SCENARIO_CANCELLATION_WITHOUT_SLA_NAME,
  ],
  [
    TRANSACTION_SCENARIO_CANCELLATION_WITH_SLA_ID,
    TRANSACTION_SCENARIO_CANCELLATION_WITH_SLA_NAME,
  ],
  [
    TRANSACTION_SCENARIO_DISPUTE_WITH_OPTION_SELLER_CANCELS_ID,
    TRANSACTION_SCENARIO_DISPUTE_WITH_OPTION_SELLER_CANCELS_NAME,
  ],
  [
    TRANSACTION_SCENARIO_DISPUTE_WITHOUT_OPTION_SELLER_CANCELS_ID,
    TRANSACTION_SCENARIO_DISPUTE_WITHOUT_OPTION_SELLER_CANCELS_NAME,
  ],
  [
    TRANSACTION_SCENARIO_DISPUTE_WITH_OPTION_BUYER_CANCELS_ID,
    TRANSACTION_SCENARIO_DISPUTE_WITH_OPTION_BUYER_CANCELS_NAME,
  ],
  [
    TRANSACTION_SCENARIO_DISPUTE_WITHOUT_OPTION_BUYER_CANCELS_ID,
    TRANSACTION_SCENARIO_DISPUTE_WITHOUT_OPTION_BUYER_CANCELS_NAME,
  ],
]);

export const TRANSACTION_SCENARIOS = [
  {
    id: TRANSACTION_SCENARIO_ACCEPTANCE_ID,
    label: TRANSACTION_SCENARIO_ACCEPTANCE_NAME,
  },
  {
    id: TRANSACTION_SCENARIO_REJECTION_WITH_SLA_ID,
    label: TRANSACTION_SCENARIO_REJECTION_WITH_SLA_NAME,
  },
  {
    id: TRANSACTION_SCENARIO_REJECTION_WITHOUT_SLA_ID,
    label: TRANSACTION_SCENARIO_REJECTION_WITHOUT_SLA_NAME,
  },
  {
    id: TRANSACTION_SCENARIO_CANCELLATION_WITHOUT_SLA_ID,
    label: TRANSACTION_SCENARIO_CANCELLATION_WITHOUT_SLA_NAME,
  },
  {
    id: TRANSACTION_SCENARIO_CANCELLATION_WITH_SLA_ID,
    label: TRANSACTION_SCENARIO_CANCELLATION_WITH_SLA_NAME,
  },
  {
    id: TRANSACTION_SCENARIO_DISPUTE_WITH_OPTION_SELLER_CANCELS_ID,
    label: TRANSACTION_SCENARIO_DISPUTE_WITH_OPTION_SELLER_CANCELS_NAME,
  },
  {
    id: TRANSACTION_SCENARIO_DISPUTE_WITHOUT_OPTION_SELLER_CANCELS_ID,
    label: TRANSACTION_SCENARIO_DISPUTE_WITHOUT_OPTION_SELLER_CANCELS_NAME,
  },
  {
    id: TRANSACTION_SCENARIO_DISPUTE_WITH_OPTION_BUYER_CANCELS_ID,
    label: TRANSACTION_SCENARIO_DISPUTE_WITH_OPTION_BUYER_CANCELS_NAME,
  },
  {
    id: TRANSACTION_SCENARIO_DISPUTE_WITHOUT_OPTION_BUYER_CANCELS_ID,
    label: TRANSACTION_SCENARIO_DISPUTE_WITHOUT_OPTION_BUYER_CANCELS_NAME,
  },
];
export const TRANSACTION_CURRENCY_USD = "USD";
export const TRANSACTION_CURRENCY_NGN = "NGN";
export const TRANSACTION_CURRENCIES = [
  {
    id: TRANSACTION_CURRENCY_USD,
    label: TRANSACTION_CURRENCY_USD,
  },
  {
    id: TRANSACTION_CURRENCY_NGN,
    label: TRANSACTION_CURRENCY_NGN,
  },
];
export const TRANSACTION_REFUND_POLICY_REPAYMENT_ID = "0";
const TRANSACTION_REFUND_POLICY_REPAYMENT_NAME = "Replacement";
export const TRANSACTION_REFUND_POLICY_REFUND_ID = "1";
const TRANSACTION_REFUND_POLICY_REFUND_NAME = "Refund";
export const TRANSACTION_REFUND_POLICES = [
  {
    id: TRANSACTION_REFUND_POLICY_REPAYMENT_ID,
    label: TRANSACTION_REFUND_POLICY_REPAYMENT_NAME,
  },
  {
    id: TRANSACTION_REFUND_POLICY_REFUND_ID,
    label: TRANSACTION_REFUND_POLICY_REFUND_NAME,
  },
];
