import React, {Component} from 'react';
import ReactTable from "react-table";
import * as axios from "axios";
import {BASE_URL} from "../../constants";
import * as moment from "moment";
import {ClipLoader} from "react-spinners";
import Modal from "react-responsive-modal";
import {toLocaleDisplay} from "./OrderDetails";


const formatToSixDigits = (number) => {
  var output = number + '';
  while (output.length < 6) {
    output = '0' + output;
  }
  return output;
}

class TransactionsReport extends Component {
  state = {
    data: [],
    // dataOriginal: [],

    notCustom: true,

    date_to: null,
    date_from: null,

    page: 0,
    pagesCount: 1,
    pageSize: 5,
    search: '',
    oldSearch: '',
  }

  componentDidMount() {

    this.loadReport();
  }

  calcRevenue = d => {
    const amountPaid = d.amount_payed || 0;
    const amountNet = d.amount_net || 0;
    const revenue = ((amountPaid - amountNet) / 100).toLocaleString('en', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

    return revenue;
  }

  loadReport = (page = 0, pageSize = 5, sorted, filtered) => {

    let url = `${BASE_URL}admin/reports/transaction?page=${page + 1}&per_page=${pageSize}`;

    if (this.state.date_from) {
      url = url + `&date_from=${this.state.date_from.format("YYYY-MM-DD")}`
    }
    if (this.state.date_to) {
      url = url + `&date_to=${this.state.date_to.format("YYYY-MM-DD")}`
    }

    if(sorted && sorted.length > 0){
      const id = sorted[0].id
      const sorting = sorted[0].desc ? 'desc' : 'asc';

      url = url + `&orderby=${id}&sort=${sorting}`
      console.log(page, 'sorted')
    }

    if(this.state.search !== '' && this.state.search !== null){
      url = url + `&search=${this.state.search}`
    }

    this.setState({loading: true})

    axios({
      method: 'get',
      mode: 'no-cors',
      url,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
       .then(({data: {data}}) => {

         this.setState({
           // dataOriginal,
           data: data.data,
           page,
           pageSize,
           pagesCount: parseInt(data.last_page),
           oldSearch: this.state.search,
         })
       })
       .catch(err => {
         console.log(err.response);
       })
       .finally(() => this.setState({loading: false}))

  };


  handleSelect = (e) => {
    const {value} = e.target;
    if (value === "Custom") {
      this.setState({notCustom: false})
    } else {
      this.setState({notCustom: true})
    }

    this.filterPeriod(value);
    // const period = {period: value}
    this.setState({period: value});
    /* console.log(value); */
    /* console.log(this.state.period); */
    /* console.log(this.state.data); */
  }

  filterPeriod = (value) => {
    const today = moment();
    let date_from = null;
    let date_to = null;

    if (value === "This Week") {
      date_from = today.startOf('week');
    }

    if (value === "This Month") {
      date_from = today.startOf('month');
    }

    if (value === "60 Days Ago") {

      date_from = today.subtract({months: 2,});
    }

    if (value === "90 Days Ago") {
      date_from = today.subtract({months: 3,});
    }

    if (value !== "Custom") {
      this.setState({date_from, date_to}, () => {
        this.loadReport();
      })
    }
  }

  filterDates = (data) => {
    var ordersBetweenTheTwoDates = [];

    for (var i = 0; i < data.length; i++) {

      var isBetweenDates = moment(data[i].created_at).isBetween(this.state.from_date, this.state.to_date); // true
      /* console.log(isBetweenDates); */
      if (isBetweenDates) {
        ordersBetweenTheTwoDates.push(data[i]);
      }
    }

    this.table_rows = ordersBetweenTheTwoDates;
    //this.notCustom = true;
    this.page = 1;
    //this.to_date = null;
    //this.from_date = null;
    /* console.log(this.table_rows); */
    return this.table_rows;
  }
  // componentDidMount= ()=>{
  //     'i got called';
  //     this.props.view("seller",this.props.history);
  // }
  handleDate = (e) => {
    const {name, value} = e.target;
    const v = moment(value)
    this.setState({[name]: v});
  }
  getOrder = (e, orderId) => {
    e.preventDefault();

    axios({
      method: 'get',
      mode: 'no-cors',
      url: `${BASE_URL}admin/orders/${orderId}`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
        .then(({data}) => {
          this.setState({
            order: data.data,
            showOrder: true,
          })
        })
        .catch(err => {
          console.log(err.response)
        })
  }

  getUser = (e, userId) => {
    e.preventDefault();
    console.log(userId);

    axios({
      method: 'get',
      mode: 'no-cors',
      url: `${BASE_URL}admin/users/${userId}`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
        .then(({data}) => {
          this.setState({
            user: data.data,
            showUser: true,
          })
        })
        .catch(err => {
          console.log(err.response)
        })
  }

  getBuyer = (e, userId) => {
    e.preventDefault();
    console.log(userId);

    axios({
      method: 'get',
      mode: 'no-cors',
      url: `${BASE_URL}admin/customers/${userId}`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
        .then(({data}) => {
          this.setState({
            buyer: data.data,
            showBuyer: true,
          })
        })
        .catch(err => {
          console.log(err.response)
        })
  }

  render() {

    const columns = [
      {
        Header: props => <span><b>Order ID</b></span>,
        id: "id",
        filterable: false,
        accessor: d => d.total,
        Cell: props => {
          return <span><a href={"#"} onClick={e => this.getOrder(e, props.original.id)}>{formatToSixDigits(props.original.id)}</a></span>;
        }
      },
      {
        Header: props => <span><b>Buyer Phone No.</b></span>,
        id: "customer_phone_number",
        filterable: false,
        accessor: d => d.buyer_phone,
        Cell: p => {

          return <span>{p.original.buyer_phone ? <a href="#" onClick={e => this.getBuyer(e, p.original.customer_id)}>{p.original.buyer_phone}</a> : 'N/A'}</span>;
        },
      },
      {
        Header: props => <span><b>Seller Phone No.</b></span>,
        id: "user_phone_number",
        filterable: false,
        accessor: d => d.seller_phone,
        Cell: p => {

          // return <span>{p.original.user_id}</span>

          return <span>{p.original.seller_phone ? <a href="#" onClick={e => this.getUser(e, p.original.user_id)}>{p.original.seller_phone}</a> : 'N/A'}</span>;
        },
      },
      {
        Header: props => <span><b>Marketplace Business Phone No.</b></span>,
        id: "marketplace_phone_number",
        filterable: false,
        sortable: true,
        accessor: d => d.marketplace_phone_number,
        Cell: d => {

          return <span>{d.original.transaction_type === 1 ? d.original.marketplace_phone_number : 'N/A'}</span>;
        },
      },
      {
        Header: props => <span><b>Order Status</b></span>,
        id: "status_title",
        filterable: false,
        accessor: d => d.sellers ? d.sellers.marketplaceuser ? (d.sellers.marketplaceuser.phone_number || 'N/A') : 'N/A' : 'N/A',
        Cell: d => {

          const item = d.original;

          return (
             item.status.id === 1 || item.status.id === 11 || item.status.id === 7 || item.status.id === 3 ?
                <span className="badge badge-warning"> {item.status_title}</span>
                : item.status_id === 9 || item.status_id === 4 || item.status_id === 10 ?
                <span className="badge badge-danger"> {item.status_title}</span>
                : item.status_id === 6 || item.status_id === 8 ?
                   <span className="badge badge-info"> {item.status_title}</span>
                   : item.status_id === 2 || item.status_id === 5 ?
                      <span className="badge badge-success"> {item.status_title}</span>
                      :
                      <span className="badge badge-warning">{item.status_title}</span>

          );
        },
      },
      {
        Header: props => <span><b>Service Type</b></span>,
        id: "transaction_type",
        filterable: false,
        accessor: d => d.transaction_type == '1' ? 'ecommerce' : (d.transaction_type == '2' || d.transaction_type == '3') ? 'p2p' : d.transaction_type == '4' ? 'atarang' : 'other',
        Cell: p => {

          return <span>{p.original.transaction_type == '1' ? 'eCommerce' : (p.original.transaction_type == '2' || p.original.transaction_type == '3') ? 'P2P' : p.original.transaction_type == '4' ? 'AtaraNG' : 'Other'}</span>;
        },
      },
    ];

    const {loading} = this.state;

    return (
       <div className={'content-wrapper'}>
         <Modal open={this.state.showOrder} onClose={() => this.setState({showOrder: false})}>
           {
             this.state.order ?
                 <form>
                   <div className="modal-header">
                     <h4 className="modal-title"><small></small></h4>
                   </div>

                   <div className="modal-body">
                     <div className="row"></div>
                     <div className="row">
                       <div className="col-sm-6">
                         <div className="form-group"><label>Order Number</label> <br/> <input
                             type="text" readOnly="readonly"
                             value={formatToSixDigits(this.state.order && this.state.order.id)}
                             className="form-control"/></div>
                       </div>
                       <div className="col-sm-6">
                         <div className="form-group">
                           <label>Amount</label><br/>
                           <input type="text" readOnly="readonly"
                                  value={this.state.order && toLocaleDisplay(parseFloat(this.state.order.amount_payed / 100))}
                                  className="form-control"/></div>
                       </div>
                     </div>
                     <div className="row">
                       <div className="col-sm-6">
                         <div className="form-group"><label>Order Date</label> <br/> <input
                             type="text" readOnly="readonly"
                             value={this.state.order && this.state.order.created_at}
                             className="form-control"/></div>
                       </div>
                       <div className="col-sm-6">
                         <div className="form-group"><label>Order Status</label> <br/> <input
                             type="text" readOnly="readonly"
                             value={this.state.order && this.state.order.status.title}
                             className="form-control"/></div>
                       </div>
                     </div>
                     <div className="row">
                       <div className="col-sm-6">
                         <div className="form-group"><label>Buyer Name</label> <br/> <input
                             type="text" readOnly="readonly"
                             value={this.state.order && this.state.order.customers && this.state.order.customers.firstname + " " + this.state.order.customers.lastname}
                             className="form-control"/></div>
                       </div>
                       <div className="col-sm-6">
                         <div className="form-group"><label>Seller Name</label> <br/> <input
                             type="text" readOnly="readonly"
                             value={this.state.order && this.state.order.user && this.state.order.user.firstname + " " + this.state.order.user.lastname}
                             className="form-control"/></div>
                       </div>
                     </div>
                     <div className="row">
                       <div className="col-sm-6">
                         <div className="form-group"><label>Delivery Location</label> <br/> <input
                             type="text" readOnly="readonly"
                             value={this.state.order && this.state.order.delivery_location || "N/A"}
                             className="form-control"/></div>
                       </div>
                       <div className="col-sm-6">
                         <div className="form-group"><label>Delivery Date</label> <br/> <input
                             type="text" readOnly="readonly"
                             value={this.state.order && this.state.order.delivery_date || "N/A"}
                             className="form-control"/></div>
                       </div>
                     </div>
                     <br/><br/>
                     {this.state.order.extra ?
                         <div className="row">

                           {this.state.order && Object.keys(this.state.order.extra).map(extra => (
                               <div className="col-sm-6">
                                 <div className="form-group">
                                   <label>{extra.charAt(0).toUpperCase() + extra.slice(1).replace(/_/g, " ")}</label>
                                   <br/> <input type="text" readOnly="readonly"
                                                value={this.state.order.extra[extra]}
                                                className="form-control"/></div>
                               </div>
                           ))}
                         </div> : null}
                     <br/><br/>
                   </div>
                 </form> :
                 <ClipLoader
                     loading={true}
                 />
           }
         </Modal>
         <Modal open={this.state.showUser} onClose={() => this.setState({showUser: false})}>
           {
             this.state.user ?
                 <form>
                   <div className="modal-header">
                     <h4 className="modal-title"><small></small></h4>
                   </div>

                   <div className="modal-body">
                     <div className="row">
                       <div className="col-sm-6">
                         <div className="form-group"><label>First Name</label> <br/> <input
                             type="text" readOnly="readonly"
                             value={this.state.user.firstname}
                             className="form-control"/></div>
                       </div>
                       <div className="col-sm-6">
                         <div className="form-group"><label>Last Name</label> <br/> <input
                             type="text" readOnly="readonly"
                             value={this.state.user.lastname}
                             className="form-control"/></div>
                       </div>
                     </div>
                     <div className="row">
                       <div className="col-sm-6">
                         <div className="form-group"><label>Phone No.</label> <br/> <input
                             type="text" readOnly="readonly"
                             value={this.state.user.phone_number || "N/A"}
                             className="form-control"/></div>
                       </div>
                       <div className="col-sm-6">
                         <div className="form-group"><label>Email</label> <br/> <input
                             type="text" readOnly="readonly"
                             value={this.state.user.email || "N/A"}
                             className="form-control"/></div>
                       </div>
                     </div>
                     {
                       this.state.user.seller &&
                           <>
                             <div className="row">
                               <div className="col-sm-6">
                                 <div className="form-group"><label>Business Name</label> <br/> <input
                                     type="text" readOnly="readonly"
                                     value={this.state.user.seller.business_name || "N/A"}
                                     className="form-control"/></div>
                               </div>
                               <div className="col-sm-6">
                                 <div className="form-group"><label>Business Email</label> <br/> <input
                                     type="text" readOnly="readonly"
                                     value={this.state.user.seller.business_email || "N/A"}
                                     className="form-control"/></div>
                               </div>
                               <div className="col-sm-6">
                                 <div className="form-group"><label>Business Address</label> <br/> <input
                                     type="text" readOnly="readonly"
                                     value={this.state.user.seller.business_address || "N/A"}
                                     className="form-control"/></div>
                               </div>
                               <div className="col-sm-6">
                                 <div className="form-group"><label>Business Address 2</label> <br/> <input
                                     type="text" readOnly="readonly"
                                     value={this.state.user.seller.business_address_2 || "N/A"}
                                     className="form-control"/></div>
                               </div>
                               <div className="col-sm-6">
                                 <div className="form-group"><label>Business City</label> <br/> <input
                                     type="text" readOnly="readonly"
                                     value={this.state.user.seller.business_city || "N/A"}
                                     className="form-control"/></div>
                               </div>
                               <div className="col-sm-6">
                                 <div className="form-group"><label>Business State</label> <br/> <input
                                     type="text" readOnly="readonly"
                                     value={this.state.user.seller.business_state || "N/A"}
                                     className="form-control"/></div>
                               </div>
                               <div className="col-sm-6">
                                 <div className="form-group"><label>Business Country</label> <br/> <input
                                     type="text" readOnly="readonly"
                                     value={this.state.user.seller.business_country || "N/A"}
                                     className="form-control"/></div>
                               </div>
                               <div className="col-sm-6">
                                 <div className="form-group"><label>Website</label> <br/> <input
                                     type="text" readOnly="readonly"
                                     value={this.state.user.seller.siteURL || "N/A"}
                                     className="form-control"/></div>
                               </div>
                             </div>
                           </>
                     }
                     <br/><br/>
                   </div>
                 </form> :
                 <ClipLoader
                     loading={true}
                 />
           }
         </Modal>
         <Modal open={this.state.showBuyer} onClose={() => this.setState({showBuyer: false})}>
           {
             this.state.buyer ?
                 <form>
                   <div className="modal-header">
                     <h4 className="modal-title"><small></small></h4>
                   </div>

                   <div className="modal-body">
                     <div className="row">
                       <div className="col-sm-6">
                         <div className="form-group"><label>First Name</label> <br/> <input
                             type="text" readOnly="readonly"
                             value={this.state.buyer.firstname}
                             className="form-control"/></div>
                       </div>
                       <div className="col-sm-6">
                         <div className="form-group"><label>Last Name</label> <br/> <input
                             type="text" readOnly="readonly"
                             value={this.state.buyer.lastname}
                             className="form-control"/></div>
                       </div>
                     </div>
                     <div className="row">
                       <div className="col-sm-6">
                         <div className="form-group"><label>Phone No.</label> <br/> <input
                             type="text" readOnly="readonly"
                             value={this.state.buyer.phone_number || "N/A"}
                             className="form-control"/></div>
                       </div>
                       <div className="col-sm-6">
                         <div className="form-group"><label>Alt Phone No.</label> <br/> <input
                             type="text" readOnly="readonly"
                             value={this.state.buyer.phone_number_alt || "N/A"}
                             className="form-control"/></div>
                       </div>
                       <div className="col-sm-6">
                         <div className="form-group"><label>Email</label> <br/> <input
                             type="text" readOnly="readonly"
                             value={this.state.buyer.email || "N/A"}
                             className="form-control"/></div>
                       </div>
                     </div>

                   </div>
                 </form> :
                 <ClipLoader
                     loading={true}
                 />
           }
         </Modal>
         <div className="content-header">
           <div className="container-fluid">
             <div className="row mb-2">

               <div className="col-sm-12">
                 <h1 className="m-0 text-dark">Transactions Report</h1>
               </div>
             </div>
           </div>
         </div>
         <section className="content">
           <div className="container-fluid">
             <div className="row ">
               <div className="col-12 col-sm-12 col-md-12">
                 <div className="alert animated bounceIn alert-danger d-none search-alert">
                   <a aria-label="close" className="close alert-close">×</a>
                 </div>
                 <div className="card">
                   <div className="card-body">
                     <div className="row">
                       <div className="col-12 col-sm-2">
                         <div className="form-group"><label htmlFor="period">Period</label>
                           <select className="form-control" name="filter" onChange={this.handleSelect}>
                             <option value="All">All</option>
                             <option value="This Week">This Week</option>
                             <option value="This Month">This Month</option>
                             <option value="60 Days Ago">60 Days Ago</option>
                             <option value="90 Days Ago">90 Days Ago</option>
                             <option value="Custom">Custom</option>
                           </select></div>
                       </div>
                       <div className="col-12 col-sm-2">
                         <div className="form-group">
                           <label htmlFor="period">Search</label>
                           <input type="text" className={'form-control'} onChange={(e) => {
                             e.preventDefault()

                             if(this.timeout){
                               clearTimeout(this.timeout)
                             }

                             if(e.target.value !== this.state.oldSearch){
                               this.setState({
                                 search: e.target.value,
                               }, () => {
                                 this.timeout = setTimeout(this.loadReport, 750);
                               })
                             }

                           }}/>
                         </div>

                       </div>
                       <div className="col-12 col-sm-3">
                         <div className="form-group">
                           <label htmlFor="from">Date From</label>
                           <input
                              type="date"
                              name="date_from"
                              value={this.state.date_from ? this.state.date_from.format('YYYY-MM-DD') : null}
                              disabled={this.state.notCustom}
                              onChange={this.handleDate}
                              className="form-control"/>
                         </div>
                       </div>
                       <div className="col-12 col-sm-3">
                         <div className="form-group">
                           <label htmlFor="from">Date To</label>
                           <input
                              type="date"
                              name="date_to"
                              value={this.state.date_to ? this.state.date_to.format('YYYY-MM-DD') : null}
                              disabled={this.state.notCustom}
                              onChange={this.handleDate}
                              className="form-control"/>
                         </div>
                       </div>
                       <div className="col-12 col-sm-2">
                         <div className="form-group"><label>&nbsp;</label>
                           <div className="row">
                             <div className="col-12">
                               <button disabled={this.state.notCustom} onClick={e => {
                                 e.preventDefault();
                                 this.loadReport();
                               }} className="btn btn-info btn-block form-control">
                                 <center>Search</center>
                               </button>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div>
                     <br/>
                     <div className="table-responsive">
                       <ReactTable
                          loading={loading}
                          pages={this.state.pagesCount}
                          page={this.state.page}
                          pageSize={this.state.pageSize}
                          defaultPageSize={this.state.pageSize}
                          filterable
                          data={this.state.data}
                          columns={columns}
                          manual
                          onFetchData={(state, instance) => {
                            this.loadReport(state.page, state.pageSize, state.sorted, state.filtered)
                          }}
                       />

                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>
         </section>
       </div>
    );
  }
}

export default TransactionsReport;