import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";

import Header from './Header';
import {userActions } from '../actions';


const mapStateToProps = (state) =>{
    
    // const { loading,success} = state.support;
    const {user} = state.users;
    // const alert  = state.alert;
    return {
        user
    };
 }
const mapDispatchToProps = (dispatch) => ({
     logout:(type,history)=>dispatch(userActions.user.logout(type,history))
}
)
     
     

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Header));