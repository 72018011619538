import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import ReactTable from "react-table";
import 'react-table/react-table.css';
import Modal from 'react-responsive-modal';
import * as moment from 'moment';
import {alertActions} from '../actions'
import swal from '@sweetalert/with-react';
import matchSorter from 'match-sorter'

import {jsx, css} from '@emotion/core';
import {ClipLoader} from 'react-spinners';
import * as axios from "axios";
import {BASE_URL} from "../constants";

const override = css`
display: block;
margin-top:10%;
margin-left:50%;
margin-right:40%;
border-color: red;
`;

const formatToSixDigits = (number) => {
  var output = number + '';
  while (output.length < 6) {
    output = '0' + output;
  }
  return output;
}

class paymentHistory extends Component {

  state = {
    filterable: false,
    notCustom: true,
    period: "All",
    assignModal: false,
    fullModal: false,
    from_date: "",
    filter: "",
    to_date: "",
    data: [],
    order: "",
    fields: {
      order_id: 0,
      delivery_man_id: 0
    },
    payments: []
  }

  componentDidMount() {
    /* console.log("oox", this.props); */

    this.getData()
  }

  getData = () => {
    this.setState({
      loading: true,
    })
    axios({
      method: 'get',
      mode: 'no-cors',
      url: `${BASE_URL}admin/payments`,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Authorization': 'Bearer ' + localStorage.getItem("user"),
        'Content-Type': 'application/json',
      }
    })
       .then(res => {
         console.log('s', res.data)
         this.setState({
           data: res.data.data,
           payments: res.data.data,
         })
       })
       .catch(err => {
       })
       .finally(() => {
         this.setState({
           loading: false,
         })
       })
  }

  componentWillReceiveProps(props) {
    if (props.alert && props.alert.message && props.alert.type === "alert-danger") {
      swal("Error", props.alert.message, "error").then(() => {
        props.dispatch(alertActions.alert.clear());
      });
    }

    if (props.alert && props.alert.message && props.alert.type === "alert-success") {
      swal("Success", props.alert.message, "success").then(() => {
        props.dispatch(alertActions.alert.clear());
      });
      this.onCloseModal('assignModal');
      // this.onCloseModal('edit');
      // this.onCloseModal('delete');
    }

    if (props.payments) {

      this.setState({data: [...props.payments]});
      this.setState({payments: props.payments})

    }

  }

  handleSelect = (e) => {
    const {value} = e.target;
    if (value === "Custom") {
      this.setState({notCustom: !this.state.notCustom})
    }
    this.filterPeriod(value);
    // const period = {period: value}
    this.setState({period: value});
    /* console.log(value); */
    console.log(this.state.period);
    console.log(this.state.data);
  }
  filterPeriod = (value) => {
    const data = this.state.payments;
    console.log(data);

    if (value === "All") {
      /* console.log(data); */
      this.table_rows = data;
      this.notCustom = true;
      this.page = 1;
      this.to_date = null;
      this.from_date = null;
      this.setState({notCustom: true, data: [...this.state.payments]});
    }

    if (value === "This Week") {
      var ordersThisWeek = [];

      for (var i = 0; i < data.length; i++) {
        var now = moment();
        var input = moment(data[i].created_at);
        var isThisWeek = input.isSame(now, "week");

        if (isThisWeek) {
          ordersThisWeek.push(data[i]);
        }
      }

      this.table_rows = ordersThisWeek;
      this.notCustom = true;
      this.page = 1;
      this.to_date = null;
      this.from_date = null;
      /* console.log(this.table_rows); */
      this.setState({notCustom: true, data: [...this.table_rows]});
    }

    if (value === "This Month") {
      var ordersThisMonth = [];

      for (let i = 0; i < data.length; i++) {
        //var now = moment();
        let input = moment(data[i].created_at);
        var isThisMonth = input.isSame(new Date(), 'month');

        if (isThisMonth) {
          ordersThisMonth.push(data[i]);
        }
      }

      this.table_rows = ordersThisMonth;
      this.notCustom = true;
      this.page = 1;
      this.to_date = null;
      this.from_date = null;
      this.setState({notCustom: true, data: [...this.table_rows]});
    }

    if (value === "60 Days Ago") {
      var ordersSixtyDaysAgo = [];

      for (let i = 0; i < data.length; i++) {

        var sixty_days_ago = moment().subtract(60, 'days');

        var isBetweenSixtyDays = moment(data[i].created_at).isAfter(sixty_days_ago);

        if (isBetweenSixtyDays) {
          ordersSixtyDaysAgo.push(data[i]);
        }
      }

      this.table_rows = ordersSixtyDaysAgo;
      this.notCustom = true;
      this.page = 1;
      this.to_date = null;
      this.from_date = null;
      this.setState({notCustom: true, data: [...this.table_rows]});
    }

    if (value === "90 Days Ago") {
      var ordersNinetyDaysAgo = [];

      for (let i = 0; i < data.length; i++) {

        var ninety_days_ago = moment().subtract(90, 'days');

        var isBetweenNinetyDays = moment(data[i].created_at).isAfter(ninety_days_ago);

        if (isBetweenNinetyDays) {
          ordersNinetyDaysAgo.push(data[i]);
        }
      }

      this.table_rows = ordersNinetyDaysAgo;
      this.notCustom = true;
      this.page = 1;
      this.to_date = null;
      this.from_date = null;
      this.setState({notCustom: true, data: [...this.table_rows]});
    }

    if (value === "Custom") {
      this.table_rows = data;
      this.to_date = null;
      this.from_date = null;

    }
  }

  filterDates = (data) => {

    // if(this.state.from_date == null){

    //     this.alertMessage = "Please select a From date";
    //     // $(".search-alert").removeClass("d-none");
    // }
    // if(this.state.to_date == null){
    //     this.alertMessage = "Please select a To date";
    //     // $(".search-alert").removeClass("d-none");
    // }

    // if(moment(this.from_date).isAfter(this.to_date)){
    //     this.alertMessage = "Please select a From date that is before your To date";
    //     // $(".search-alert").removeClass("d-none");
    // }

    /* console.log(this.state.from_date); console.log(this.state.to_date); */

    var ordersBetweenTheTwoDates = [];

    for (var i = 0; i < data.length; i++) {

      var isBetweenDates = moment(data[i].created_at).isBetween(this.state.from_date, this.state.to_date); // true
      /* console.log(isBetweenDates); */
      if (isBetweenDates) {
        ordersBetweenTheTwoDates.push(data[i]);
      }
    }

    this.table_rows = ordersBetweenTheTwoDates;
    //this.notCustom = true;
    this.page = 1;
    //this.to_date = null;
    //this.from_date = null;
    /* console.log(this.table_rows); */
    return this.table_rows;
  }
  // componentDidMount= ()=>{
  //     'i got called';
  //     this.props.view("seller",this.props.history);
  // }
  handleDate = (e) => {
    const {name, value} = e.target;
    this.setState({[name]: value});

  }

  filterTable = (filter) => {
    var data = this.state.payments;
    var filteredRows = [];
    // this.setState({data:[...this.state.deliveryMen]});
    if (filter !== "") {
      for (var i = 0; i < data.length; i++) {
        // console.log(parseInt(filter), data[i].id, data[i].id === parseInt(filter));
        // return;
        if (parseInt(data[i].transaction_number) === parseInt(filter) ||
           (data[i].created_at && data[i].created_at.includes(filter)) ||
           (data[i].user && (data[i].user.firstname + ' ' + data[i].user.lastname).toLowerCase().includes(filter.toLowerCase())) ||
           (data[i].customer && (data[i].customer.firstname + ' ' + data[i].customer.lastname).toLowerCase().includes(filter.toLowerCase())) ||
           (data[i].payment_ref && data[i].payment_ref.toUpperCase().includes(filter.toUpperCase())) ||
           (data[i].transaction_type && data[i].transaction_type.toUpperCase().includes(filter.toUpperCase())) ||
           (parseInt(data[i].amount) === parseInt(filter))
        ) {
          filteredRows.push(data[i]);
        }
      }

      this.setState({data: [...filteredRows]});
    } else {
      this.setState({data: [...this.state.payments]});
    }

  }

  handleFilter = (e) => {
    const {value} = e.target;
    this.setState({filter: value}, () => this.filterTable(this.state.filter));
    // this.filterTable();

  }
  onOpenModal = (name, payments) => {
    this.setState({[name]: true});
    this.setState({payments: payments});

  };

  onCloseModal = (name) => {
    this.setState({[name]: false});
    this.setState({payments: ""});
  };

  formatAmount = (amount) => {
    let a = amount + '';
    let naira = a.slice(0, -3);
    let kobo = a.substr(a.length - 2);
    return naira + "." + kobo;
  }

  render() {
    const {filterable, loading} = this.state
    //   var data = [];
    //   payments ? data = payments  : data = [];
    console.log(loading);
    var columns = [];
    {
         columns = [
           {
             Header: props => <span><b>SN</b></span>,
             id: "sn",
             filterable,
             maxWidth: 80,
             accessor: d => d,// String-based value accessors!
             Cell: (row) => {
               return <span>{row.index + 1}</span>;
             }
           }, {
             Header: props => <span><b>Order ID</b></span>,
             id: "id",
             filterable,
             maxWidth: 140,
             accessor: d => d,// String-based value accessors!
             Cell: props => <a href="#/app/payments"
                               onClick={() => this.onOpenModal("fullModal", props.value)}>{props.value.transaction_number}</a>
           }, {
             Header: props => <span><b>Service Type</b></span>,
             id: "servicetype",
             filterable,
             maxWidth: 140,
             accessor: d => d.transaction_type,// String-based value accessors!
             Cell: props =>
                <span>
                  {props.original.transaction_type}

                </span>
           }, {
             Header: props => <span><b>Buyer</b></span>,
             id: "buyer",
             filterable,
             maxWidth: 140,
             accessor: d => d,// String-based value accessors!
             Cell: props =>
                <span>
                  {props.value.customer && (props.value.customer.firstname + ' ' + props.value.customer.lastname)}
                </span>
           }, {
             Header: props => <span><b>Seller</b></span>,
             id: "seller",
             filterable,
             maxWidth: 140,
             accessor: d => d,// String-based value accessors!
             Cell: props => <span>
               {props.value.user && (props.value.user.firstname + ' ' + props.value.user.lastname)}
             </span>
           },
           {
             id: 'amount', // Required because our accessor is not a string
             Header: props => <span><b>Amount Paid</b></span>,
             filterable,
             maxWidth: 170,
             accessor: d => d, // Custom value accessors!
             Cell: props => <span
                className='number'>{props.value === null ? '' : '₦' + (props.value.amount / 100).toLocaleString('en', {minimumFractionDigits: 2})}</span> // Custom cell components!

           },
           {
             id: "transaction",
             filterable,
             maxWidth: 170,
             Header: props => <span><b>Date Paid</b></span>,
             accessor: d => d, // String-based value accessors!
             Cell: props => <span>{props.value == null ? '' : props.value.created_at}</span>
           }, {
             Header: props => <span><b>Transaction No.</b></span>,
             id: "reference",
             filterable,
             accessor: d => d,
             Cell: props => <span className='number'>{props.value === null ? '' : props.value.payment_ref}</span> // Custom cell components!
           },]
    }

    return (

       <div>
         <Modal open={this.state.fullModal} onClose={() => this.onCloseModal("fullModal")} center>
           <form>
             <div class="modal-header">
               <h4 class="modal-title">
                 <small>Transaction {formatToSixDigits(this.state.payments && this.state.payments.transaction_number)}</small>
               </h4>
             </div>
             <div class="modal-body">
               <div class="row"></div>
               <div class="row">
                 <div class="col-sm-6">
                   <div class="form-group"><label>Transaction Number</label> <br/> <input type="text"
                                                                                          readonly="readonly"
                                                                                          value={formatToSixDigits(this.state.payments && this.state.payments.id)}
                                                                                          class="form-control"/></div>
                 </div>
                 <div class="col-sm-6">
                   <div class="form-group"><label>Reference</label> <br/> <input type="text" readonly="readonly"
                                                                                 value={this.state.payments && this.state.payments.payment_ref}
                                                                                 class="form-control"/></div>
                 </div>
               </div>
               <div class="row">
                 <div class="col-sm-6">
                   <div class="form-group"><label>Transaction Date</label> <br/> <input type="text" readonly="readonly"
                                                                                        value={this.state.payments && this.state.payments.created_at}
                                                                                        class="form-control"/></div>
                 </div>
                 <div class="col-sm-6">
                   <div class="form-group"><label>Buyer Name</label> <br/> <input type="text" readonly="readonly"
                                                                                  value={this.state.payments && this.state.payments.customer && this.state.payments.customer.firstname + " " + this.state.payments.customer.lastname}
                                                                                  class="form-control"/></div>
                 </div>
               </div>
               <div class="row">
                 <div class="col-sm-6">
                   <div class="form-group"><label>Seller Name</label> <br/> <input type="text" readonly="readonly"
                                                                                   value={this.state.payments && this.state.payments.business && this.state.payments.business.business_name ? this.state.payments.business.business_name : 'AtaraPay Marketplace'}
                                                                                   class="form-control"/></div>
                 </div>
                 <div class="col-sm-6">
                   <div class="form-group"><label><small><b>Marketplace Business Name</b></small></label> <br/> <input
                      type="text" readonly="readonly"
                      value={this.state.payments && this.state.payments.business && this.state.payments.business.business_name ? this.state.payments.business.business_name : 'AtaraPay Marketplace'}
                      class="form-control"/></div>
                 </div>
               </div>
               <div class="row">
                 <div class="col-sm-6">
                   <div class="form-group"><label>Product Amount</label> <br/> <input type="text" readonly="readonly"
                                                                                      value={this.state.payments && this.state.payments.amount ? '₦' + (this.state.payments.amount / 100).toLocaleString('en', {
                                                                                        minimumFractionDigits: 2,
                                                                                        maximumFractionDigits: 2
                                                                                      }) : '₦' + (0).toLocaleString('en', {minimumFractionDigits: 2})}
                                                                                      class="form-control"/></div>
                 </div>
                 <div class="col-sm-6">
                   <div class="form-group"><label>Shipping Amount</label> <br/> <input type="text" readonly="readonly"
                                                                                       value={this.state.payments && this.state.payments.shipping_cost ? '₦' + this.state.payments.shipping_cost.toLocaleString('en', {
                                                                                         minimumFractionDigits: 2,
                                                                                         maximumFractionDigits: 2
                                                                                       }) : '₦' + (0).toLocaleString('en', {minimumFractionDigits: 2})}
                                                                                       class="form-control"/></div>
                 </div>
               </div>
               <div class="row">
                 <div class="col-sm-6">
                   <div class="form-group"><label>Escrow Fee Paid By</label> <br/> <input type="text"
                                                                                          readonly="readonly"
                                                                                          value={this.state.payments && this.state.payments.escrow_fee_bearer ? this.state.payments.escrow_fee_bearer : 'N/A'}
                                                                                          class="form-control"/></div>
                 </div>
                 <div class="col-sm-6">
                   <div class="form-group"><label><small><b>Payment Transaction Fee</b></small></label> <br/> <input
                      type="text" readonly="readonly"
                      value={'1.5%'} class="form-control"/></div>
                 </div>
               </div>
               <div class="row">
                 <div class="col-sm-6">
                   <div class="form-group"><label>Payment Channel</label> <br/> <input type="text" readonly="readonly"
                                                                                       value={this.state.payments && this.state.payments.method}
                                                                                       class="form-control"/></div>
                 </div>
                 <div class="col-sm-6">
                   <div class="form-group"><label>Payment Status</label> <br/> <input type="text" readonly="readonly"
                                                                                      value={this.state.payments && this.state.payments.gateway_response}
                                                                                      class="form-control"/></div>
                 </div>
               </div>
               <div class="row">
                 <div class="col-sm-6">
                   <div class="form-group"><label>Payment Value</label> <br/> <input type="text" readonly="readonly"
                                                                                     value={this.state.payments && '₦' + (this.state.payments.amount_payed / 100).toLocaleString('en', {minimumFractionDigits: 2})}
                                                                                     class="form-control"/></div>
                 </div>
               </div>
               <br/><br/>
             </div>
           </form>
         </Modal>
         <div class="content-wrapper" style={{minHeight: 136}}>
           <div class="content-header">
             <div class="container-fluid">
               <div class="row mb-2">
                 <div class="col-sm-12">
                   <h1 class="m-0 text-dark">Payment History</h1>
                   <div class="attop">
                     <p>
                       On this page, you will find details of payments made by the Buyer on orders placed from the
                       Seller’s website or a P2P escrow transaction
                     </p>
                   </div>
                   <br/>

                 </div>
               </div>
             </div>
           </div>
           {!loading ?
              <section class="content">
                <div class="container-fluid"><br/>
                  <div class="row ">
                    <div class="col-12 col-sm-12 col-md-12">
                      <div class="alert animated bounceIn alert-danger d-none search-alert"><a aria-label="close"
                                                                                               class="close alert-close">×</a>
                        <span></span></div>
                      <div class="card">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-12 col-sm-2">
                              <div class="form-group"><label for="period">Period</label>
                                <select class="form-control" name="filter" onChange={this.handleSelect}>
                                  <option value="All">All</option>
                                  <option value="This Week">This Week</option>
                                  <option value="This Month">This Month</option>
                                  <option value="60 Days Ago">60 Days Ago</option>
                                  <option value="90 Days Ago">90 Days Ago</option>
                                  <option value="Custom">Custom</option>
                                </select></div>
                            </div>
                            <div class="col-12 col-sm-2">
                              <div class="form-group"><label for="filter">Filter</label><input type="text"
                                                                                               placeholder="Filter"
                                                                                               class="form-control"
                                                                                               value={this.state.filter}
                                                                                               onChange={this.handleFilter}/>
                              </div>
                            </div>
                            <div class="col-12 col-sm-3">
                              <div class="form-group"><label for="from">Date From</label> <input type="date"
                                                                                                 name="from_date"
                                                                                                 value={this.state.from_date}
                                                                                                 disabled={this.state.notCustom}
                                                                                                 onChange={this.handleDate}
                                                                                                 class="form-control"/>
                              </div>
                            </div>
                            <div class="col-12 col-sm-3">
                              <div class="form-group"><label for="from">Date To</label> <input type="date"
                                                                                               name="to_date"
                                                                                               value={this.state.to_date}
                                                                                               disabled={this.state.notCustom}
                                                                                               onChange={this.handleDate}
                                                                                               class="form-control"/>
                              </div>
                            </div>
                            <div class="col-12 col-sm-2">
                              <div class="form-group"><label>&nbsp;</label>
                                <div class="row">
                                  <div class="col-12">
                                    <button disabled={this.state.notCustom} onClick={() => {
                                      this.setState({data: [...this.filterDates(this.state.data)]})
                                    }} class="btn btn-info btn-block form-control">
                                      <center>Search</center>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <br/>
                          <div class="table-responsive">
                            <ReactTable
                               defaultPageSize={5}
                               filterable
                               data={this.state.data}
                               columns={columns}/>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <br/>
                </div>
              </section> :
              <div className='sweet-loading'>
                <ClipLoader
                   className={override}
                   sizeUnit={"px"}
                   size={70}

                   color={'blue'}
                   loading={loading}
                />
              </div>
           }
         </div>
       </div>
    )
  }
}

export default paymentHistory;