import { orderConstants, disputeConstants} from '../constants';

 const  orders = (state = {}, action)=>{
  switch (action.type) {
    case orderConstants.order.ASSIGN_DELIVERY_MAN:
      return { ...state,adding: true };
    case orderConstants.order.ASSIGN_DELIVERY_MAN_SUCCESS:
      return { ...state, adding: false, orders: [...state.orders.filter(order => order.id !== action.delivery.id), action.delivery],deliverySuccess:true};
    case orderConstants.order.ASSIGN_DELIVERY_MAN_FAILURE:
      return {...state,adding: false,deliverySuccess:false};
    case orderConstants.order.ORDER_DETAILS_REQUEST:
      return {...state, loading: true };
    case orderConstants.order.ORDER_DETAILS_SUCCESS:
      return {...state,orders: action.orders, loading: false };
    case orderConstants.order.ORDER_DETAILS_FAILURE:
      return {...state, error: action.error , loading: false};
    case orderConstants.order.PAY_DETAILS_REQUEST:
      return {...state, loading: true };
    case orderConstants.order.PAY_DETAILS_SUCCESS:
      return {...state,payments: action.payments, loading: false };
    case orderConstants.order.PAY_DETAILS_FAILURE:
    case orderConstants.order.PROCESS_PAYMENT_REQUEST:
      return {...state, loading: true };
    case orderConstants.order.PROCESS_PAYMENT_SUCCESS:
      return {...state,result: action.payment, loading: false };
    case orderConstants.order.PROCESS_PAYMENT_FAILURE:
      return {...state, error: action.error , loading: false};
    case disputeConstants.dispute.ORDER_DISPUTES_REQUEST:
      return {...state, loading: true };
    case disputeConstants.dispute.ORDER_DISPUTES_SUCCESS:
      return {...state,disputes: action.disputes, loading: false };
    case disputeConstants.dispute.ORDER_DISPUTES_FAILURE:
      return {...state, error: action.error , loading: false};
    case disputeConstants.dispute.RAISE_DISPUTE_REQUEST:
      return {...state, raising: true };
    case disputeConstants.dispute.RAISE_DISPUTE_SUCCESS:
      return {...state, raising: false };
    case disputeConstants.dispute.RAISE_DISPUTE_FAILURE:
      return {...state, error: action.error , raising: false};
    case disputeConstants.dispute.CANCEL_DISPUTE_REQUEST:
      return {...state, canceling: true };
    case disputeConstants.dispute.CANCEL_DISPUTE_SUCCESS:
      return {...state, canceling: false };
    case disputeConstants.dispute.CANCEL_DISPUTE_FAILURE:
      return {...state, error: action.error , canceling: false};
    case orderConstants.order.START_POD:
      return {...state, loading: true };
    case orderConstants.order.START_POD_SUCCESS:
      return {...state,delivery:action.delivery,loading: false };
    case orderConstants.order.START_POD_FAILURE:
      return {...state, error: action.error , loading: false};
    default:
      return state
  }
}

export default orders;